class APIEndPoints {
    static BASE = window.config.API_BASE_ADDRESS;
    static BI_BASE = window.config.BI_REPORT_ADDRESS;
    static GLUE_JOB = window.config.GLUE_JOB_BASE_ADDRESS;

    static GET_GROWER_LIST = (startPage, pageSize, businessUnit = 'DOC', lang = 'en-US', filter = '') => {
        return `${this.BASE}growerlist?${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }&businessunit=${businessUnit}&lang=${lang}${filter}`;
    };

    static GET_ITEM_UOM_CONVERSION_LIST = (startPage, pageSize, businessUnit = 'DOC', lang = 'en-US', filter = '') => {
        return `${this.BASE}itemlist?businessunit=${businessUnit}&lang=${lang}${filter}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }`;
    };

    static GET_ITEM_LIST = (businessUnit = 'DOC', lang = 'en-US') => {
        return `${this.BASE}itemlist?businessunit=${businessUnit}&lang=${lang}`;
    };

    static GET_CURRENT_POOL_WEEK = (keydate, businessUnit = 'DOC') => {
        return `${this.BASE}poolweek?daydate=${keydate}&businessunit=${businessUnit}`;
    };

    static GET_SETTLEMENT_RATE = (startPage = 0, pageSize = 50, businessUnit = 'DOC', filter = '') => {
        return `${this.BASE}settlementrate?businessunit=${businessUnit}&startpage=${startPage}&pagesize=${pageSize}${filter}`;
    };

    static GET_INVENTORY_ONHAND = (startPage, pageSize, businessUnit = 'DOC', filters, poolweek) => {
        return `${this.BASE}inventoryonhand?businessunit=${businessUnit}&poolweek=${poolweek}${filters ? filters : ''}${
            startPage ? `&startpage=${startPage}` : ''
        }${pageSize ? `&pagesize=${pageSize}` : ''}`;
    };

    static GET_REPACKAGING_SKU_MAPPING = (startPage = 0, pageSize = 50, businessUnit = 'DOC', filters = '') => {
        return `${this.BASE}repackageitemmap?businessunit=${businessUnit}&startpage=${startPage}&pagesize=${pageSize}${filters}`;
    };

    static GET_ITEM_SHIPPING_RATES = (startPage = 0, pageSize = 50, businessUnit = 'DOC', filters = '') => {
        return `${this.BASE}itemshippingrates?businessunit=${businessUnit}&startpage=${startPage}&pagesize=${pageSize}${filters}`;
    };
    static GET_CUSTOMER_CODE_CATEGORY = (startPage = 0, pageSize = 50, businessUnit = 'DOC', filters = '') => {
        return `${this.BASE}customercodemap?startpage=${startPage}&pagesize=${pageSize}&businessunit=${businessUnit}${filters}`;
    };

    static GET_RECEIPT_LINES = (startPage, pageSize, businessUnit = 'DOC', poolweek, filters = '') => {
        return `${this.BASE}receiptline?businessunit=${businessUnit}&poolweek=${poolweek}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }${filters}`;
    };

    static GET_SUMMARY_TRANSACTION = (businessUnit = 'DOC', poolweek) => {
        return `${this.BASE}summarytransaction?businessunit=${businessUnit}&poolweek=${poolweek}`;
    };

    static GET_SALES_INVOICE_LINES = (startPage, pageSize, businessUnit = 'DOC', poolweek, filters = '') => {
        return `${this.BASE}salesinvoice?businessunit=${businessUnit}&poolweek=${poolweek}${filters}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }`;
    };

    static GET_SERVICEDEDUCTIONS_LINES = (startPage, pageSize, businessUnit = 'DOC', filters, poolweek) => {
        return `${this.BASE}servicededuction?businessunit=${businessUnit}&poolweek=${poolweek}${filters ? filters : ''}${
            startPage ? `&startpage=${startPage}` : ''
        }${pageSize ? `&pagesize=${pageSize}` : ''}`;
    };

    static GET_INTRANSIT_INVENTORY_LINES = (startPage = 0, pageSize = 50, businessUnit = 'DOC', filters, poolweek) => {
        return `${this.BASE}inventoryintransit?businessunit=${businessUnit}&poolweek=${poolweek}${filters ? filters : ''}${
            startPage ? `&startpage=${startPage}` : ''
        }${pageSize ? `&pagesize=${pageSize}` : ''}`;
    };

    static GET_POOL_TABLE = (startPage = 0, pageSize = 50, businessUnit = 'DOC') => {
        return `${this.BASE}pooltable?businessunit=${businessUnit}&startpage=${startPage}&pagesize=${pageSize}`;
    };

    static GET_POOL_WEEK_BY_DATE = (startdate, endDate, businessUnit = 'DOC') => {
        return `${this.BASE}poolweek?businessunit=${businessUnit}&startdate=${startdate}&enddate=${endDate}`;
    };

    static GET_VAT_RATE = (businessUnit = 'DOC', filter = '') => {
        return `${this.BASE}vatpercentage?businessunit=${businessUnit}${filter}`;
    };

    static GET_CONVERSION_RATE = (exchangeSymbol = 'CNYUSD', rateDate = '') => {
        return `${this.BASE}forexcurrencyrate?exchangesymbol=${exchangeSymbol}&ratedate=${rateDate}`;
    };

    static GET_LOOKUPVALUES = (startPage = 0, pageSize = 50, businessUnit = 'DEMEA', enabledFlag = 'Y', filter = '') => {
        let url = `${this.BASE}lookupvalues?businessunit=${businessUnit}`;
        url = url + `&enabledflag=${enabledFlag}`;
        url = url + `&startpage=${startPage}`;
        url = url + `&pagesize=${pageSize}`;
        url += filter;
        return url;
    };

    static GET_SETTLEMENT_RECORD = (poolweek, startpage, pagesize, filters, businessUnit = 'DOC') => {
        return `${this.BASE}growersettlement?businessunit=${businessUnit}${startpage ? `&startpage=${startpage}` : ''}${
            pagesize ? `&pagesize=${pagesize}` : ''
        }&poolweek=${poolweek}${filters || ''}`;
    };

    static GET_AVERAGE_PRICE = (poolweek = '2145', startpage, pagesize, filters, businessUnit = 'DOC') => {
        return `${this.BASE}itemsalessummary?businessunit=${businessUnit}&poolweek=${poolweek}${filters || ''}${
            startpage ? `&startpage=${startpage}` : ''
        }${pagesize ? `&pagesize=${pagesize}` : ''}`;
    };

    static GET_LOOK_UP_VALUES = (businessUnit, lookuptype, enabledflag = 'y') => {
        return `${this.BASE}lookupvalues?businessunit=${businessUnit}&enabledflag=${enabledflag}&lookuptype=${lookuptype}`;
    };

    static GET_SETTLEMENT_STATUS = (poolweek, businessunit, status) =>
        `${this.BASE}growersettlementstatus?businessunit=${businessunit}&poolweek=${poolweek}${status ? `&status=${status}` : ''}`;

    static GET_APPROVER_LIST = (groupid) => `${this.BASE}groups?groupid=${groupid}`;
    static GET_SETTLEMENT_RECORDS = (businessunit, poolweek, status, levelid = 3) =>
        `${this.BASE}settlementpoolstatus?businessunit=${businessunit}&poolweek=${poolweek}&levelid=${levelid}${status ? `&status=${status}` : ''}`;

    static GET_REPACKAGE_MOVEMENT = (businessUnit = 'DOC', startPage, pageSize, filter = '') =>
        `${this.BASE}repackagemovement?businessunit=${businessUnit}${filter}${startPage ? `&startpage=${startPage}&pagesize=${pageSize}` : ''}`;

    static GET_SHIPMENT_DETAILS = (startPage, pageSize, businessUnit, filters = '', poolweek) => {
        return `${this.BASE}importcost?businessunit=${businessUnit}&poolweek=${poolweek}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }${filters}`;
    };

    static GET_USER_PREFERENCES = (oktaId, email) => `${this.BASE}userpreference?oktaid=${oktaId}&email=${email}`;

    static GET_GROWER_FIXED_RATE = (businessUnit = 'DOC', startPage, pageSize, filter = '', lang = 'en-US') =>
        `${this.BASE}growerfixedpricerate?businessunit=${businessUnit}${filter}&startpage=${startPage}&pagesize=${pageSize}&lang=${lang}`;

    static GET_DOMESTIC_SUMMARY = (poolweek, startpage, pagesize, filters, businessUnit = 'DOC') => {
        return `${this.BASE}gssummarydomestic?businessunit=${businessUnit}${startpage ? `&startpage=${startpage}` : ''}${
            pagesize ? `&pagesize=${pagesize}` : ''
        }&poolweek=${poolweek}${filters || ''}`;
    };
    static GET_MANUAL_ADJUSTMENT = (businessUnit, poolweek) => {
        return `${this.BASE}manualadjustments?businessunit=${businessUnit}&poolweek=${poolweek} `;
    };

    static GET_GROWER_SETTLEMENT_REPORT = `${this.BASE}growersettlementreport`;
    static GET_RECEIPT_BY_GROWER = `${this.BASE}receiptreport`;

    static GET_RECEIPT_COST_CENTER = (poolweek, startpage, pagesize, filters, businessUnit = 'DOC') => {
        return `${this.BASE}receiptcostcenter?businessunit=${businessUnit}${startpage ? `&startpage=${startpage}` : ''}${
            pagesize ? `&pagesize=${pagesize}` : ''
        }&poolweek=${poolweek}${filters || ''}`;
    };

    static GET_GROWER_PERCENTAGE = (startPage, pageSize, filters, businessUnit = 'DOC') => {
        return `${this.BASE}growersettlementrate?businessunit=${businessUnit}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }${filters || ''}`;
    };
    static GET_IMPORT_SETTLEMENT_RATE = (startPage, pageSize, businessUnit = 'DOC') => {
        return `${this.BASE}importsettlementrate?businessunit=${businessUnit}${startPage ? `&startpage=${startPage}` : ''}${
            pageSize ? `&pagesize=${pageSize}` : ''
        }`;
    };

    static GET_POOLWEEK_SHIFT = (poolWeek, businessUnit = 'DOC') => {
        return `${this.BASE}poolweekshift?businessunit=${businessUnit}&poolweek=${poolWeek}`;
    };

    static GET_REPORT_FILE_KEY = (poolWeek, geography, reportType, businessUnit = 'DOC') => {
        return `${this.BASE}reportfilekeys?businessunit=${businessUnit}&poolweek=${poolWeek}&geography=${geography}&reporttype=${reportType}`;
    };

    static GET_REPORT_PUBLISH_DATA = (poolWeek, businessUnit = 'DOC') => {
        return `${this.BASE}ggsreportpublish?businessunit=${businessUnit}&poolweek=${poolWeek}`;
    };

    static GET_POOLDATAITEMS = (pricingPool, businessUnit = 'DOC', startPage, pageSize) => `${this.BASE}pooltableitem?pricingPoolID=${pricingPool}}`;

    static GET_BI_DOMESTIC_PRE_REPORT = `${this.BI_BASE}SettlementReport_Pre/SettlementReport_Int?:iid=1`;
    static GET_BI_DOMESTIC_FINAL_REPORT = `${this.BI_BASE}SettlementReport_Final/SettlementReport_Int?:iid=1`;
    static GET_BI_IMPORT_PRE_REPORT = `${this.BI_BASE}SettlementReport_International_Pre/SettlementReport`;
    static GET_BI_IMPORT_FINAL_REPORT = `${this.BI_BASE}SettlementReport_International_Final/SettlementReport?:iid=4`;

    // PUT APIs
    static PUT_ITEM_SHIPPING_RATES = () => `${this.BASE}itemshippingrates`;
    static PUT_GROWER_LIST = () => `${this.BASE}growerlist`;
    static PUT_SETTLEMENT_RATE = () => `${this.BASE}settlementrate`;
    static PUT_ITEM_UOM_CONVERSION_LIST = () => `${this.BASE}itemlist`;
    static PUT_REPACKAGING_SKU_MAPPING = () => `${this.BASE}repackageitemmap`;
    static PUT_CUSTOMER_CODE_CATEGORY = () => `${this.BASE}customercodemap`;
    static PUT_VAT_RATE = () => `${this.BASE}vatrate`;
    static PUT_LOOKUPVALUES = () => `${this.BASE}lookupvalues`;
    static PUT_GROWER_SETTLEMENT_STATUS = () => `${this.BASE}growersettlementstatus`;
    static PUT_REPACKAGE_MOVEMENT = () => `${this.BASE}repackagemovement`;
    static PUT_SHIPMENT_DETAILS = () => `${this.BASE}importcost`;
    static PUT_GROWER_FIXED_RATE = () => `${this.BASE}growerfixedpricerate`;
    static PUT_MANUAL_ADJUSTMENT = () => `${this.BASE}manualadjustments`;
    static PUT_GROWER_PERCENTAGE = () => `${this.BASE}growersettlementrate`;
    static PUT_IMPORT_SETTLEMENT_RATE = () => `${this.BASE}importsettlementrate`;

    /// HYBRID PUT POST APIs
    static PUTPOST_POOLTABLEITEM = () => `${this.BASE}pooltable/pooltableitem`;
    static PUTPOST_POOLTABLEPRODUCINGAREA = () => `${this.BASE}pooltableproducingarea`;
    static PUTPOST_POOLTABLEGROWER = () => `${this.BASE}pooltable/pooltablegrower`;
    static PUTPOST_POOLTABLECOUNTRY = () => `${this.BASE}pooltablecountry`;
    static PUTPOST_POOLTABLECOMMODITY = () => `${this.BASE}pooltablecommodity`;
    static PUTPOST_POOLTABLEREGION = () => `${this.BASE}pooltableregion`;

    //POST APIs
    static POST_VAT_RATE = () => `${this.BASE}vatpercentage`;
    static POST_USER_DETAIL = () => `${this.BASE}languserlist`;
    static POST_POOL_WEEK_YEAR = () => `${this.BASE}poolweekyear`;
    static POST_EMAIL = () => `${this.BASE}groweremail`;
    static POST_SETTLEMENT_RECORD = () => `${this.GLUE_JOB}/submit`;
    static POST_CSV_FILE_UPLOAD = () => `${this.GLUE_JOB}/fileupload`;
    static POST_USER_PREFERENCES = () => `${this.BASE}userpreference`;
    static POST_JOURNAL_ENTRY_REPORT = () => `${this.BASE}journalentryreport`;
    static POST_IMPORT_GROWER_SETTLEMENT_REPORT = () => `${this.BASE}importgrowersettlementreport`;
    static POST_POOLWEEK_SHIFT = () => `${this.BASE}poolweekshift`;
    static POST_GGS_REPORT_PUBLISH = () => `${this.BASE}ggsreportpublish`;
    static POST_INVENTORY_ON_TRANSIT = () => `${this.BASE}inventoryintransit`;
    static POST_INVENTORY_ON_HAND = () => `${this.BASE}inventoryonhand`;
    static POST_IMPORT_COST_UPLOAD = () => `${this.BASE}importcostupload`;
}

export default APIEndPoints;
