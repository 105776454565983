import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import Box from '@material-ui/core/Box';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NestedMenu from './NestedMenu';
import { openDrawer, showReportBackLinkAction } from '../actions/actions';
import { connect } from 'react-redux';
import { designColors, drawerWidth } from '../data/constants';
import { useHistory } from 'react-router-dom';
import { DrcTooltip } from '@driscollsinc/driscolls-react-components';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
        // '& .MuiListItemText-root': {
        //     color: `${designColors.blue} !important`
        // }
    },
    listItemRoot: {
        color: '#fff !important',
        backgroundColor: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        fontWeight: 'bold',
        minWidth: 32
    },
    selected: {
        backgroundColor: '#fff !important',
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        },
        fontWeight: 'bold'
    },
    menuBox: {
        display: 'flex',
        borderRadius: '10px',
        width: '45px',
        border: '2px solid white',
        padding: '8px',
        alignContent: 'center !important'
    },
    selectedBorder: {
        border: `2px solid ${designColors.blue}`
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
    },
    appBarShift: {
        marginLeft: drawerWidth,
        // width: `calc(100% - ${drawerWidth}px)`,
        width: '100%',
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        })
    },
    menuButton: {
        // marginRight: 36,
        // border: '1px solid white',
        borderRadius: 0,
        // padding: 0,
        justifyContent: 'end'
    },
    hide: {
        display: 'none'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap'
        // color: '#fff !important',
        // backgroundColor: `${designColors.blue} !important`
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen
        }),
        top: '70px',
        color: '#fff',
        height: 'calc(100% - 70px)',
        backgroundColor: `${designColors.blue}`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        overflowX: 'hidden',
        width: theme.spacing(9) + 1,
        // [theme.breakpoints.up('sm')]: {
        //     width: theme.spacing(9) + 1
        // },
        top: '70px',
        color: '#fff',
        backgroundColor: `${designColors.blue}`,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        }
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    paperAnchorDockedLeft: {
        border: 'none'
    }
}));

function MiniDrawer(props) {
    const classes = useStyles();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const history = useHistory();
    useEffect(() => {
        let dataSetupRoutes = props.allLinks.reduce((acc, menu) => {
            menu.items?.map((itm) => acc.push(itm.route));
            return acc;
        }, []);
        if ((history.location.pathname === '/' || history.location.pathname === '/SettlementWorkbench/') && selectedIndex !== 0) {
            setSelectedIndex(0);
        } else if (history.location.pathname === '/PricingPool/' && selectedIndex !== 1) {
            setSelectedIndex(1);
        } else if (dataSetupRoutes.includes(history.location.pathname) && selectedIndex !== 2) {
            setSelectedIndex(2);
        } else if (history.location.pathname === '/Reports/' && selectedIndex !== 3) {
            setSelectedIndex(3);
        }
    }, [history.location.pathname]);
    const handleDrawerOpen = () => {
        props.openDrawer(true);
    };

    const handleDrawerClose = () => {
        props.openDrawer(false);
    };

    const handleListItemClick = (itm, index) => {
        setSelectedIndex(index);
        props.showReportBackLinkAction(false);
        props.handleListItemClick(itm, index);
    };

    if (props.allLinks.length <= 0) return null;
    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
                [classes.drawerOpen]: props.drawerOpen,
                [classes.drawerClose]: !props.drawerOpen
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.drawerOpen,
                    [classes.drawerClose]: !props.drawerOpen,
                    [classes.paperAnchorDockedLeft]: true
                })
            }}
        >
            {/* <div className={classes.toolbar}> */}
            {/* <IconButton onClick={handleDrawerClose}>{theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton> */}
            <IconButton
                color="inherit"
                aria-label="close drawer"
                onClick={handleDrawerClose}
                edge="start"
                disableRipple
                className={clsx(classes.menuButton, {
                    [classes.hide]: !props.drawerOpen
                    // [classes.root]: true
                })}
            >
                <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                    <ChevronLeftIcon />
                    <MenuIcon />
                </Box>
            </IconButton>
            {/* </div> */}
            {/* <Divider /> */}

            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                disableRipple
                className={clsx(classes.menuButton, {
                    [classes.hide]: props.drawerOpen
                    // [classes.root]: true
                })}
            >
                <Box component="span" sx={{ p: 1, border: '1px solid white', borderRadius: 8 }}>
                    <MenuIcon />
                    <ChevronRightIcon />
                </Box>
            </IconButton>
            <List>
                {!!props.drawerOpen && selectedIndex !== 0 && <Divider variant="middle" style={{ backgroundColor: 'white' }} />}
                {props.allLinks.map((menu, index) =>
                    menu.items ? (
                        <>
                            <NestedMenu
                                key={index}
                                menu={menu}
                                index={index}
                                onClick={(_event) => handleListItemClick(menu, menu.index)}
                                setSelectedIndex={() => setSelectedIndex(menu.index)}
                                handleDrawerOpen={handleDrawerOpen}
                                selected={selectedIndex === menu.index}
                                classes={classes}
                            />
                            {!!props.drawerOpen && selectedIndex !== menu.index && selectedIndex !== menu.index + 1 && (
                                <Divider variant="middle" style={{ backgroundColor: 'white' }} />
                            )}
                        </>
                    ) : (
                        <>
                            <ListItem
                                button
                                key={index}
                                onClick={(_event) => handleListItemClick(menu, menu.index)}
                                selected={selectedIndex === menu.index}
                                className={selectedIndex === menu.index ? classes.selected : classes.listItemRoot}
                            >
                                {props.drawerOpen ? (
                                    <ListItemText primary={menu.name} /> //classes={{ root: classes.listItemRoot }}
                                ) : (
                                    <DrcTooltip tipText={menu.name}>
                                        <div className={`${classes.menuBox} ${selectedIndex === menu.index ? classes.selectedBorder : null}`}>
                                            <ListItemIcon className={selectedIndex === menu.index ? classes.selected : classes.listItemRoot}>
                                                {selectedIndex === menu.index ? menu.selectedIcon : menu.icon}
                                            </ListItemIcon>
                                        </div>
                                    </DrcTooltip>
                                )}
                            </ListItem>
                            {!!props.drawerOpen && selectedIndex !== menu.index + 1 && selectedIndex !== menu.index && (
                                <Divider variant="middle" style={{ backgroundColor: 'white' }} />
                            )}
                        </>
                    )
                )}
            </List>
        </Drawer>
    );
}
const mapStateToProps = (state) => ({
    drawerOpen: state.rootReducer.drawerOpen
});

export default connect(mapStateToProps, { openDrawer, showReportBackLinkAction })(MiniDrawer);
