import React, { useEffect } from 'react';
import { control } from 'react-validation';
import { makeStyles } from '@material-ui/core/styles';
import { DrcInput, DrcTooltip } from '@driscollsinc/driscolls-react-components';

const useStyles = makeStyles((theme) => ({
    error: {
        color: theme.light.text.errorTitle + ' !important',
        [theme.darkTheme]: {
            color: theme.dark.text.errorTitle + ' !important'
        },
        fontSize: '0.8rem !important'
    }
}));
const GGSFormInput = ({ error, isChanged, isUsed, validationDetected = () => null, ...props }) => {
    const classes = useStyles();
    useEffect(() => {
        validationDetected((isUsed || isChanged) && !!error);
    }, [error, isChanged, isUsed]);
    return (
        <DrcInput
            error={(isUsed || isChanged) && !!error}
            helperText={
                isUsed || isChanged ? (
                    error ? (
                        <DrcTooltip tipText={error}>
                            <span style={{ fontSize: 7 }}>{error} </span>
                        </DrcTooltip>
                    ) : (
                        ''
                    )
                ) : (
                    ''
                )
            }
            {...{
                ...props,
                required: false,
                ...{
                    label: props.required ? (
                        <span>
                            {props.label} <span className={classes.error}>*</span>
                        </span>
                    ) : (
                        props.label
                    )
                }
            }}
        />
    );
};

export default control(GGSFormInput);
