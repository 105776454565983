import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { designColors } from '../data/constants';
import { DrcTranslate } from '@driscollsinc/driscolls-react-components';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return value === index ? children : null;
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

        display: 'flex'
        // height: '100%'
    },
    tabs: {
        // borderRight: `1px solid ${theme.palette.divider}`
    }
}));

export default function VerticalTabs(props) {
    const classes = useStyles();

    const { tabItems = [], value, handleChange = null } = props;
    // const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        handleChange(newValue);
    };

    const VerticalTab = withStyles((theme) => ({
        flexContainer: {
            flexDirection: 'column'
        },
        indicator: {
            display: 'none'
        }
    }))(Tabs);

    const CustomTab = withStyles((theme) => ({
        root: {
            // backgroundColor: `${designColors.grey}`,
            borderRadius: theme.shape.borderRadius,
            textTransform: 'none',
            padding: '4px 0 0 12px'
        },
        wrapper: {
            backgroundColor: theme.light.primary,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.primary} !important`
            },
            padding: theme.spacing(2, 1),
            borderRadius: theme.shape.borderRadius,
            borderTop: '1px solid #DDD',
            borderBottom: '1px solid #DDD',
            borderLeft: '6px solid #9B9B9B',
            alignItems: 'start'
            // fontWeight: 700
        },
        selected: {
            color: `${designColors.blue}`,
            '& .MuiTab-wrapper': {
                backgroundColor: `${designColors.grey}`,
                borderLeft: '6px solid #9B9B9B'
            }
        }
    }))(Tab);
    return (
        <div className={classes.root}>
            <VerticalTab
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleTabChange}
                aria-label="Vertical tabs"
                scrollButtons="off"
                className={classes.tabs}
            >
                {tabItems.map((tab, indx) => (
                    <CustomTab disableRipple label={<DrcTranslate>{`${tab.name}`}</DrcTranslate>} {...a11yProps(indx)} key={indx} />
                ))}
            </VerticalTab>
            {tabItems.map((tab, indx) => (
                <TabPanel value={value} index={indx} key={indx}>
                    {tab.component}
                </TabPanel>
            ))}
        </div>
    );
}
