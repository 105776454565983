import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcDialog, DrcLoading, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';
import Papa from 'papaparse';
import APIEndPoints from '../services/api';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { displayErrorMessage, userBusinessUnit } from '../utils/helper';
import { convertDateToIso } from '../data/constants';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const styles = (theme) => ({
    popupContainer: {
        '& .MuiDialog-paperWidthSm': {
            maxWidth: '1200px !important' //TO override dialog maxWidth
        }
    },
    popupStyle: {
        width: '700px',
        backgroundColor: 'background.paper',
        boxShadow: 24,
        p: 4,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        }
    },
    loadingSection: {
        position: 'absolute',
        top: '0',
        width: '100%',
        height: '100vh',
        paddingTop: '150px',
        zIndex: '99'
    },
    modalContent: {
        background: '#ffffff',
        width: '100%',
        minHeight: '250px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        }
    },
    title: {
        height: '35px',
        marginTop: '10px',
        marginBottom: '10px',
        fontSize: '26px',
        fontWeight: '400',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 'normal',
        letterSpacing: 'normal',
        textAlign: 'left',
        color: '#1e4471',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        }
    },
    buttonsNew: {
        width: 'auto !important',
        borderRadius: '5px',
        border: 'solid 1px #707070',
        padding: '3px 21px'
    },
    boxFile: {
        display: 'none'
    },
    boxInput: {
        backgroundColor: 'white',
        height: '140px',
        outline: '2px dashed black',
        outlineOffset: '-10px',
        border: '2px dashed #91D4ED',
        borderRadius: '5px',
        textAlign: 'center',
        cursor: 'pointer',
        width: '100%',
        float: 'left',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`,
            color: '#fff !important'
        },
        position: 'relative',
        '& p': {
            position: 'relative',
            width: '100%',
            top: '40%'
        }
    },
    selectedFile: {
        position: 'relative',
        width: '100%',
        top: '30%'
    },
    note: {
        color: 'red'
    },
    divider: {
        backgroundColor: '#000',
        width: '100%',
        marginBottom: '1rem'
    }
});

const inventoryOnHandFields = ['Description', 'Item', 'On Hand', 'Physical Count', 'Pref. Vendor'];

const goodsInTransitFields = [
    'Date',
    'Item: Full Name',
    'Document Number',
    'Status',
    'Ship To',
    'Quantity',
    'Memo',
    'Date Shipped',
    'Date Received',
    'Description'
];

const importCostFields = [
    'Shipment Number',
    'Grower ID',
    'Item ID',
    'FDC',
    'Trays',
    'Container Number',
    'Report Type',
    'Custom Clearance Fee',
    'Freight Custom To FDC',
    'Repackaging Charge',
    'Others 1',
    'Others 2',
    'Others 3'
];

function DrcFileUploadDialog(props) {
    const { classes } = props;
    const [fileName, setFileName] = useState('');
    const [disableSubmit, setDisableSubmit] = useState(true);
    const [convertedJsonFile, setConvertedJsonFile] = useState([]);
    const [isCsvValid, setIsCsvValid] = useState(false);

    const handleClose = () => {
        props.setOpenUploadDialog(false);
        setFileName('');
        setDisableSubmit(true);
        setConvertedJsonFile([]);
        setIsCsvValid(false);
    };

    const onDropHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let files = e.dataTransfer.files;
        handleFileChosen(files);
    };

    const handleFileChosen = async (files) => {
        let resultData = [];
        const file = files[0];
        setFileName(file.name);
        if (file) {
            setDisableSubmit(false);
            if (props.fileType === 'Import Cost') {
                Papa.parse(file, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        formatImportCostData(results.data);
                        validateFile(results.meta.fields);
                    }
                });
            } else {
                Papa.parse(file, {
                    header: false,
                    skipEmptyLines: true,
                    complete: function (results) {
                        for (let i = 6; i < results.data.length; i++) resultData.push(results.data[i]); // actual file starts at line 7.
                        formatJsonData(resultData);
                        validateFile(results.data[6]); // header is on line 7
                    }
                });
            }
        }
    };

    const formatJsonData = (data) => {
        let keys = data.shift();
        let resultArray = [];

        const convertedData = data.map(function (row) {
            return keys.reduce(function (obj, key, i) {
                obj[key] = row[i];
                return obj;
            }, {});
        });

        props.fileType === 'Inventory On Hand' && convertedData.pop(); // last row contains Total

        if (props.fileType === 'Goods In Transit') {
            resultArray = convertedData.map(function (itm) {
                return {
                    ItemID: parseInt(itm['Item: Full Name']),
                    TransactionKey: itm['Document Number'],
                    ShipToLocation: itm['Ship To'],
                    ShipFromLocation: itm['Ship From'] || '',
                    DateShipped: itm['Date Shipped'] ? convertDateToIso(itm['Date Shipped']) : '',
                    DateReceived: itm['Date Received'] ? convertDateToIso(itm['Date Received']) : '',
                    InventoryStatus: itm['Status'],
                    LastModifiedDate: itm['Date'] ? convertDateToIso(itm['Date']) : '',
                    ItemDescription: itm['Description'],
                    Quantity: itm['Quantity'].includes(',') ? itm['Quantity'].replace(/,/g, '') : itm['Quantity'],
                    Memo: itm['Memo'],
                    BusinessUnit: userBusinessUnit(),
                    PoolWeek: props.selectedPoolWeek.PoolWeek
                };
            });
        }

        if (props.fileType === 'Inventory On Hand') {
            resultArray = convertedData.map(function (itm) {
                return {
                    ItemID: parseInt(itm['Item']),
                    OnHand: itm['On Hand'].includes(',') ? itm['On Hand'].replace(/,/g, '') : itm['On Hand'],
                    PreferredVendor: itm['Pref. Vendor'] || '',
                    PhysicalCount: itm['Physical Count'] || '',
                    LastModifiedDate: new Date().toISOString(),
                    Description: itm['Description'],
                    BusinessUnit: userBusinessUnit(),
                    PoolWeek: props.selectedPoolWeek.PoolWeek
                };
            });
        }

        setConvertedJsonFile(resultArray);
    };

    const formatImportCostData = async (data) => {
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        const resultArray = data.map(function (itm) {
            return {
                ShipmentNumber: itm['Shipment Number'],
                GrowerID: parseInt(itm['Grower ID']),
                ItemID: parseInt(itm['Item ID']),
                FDC: itm['FDC'],
                Trays: itm['Trays'].includes(',') ? itm['Trays'].replace(/,/g, '') : itm['Trays'],
                ContainerNumber: itm['Container Number'],
                ReportType: itm['Report Type'],
                CustomClearanceFee: itm['Custom Clearance Fee'].includes(',')
                    ? itm['Custom Clearance Fee'].replace(/,/g, '')
                    : itm['Custom Clearance Fee'],
                FreightCustomToFDC: itm['Freight Custom To FDC'].includes(',')
                    ? itm['Freight Custom To FDC'].replace(/,/g, '')
                    : itm['Freight Custom To FDC'],
                RepackagingCharge: itm['Repackaging Charge'].includes(',') ? itm['Repackaging Charge'].replace(/,/g, '') : itm['Repackaging Charge'],
                Others1: itm['Others 1'],
                Others2: itm['Others 2'],
                Others3: itm['Others 3']
            };
        });
        setConvertedJsonFile({
            BusinessUnit: userBusinessUnit(),
            PoolWeek: props.selectedPoolWeek.PoolWeek,
            RequestBy: loggedInUser,
            Data: resultArray
        });
    };

    const validateFile = (keys) => {
        if (props.fileType === 'Goods In Transit') {
            setIsCsvValid(goodsInTransitFields.sort().toString() === keys.sort().toString());
        }
        if (props.fileType === 'Inventory On Hand') {
            setIsCsvValid(inventoryOnHandFields.sort().toString() === keys.sort().toString());
        }
        if (props.fileType === 'Import Cost') {
            setIsCsvValid(importCostFields.sort().toString() === keys.sort().toString());
        }
    };

    const handleSubmit = async () => {
        props.setOpenUploadDialog(false);
        const name = props.fileType === 'Goods In Transit' ? 'GoodsInTransit' : props.fileType === 'Import Cost' ? 'Import Cost' : 'InventoryOnHand';
        const url =
            props.fileType === 'Goods In Transit'
                ? APIEndPoints.POST_INVENTORY_ON_TRANSIT()
                : props.fileType === 'Import Cost'
                ? APIEndPoints.POST_IMPORT_COST_UPLOAD()
                : APIEndPoints.POST_INVENTORY_ON_HAND();
        try {
            props.showLoadingScreenAction('Processing');
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: name,
                    url: url,
                    method: 'POST',
                    options: {},
                    data: convertedJsonFile
                },
                token
            );
            if (response.errors.length && !response.raw) {
                props.hideLoadingScreenAction();
                return props.showToast(response.errors[0], false);
            }
            props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            if (props.poolweekFreezeData && props.poolweekFreezeData.Status === 'Open' && props.fileType !== 'Import Cost') freezePoolWeek();
            props.fileType !== 'Import Cost' && fileuploadGlueJob();
            props.hideLoadingScreenAction();
        } catch (err) {
            props.hideLoadingScreenAction();
            props.showToast(<DrcTranslate>{'An Error Occurred'}</DrcTranslate>, false);
            console.log('error : ', err);
        }
        setTimeout(props.refreshData, 1000); // to get data just after successful post api
        handleClose();
    };

    const freezePoolWeek = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            let response = await Call(
                {
                    name: 'PoolWeekShift',
                    url: APIEndPoints.POST_POOLWEEK_SHIFT(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        BusinessUnit: userBusinessUnit(),
                        Status: 'Freeze',
                        CreatedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!response) {
                showError();
            } else if (response.display.Status === 409) {
                showError(response.display.Message, 'Something Went Wrong');
            } else {
                props.showToast(<DrcTranslate>{'PoolWeek Freezed Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
    };

    const fileuploadGlueJob = async () => {
        try {
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    url: APIEndPoints.POST_CSV_FILE_UPLOAD(),
                    method: 'POST',
                    options: {},
                    data: {
                        jobname: 'Job_ggs_transactional_tables_git_onhand_load',
                        arguments: {
                            '--env': window.config.ENVIRONMENT_NAME,
                            '--poolweek': props.selectedPoolWeek.PoolWeek,
                            '--businessunit': userBusinessUnit(),
                            '--enable-glue-datacatalog': '',
                            '--tablelist': props.fileType === 'Inventory On Hand' ? 'T_INVENTORYONHAND' : 'T_INVENTORYINTRANSIT'
                        }
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            return response;
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', err);
        }
    };

    const showError = (msg, err) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    return (
        <DrcDialog className={classes.popupContainer} open={props.open}>
            <div className={classes.popupStyle}>
                <div className={classes.modalContent}>
                    {props.showLoadingScreen && (
                        <div className={classes.loadingSection}>
                            <DrcLoading />
                        </div>
                    )}
                    <h6 className={classes.title}>
                        <DrcTranslate>{'Import Data'}</DrcTranslate>{' '}
                    </h6>
                    <Divider className={classes.divider} />

                    <form style={{ width: '100%', marginBottom: '20px' }} className="box" method="post" action="" encType="multipart/form-data">
                        <div
                            onDragOver={(ev) => {
                                ev.preventDefault();
                            }}
                            onDrop={onDropHandler}
                        >
                            <input
                                type="file"
                                id="file"
                                accept=".csv"
                                className={classes.boxFile}
                                onChange={(e) => {
                                    handleFileChosen(e.target.files);
                                }}
                            />
                            <label className={classes.boxInput} htmlFor="file">
                                {fileName && (
                                    <div className={classes.selectedFile}>
                                        <strong>{fileName}</strong>
                                    </div>
                                )}
                                <p>
                                    <strong>
                                        <DrcTranslate>{'Choose a file '}</DrcTranslate>
                                    </strong>
                                    <span>
                                        <DrcTranslate>{'or drag it here.'}</DrcTranslate>
                                    </span>
                                </p>
                            </label>
                        </div>
                        <p className={classes.note}>
                            <DrcTranslate>{'Only *.csv file should be uploaded for acceptance.'}</DrcTranslate>
                            <br />
                            {fileName && !isCsvValid && <DrcTranslate>{'Please Enter a Valid File.'}</DrcTranslate>}
                        </p>
                    </form>
                    <div style={{ alignSelf: 'flex-end' }}>
                        <DrcButton className={classes.buttonsNew} size="large" onClick={handleClose}>
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            className={classes.buttonsNew}
                            isPrimary
                            size="large"
                            onClick={() => {
                                handleSubmit();
                            }}
                            disabled={disableSubmit || !isCsvValid}
                        >
                            <DrcTranslate>{'Submit'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
            </div>
        </DrcDialog>
    );
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        showToast: state.rootReducer.showToast,
        selectedPoolWeek: state.weeklySettlementStatusReducer.selectedPoolWeek,
        selectedSettlementRecord: state.weeklySettlementStatusReducer.records,
        poolweekFreezeData: state.weeklySettlementStatusReducer.poolweekFreezeData
    };
}

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withRouter(withStyles(styles)(DrcFileUploadDialog))));
