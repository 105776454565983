import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeStyles, Box } from '@material-ui/core';
import Form from 'react-validation/build/form';

import { DrcDialog, DrcButton, DrcTranslate, DrcChipsMultiSelect, DrcTooltip } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Call } from '@driscollsinc/driscolls-display-rules';

import { userBusinessUnit } from '../../../utils/helper';
import DataTable from '../../../components/DataTable';
import APIEndPoints from '../../../services/api';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';

const useStyles = makeStyles((theme) => ({}));

const ItemsTab = (props) => {
    const [itemsData, setItemsData] = useState([]);
    const [editPoolTableItem, setEditPoolTableItem] = useState({});
    const [showAddDialog, setShowAddDialog] = useState(false);

    const initialAddPoolTableItem = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };

    useEffect(() => {
        setupData();
        loadData();
    }, []);

    const setupData = () => {
        setEditPoolTableItem({
            ...initialAddPoolTableItem
        });
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const updateItems = (itemsArray) => {
        var updatedItemsData = [];
        let selectedItems = (props?.selectedKey?.Item?.ItemSelected || '').split(',') || [];
        itemsArray.forEach((item) => {
            updatedItemsData.push({
                ...item,
                selected: selectedItems.includes('' + item.ItemID)
            });
        });
        return updatedItemsData;
    };

    const loadData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_ITEM_UOM_CONVERSION_LIST('', '', userBusinessUnit(), props.userLang, ''),
                    method: 'GET'
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            var newData = updateItems(data.converted.Data);
            setItemsData(newData);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const PostPoolTableItem = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Posting data'}</DrcTranslate>);
        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'PUT_POOLTABLEITEM',
                    url: APIEndPoints.PUTPOST_POOLTABLEITEM(),
                    method: 'POST',
                    data: {
                        PoolTableItem: itemsData
                            .filter((item) => {
                                return item.selected;
                            })
                            .map((item) => {
                                return { ItemID: '' + item.ItemID, Description: item.Description };
                            }),
                        PoolID: editPoolTableItem['PoolID'],
                        DataareaID: editPoolTableItem['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowAddDialog(false);
                setEditPoolTableItem({ ...initialAddPoolTableItem });
                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowAddDialog(false);
                setEditPoolTableItem({ ...initialAddPoolTableItem });
                return showError((data.display.Message, (<DrcTranslate>{'Duplicate Record'}</DrcTranslate>)));
            } else {
                props.hideLoadingScreenAction();
                setShowAddDialog(false);
                setEditPoolTableItem({ ...initialAddPoolTableItem });
                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
        props.hideLoadingScreenAction();
    };

    const handleSelectChange = (item, isSelected) => {
        let newData = itemsData.reduce((arr, data) => {
            if (item.key === data.ItemID) {
                data.selected = isSelected;
            }
            arr.push(data);
            return arr;
        }, []);
        setItemsData(newData);
    };

    let data = itemsData.filter((item) => !!item.selected);
    return (
        <>
            <Box
                sx={{
                    margin: 'auto',
                    marginRight: 'inherit'
                }}
            >
                <DrcTooltip tipText="Select Items" placement="left">
                    <NoteAdd onClick={() => setShowAddDialog(true)} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                </DrcTooltip>
            </Box>
            <DataTable
                total={data.length}
                data={data || []}
                columns={[
                    { key: 'ItemID', name: 'Item Id' },
                    { key: 'Description', name: 'Description' }
                ]}
            />
            <DrcDialog
                title={<DrcTranslate>{'Update Items'}</DrcTranslate>}
                open={showAddDialog}
                buttons={
                    <>
                        <DrcButton
                            poly
                            line
                            primary
                            onClick={() => {
                                PostPoolTableItem();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            poly
                            line
                            onClick={() => {
                                setEditPoolTableItem({ ...initialAddPoolTableItem });
                                setShowAddDialog(!showAddDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <Form>
                    <DrcChipsMultiSelect
                        chips={itemsData
                            .filter((item) => item.selected)
                            .map((item) => {
                                return { key: item.ItemID, value: item.ItemID, label: item.Description };
                            })}
                        options={itemsData.map((item) => {
                            return { key: item.ItemID, value: item.ItemID, label: item.Description };
                        })}
                        onSelectChange={(item) => handleSelectChange(item, true)}
                        onChipDelete={(item) => handleSelectChange(item, false)}
                    />
                </Form>
            </DrcDialog>
        </>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(ItemsTab));
