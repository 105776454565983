import React, { useState, useEffect } from 'react';
import { DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, useTheme } from '@material-ui/core';
import AccordionPoolDefinition from '../components/AccordionPoolDefinition';
import { getAccordionColor, poolStatus } from '../data/constants';
import DrcWrapper from '../components/DrcWrapper';
import Stepper from '../components/Stepper';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { showLoadingScreenAction, hideLoadingScreenAction, setPageTitleAction } from '../actions/actions';
import { setPricingPoolData } from '../actions/PricingPoolAction';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../services/api';
import PricingPoolDetail from './PricingPool/PricingPoolDetail';

const pageTitle = 'Pricing Pool';
const useStyles = makeStyles((theme) => ({
    legendItem: {
        '&': {
            color: theme.dark.primary,
            [theme.darkTheme]: {
                color: `${theme.light.primary} !important`
            },
            fontSize: 'x-small',
            fontWeight: 'bold',
            marginRight: 8
        },
        '&::before': {
            content: '""',
            display: 'inline-block',
            position: 'relative',
            minWidth: '12px',
            minHeight: '12px',
            borderRadius: '100%',
            marginRight: '5px',
            top: '2.5px'
        },
        '&[legendType=Approved]::before': {
            backgroundColor: getAccordionColor(theme, 'Approved')
        },
        '&[legendType="New"]::before': {
            backgroundColor: getAccordionColor(theme, 'Not Run')
        },
        '&[legendType="Closed"]::before': {
            backgroundColor: getAccordionColor(theme, 'Processed')
        }
    },
    poolContainer: {
        borderRadius: '8px 8px 0px 0px',
        marginRight: 10,
        padding: '2px 0px 1px 16px',
        backgroundColor: theme.palette.grey['400'],
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['800']} !important`
        }
    },
    root: {
        '& .select': {
            top: 2,
            maxWidth: '400px'
        },
        '& .select > div:first-of-type': {
            backgroundColor: theme.light.primary,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.primary} !important`
            }
        }
    },
    childAccordion: {
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: `${theme.dark.primary} !important`
        },
        margin: '0px',
        '& .MuiAccordionSummary-root': {
            height: 40
        }
    },
    iconText: {
        paddingRight: '8px',
        fontSize: '28px',
        width: '50px',
        height: '20px',
        marginRight: '-10px'
    }
}));

// Component Main Function
const PricingPool = (props) => {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pageSize, setPageSize] = useState(50);

    useEffect(() => {
        getData();
        if (props.pageTitle !== pageTitle) {
            props.setPageTitleAction(pageTitle);
        }
    }, []);

    const getData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'PoolTable',
                    url: APIEndPoints.GET_POOL_TABLE(page / pageSize + 1, pageSize, 'DOC', props.userLang),
                    method: 'GET',
                    options: {}
                },
                token
            );
            let parentPools = {};
            let pricingPoolsMap = {};
            let settlementPoolsMap = {};
            data.raw.data.forEach((itm) => {
                if (itm.Identification.LevelID === 1) {
                    parentPools[itm.Identification.PoolTableID] = itm;
                } else if (itm.Identification.LevelID === 2) {
                    let data = pricingPoolsMap[itm.Identification.ParentPoolIDRec] || [];
                    data.push(itm);
                    pricingPoolsMap[itm.Identification.ParentPoolIDRec] = data;
                } else if (itm.Identification.LevelID === 3) {
                    let data = settlementPoolsMap[itm.Identification.ParentPoolIDRec] || [];
                    data.push(itm);
                    settlementPoolsMap[itm.Identification.ParentPoolIDRec] = data;
                }
            });
            props.setPricingPoolData({ data: data.raw.data, parentPools, pricingPoolsMap, settlementPoolsMap });
            // setPricingPools(data);
            // setParentPools(parentPools);
            // setPricingPoolsMap(pricingPoolsMap);
            // setSettlementPoolsMap(settlementPoolsMap);
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error :', error);
        }
    };

    const classes = useStyles();
    const theme = useTheme();
    let options = [
        { label: '210 Driscolls Strawberry Associates Inc.', value: 'value1' },
        { label: '150 Driscolls Strawberry Associates Inc.', value: 'value2' },
        { label: '120 Driscolls Strawberry Associates Inc.', value: 'value3' }
    ];

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    return (
        <DrcWrapper>
            <Stepper page={pageTitle} />
            <div className={classes.poolContainer}>
                <DrcSelect
                    options={options}
                    label=" "
                    hideLabel={true}
                    className={classes.root}
                    onChange={(option) => {
                        console.log(option);
                    }}
                />
            </div>
            {Object.keys(props.parentPools).map((key, indx) => (
                <AccordionPoolDefinition
                    key={indx}
                    title={props.parentPools[key].Identification.Description}
                    poolStatus="Not Closed"
                    elevation={2}
                    selectedKey={key}
                    data={props.parentPools[key]}
                    disableDialog
                >
                    <PricingPoolDetail id={key} />
                </AccordionPoolDefinition>
            ))}
            <Box sx={{ flexGrow: 1 }} mt={2}>
                <Grid container spacing={3}>
                    {poolStatus.map((itm, index) => (
                        <Grid key={index} item xs={1.5}>
                            <span key={index} className={classes.legendItem} legendType={itm}>
                                <DrcTranslate>{itm}</DrcTranslate>
                            </span>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </DrcWrapper>
    );
};

const mapStateToProps = ({ masterReducer, pricingPoolReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    parentPools: pricingPoolReducer.parentPools,
    pricingPoolsMap: pricingPoolReducer.pricingPoolsMap,
    pageTitle: rootReducer.pageTitle,
    settlementPoolsMap: pricingPoolReducer.settlementPoolsMap
});

export default connect(mapStateToProps, { showLoadingScreenAction, hideLoadingScreenAction, setPricingPoolData, setPageTitleAction })(
    withOktaAuth(withRouter(PricingPool))
);
