import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Typography } from '@material-ui/core';

import { DrcButton, DrcSelect, DrcInput, DrcChips, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { Call } from '@driscollsinc/driscolls-display-rules';

import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { ReactComponent as Delete } from '../../../Images/SVG/Delete_Enabled.svg';
import { userBusinessUnit } from '../../../utils/helper';
import { designColors } from '../../../data/constants';
import CommoditiesTable from '../CommoditiesTable';
import APIEndPoints from '../../../services/api';

const CommoditiesTab = (props) => {
    const [showCommodityBox, setShowCommodityBox] = useState(false);
    const [commodityChips, setCommodityChips] = useState([]);
    const [commodityData, setCommodityData] = useState([]);
    const [editPoolTableCommodity, setEditPoolTableCommoditie] = useState({});
    const [commoditiesConfig, setCommoditiesConfig] = useState({ CommoditieSelected: '', CommoditieSelection: 0 });

    const initialAddPoolTableCommodity = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };

    useEffect(() => {
        setupData();
        loadData();
    }, []);

    const setupData = () => {
        setCommoditiesConfig(props.selectedKey.Commoditie);
        setEditPoolTableCommoditie({
            ...initialAddPoolTableCommodity
        });
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const updateCommoditiesArray = (commoditiesArray) => {
        var updatedCommoditiesData = [];
        if (itemsConfig.ItemSelection === 0) {
            commoditiesArray.forEach((item) => {
                updatedCommoditiesData.push({ ...item, selected: true });
            });
        } else {
            let selectedItems = itemsConfig.ItemSelected.split(',');
            commoditiesArray.forEach((item) => {
                updatedCommoditiesData.push({
                    ...item,
                    selected: selectedItems.contains((sel) => {
                        return sel === item.ItemID;
                    })
                });
            });
        }
        return updatedCommoditiesData;
    };

    const loadData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_COMMODITIES('', '', userBusinessUnit(), props.userLang, ''),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            var newData = updateCommoditiesArray(data.converted.Data);
            setItemsData(newData);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const addChipsCommodity = (e) => {
        e.map((item) => {
            if (!commodityChips.filter((chip) => chip.key === item.CommodityId).length > 0) {
                setCommodityChips((commodityChips) => [...commodityChips, { key: item.CommodityId, label: item.Code }]);
            }
        });
    };

    const postCommodities = async () => {
        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'POST_COMMODITIES',
                    url: APIEndPoints.PUTPOST_POOLTABLECOMMODITY(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolTableCommodity: commodityData
                            .filter((commoditie) => {
                                return commoditie.selected;
                            })
                            .map((commoditie) => {
                                return { CommodityID: commoditie.CommodityID, Description: commoditie.Description };
                            }),
                        PoolID: editPoolTableCommodity['PoolID'],
                        DataareaID: editPoolTableCommodity['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowAddDialog(false);

                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowAddDialog(false);

                return showError((data.display.Message, 'Duplicate Record'));
            } else {
                props.hideLoadingScreenAction();

                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                // this.loadData();
            }
        } catch (error) {
            showError(error);
        }
    };

    const removeChip = (id) => {
        setCommodityChips(commodityChips.filter((item) => item.key !== id));
    };

    const updateCommodities = () => {
        console.log('commdities data: ', commodityChips);
    };

    const CommodityBox = () => {
        return (
            <Box sx={{ width: '100%', display: 'inlineBlock', height: 'auto', marginBottom: '30px' }}>
                <Box sx={{ width: '100%', justifyContent: 'space-between' }}>
                    <CloseIcon
                        style={{ float: 'right', marginLeft: '97%', fontSize: 'large', cursor: 'pointer' }}
                        onClick={() => setShowCommodityBox(false)}
                    />
                    <Typography component="h6" style={{ color: `${designColors.blue}` }}>
                        <DrcTranslate>{'Add Commodity from below'}</DrcTranslate>
                    </Typography>
                    <Typography component="span">
                        <DrcTranslate>{'Selected Commodities'}</DrcTranslate>
                    </Typography>
                    <DrcButton
                        style={{ width: '20px', marginLeft: '90%', color: `${designColors.blue}` }}
                        isPrimary={true}
                        size="small"
                        onClick={updateCommodities}
                    >
                        OK
                    </DrcButton>
                </Box>
                <Box sx={{ width: '100%', height: 'auto', border: '1px solid #ccc', borderRadius: '5px', padding: '10px' }}>
                    <DrcChips
                        chips={commodityChips}
                        onDelete={(e) => {
                            removeChip(e.key);
                        }}
                    />
                </Box>
                <Box sx={{ width: '100%', height: 'auto', marginBottom: '10px', border: '2px' }}>
                    <DrcInput label="Search Commodities" placeholder="Filter" style={{ width: '200px' }} />
                    <CommoditiesTable addChipsCommodity={(e) => addChipsCommodity(e)} />
                </Box>
            </Box>
        );
    };
    return (
        <>
            <Box
                sx={{
                    width: '30%',
                    marginRight: 'auto',
                    display: 'inlineBlock'
                }}
            >
                <DrcSelect label="Commodity Selection" hiddenLabel={true} isCreatable={true} />
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    marginRight: 'inherit'
                }}
            >
                <NoteAdd onClick={() => setShowCommodityBox(true)} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                <Delete onClick={() => console.log('deleting')} style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
            </Box>
            <Box
                sx={{
                    width: '99%',
                    margin: 'auto',
                    marginTop: '25px'
                }}
            >
                {showCommodityBox ? CommodityBox : null /* table can be inserted here */}
                {/* Table will appear here */}
            </Box>
        </>
    );
};
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(CommoditiesTab));
