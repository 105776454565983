import React from 'react';
import { Divider } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcSelect, DrcTranslate, DrcButton, DrcTooltip } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, makeOptions, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { growerCategoryDsColumns, LOOKUP_FIELDS, geographyOptions } from '../../../data/constants';
import Form from 'react-validation/build/form';
import GGSFormInput from '../../../components/GGSFormInput';
import { alphaNumeric, emailsAsCsv, numeric } from '../../../utils/validations';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';

const pageTitle = 'Grower Category';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class GrowerCategory extends React.Component {
    state = {
        data: [],
        convertedData: [],
        displayData: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        invalidFields: []
    };
    excludingFields = ['Actions', 'GrowerListID'];
    readOnly = false;
    uniqueKeyField = 'GrowerListID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;
    validationFields = {
        ContactEmailId: {
            key: 'ContactEmailId',
            // validations: [maxlen, numeric]
            validations: [emailsAsCsv]
        },
        CostCenter: {
            key: 'CostCenter',
            validations: [alphaNumeric]
        },
        CustomerCode: {
            key: 'CustomerCode',
            validations: [numeric]
        },
        ShortName: {
            key: 'ShortName',
            validations: [alphaNumeric]
        },
        GrowerName: {
            key: 'GrowerName',
            validations: []
        },
        ParentVendorCode: {
            key: 'ParentVendorCode',
            validations: []
        }
    };

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);

        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        if (
            this.state.editingRow.FamilyEntity !== prevState.editingRow.FamilyEntity ||
            this.state.editingRow.Category !== prevState.editingRow.Category
        )
            this.checkCategoryValidation();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    checkCategoryValidation() {
        if (
            ['Interco', 'Mid-size', 'Share', 'Share Grower'].includes(this.state.editingRow['Category']) &&
            this.state.editingRow['FamilyEntity'] === ''
        ) {
            return this.setState({ categoryError: 'Select Family Entity', invalidFields: ['FamilyEntity'] });
        } else {
            return this.setState({ categoryError: '', invalidFields: [] });
        }
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true }, invalidFields: [] });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    deleteData = async (_rowData) => !this.props.isUserAdmin;
    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'GrowerList',
                    url: APIEndPoints.PUT_GROWER_LIST(),
                    method: 'PUT',
                    options: {},
                    data: {
                        GrowerListID: this.state.editingRow['GrowerListID'],
                        GrowerName: this.state.editingRow['GrowerName'] || '',
                        InactiveFlag: this.state.editingRow['InactiveFlag'] || '',
                        LocationCode: this.state.editingRow['LocationCode'] || '',
                        FamilyEntity: this.state.editingRow['FamilyEntity'] || '',
                        ContactEmailId: this.state.editingRow['ContactEmailId'] || '',
                        AccountNo: this.state.editingRow['AccountNo'] || '',
                        Geography: this.state.editingRow['Geography'] || '',
                        Module: this.state.editingRow['Module'] || '',
                        ModifiedBy: loggedInUser,
                        ModifiedDateTime: new Date(),
                        CostCenter: this.state.editingRow['CostCenter'] || '',
                        VendorCode: this.state.editingRow['VendorCode'],
                        ShortName: this.state.editingRow['ShortName'] || '',
                        Category: this.state.editingRow['Category'] || '',
                        CustomerCode: this.state.editingRow['CustomerCode'] || '',
                        ParentVendorCode: this.state.editingRow['ParentVendorCode'] || '',
                        Lang: this.props.userLang
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response = {}, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  [
                      'FamilyEntity',
                      'ContactEmailId',
                      'CostCenter',
                      'ShortName',
                      'Category',
                      'Geography',
                      'LocationCode',
                      'CustomerCode',
                      'ParentVendorCode',
                      'Module'
                  ],
                  ['Actions'],
                  growerCategoryDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            convertedData: response.converted || [],
            displayData: response.display || [],
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'GrowerList',
                    url: APIEndPoints.GET_GROWER_LIST(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        this.props.userLang,
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getRowEditor = (key, col, others) => {
        switch (key) {
            case 'Category':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.categoryLookup, 'LookupCode', 'LookupCode'), false);
            case 'Module':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.growerModule, 'LookupCode', 'LookupCode'), false);
            case 'FamilyEntity':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.familyEntityLookUp, 'LookupCode', 'LookupCode'), true);
            case 'LocationCode':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.locationLookUp, 'LookupCode', 'LookupCode'), false);
            case 'Geography':
                return this.selectCellEditor(key, col, others, geographyOptions);
            case 'ParentVendorCode':
                return this.selectCellEditor(key, col, others, this.props.vendorCodeOptions);
            case 'ContactEmailId':
            case 'CustomerCode':
            case 'ShortName':
            case 'CostCenter':
                let field = this.validationFields[key];
                return this.inputTextEditor(key, col, others, field.validations, field.customProps);
        }
    };

    inputTextEditor = (key, _col, _others, validations = [], customProps = {}) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) => {
                        return this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }));
                    }}
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    selectCellEditor = (key, _col, _others, options, hasError) => {
        return (
            <>
                <DrcSelect
                    options={options}
                    label="Select"
                    hideLabel={true}
                    className={this.props.classes.root}
                    style={{ marginTop: `${hasError && this.state.categoryError ? '19px' : '0'}` }}
                    onChange={(option) => {
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: option.value }
                        }));
                    }}
                    value={options.find((itm) => itm.label === this.state.editingRow[key])}
                    menuShouldBlockScroll={true}
                    menuPortalTarget={document.body}
                    styles={{
                        menuList: (base) => ({
                            ...base,
                            // position: 'fixed !important',
                            // width: '15rem',
                            backgroundColor: this.props.theme.palette.common.white,
                            [this.props.theme.darkTheme]: {
                                backgroundColor: this.props.theme.palette.common.black
                            },
                            border: '1px solid lightgray',
                            width: 'auto',
                            zIndex: 9999
                        })
                    }}
                    menuPlacement={'auto'}
                />
                {hasError && this.state.categoryError && (
                    <DrcTooltip tipText={this.state.categoryError}>
                        <p style={{ color: 'red', fontSize: '10px', textOverflow: 'ellipsis' }}>{this.state.categoryError || ''}</p>
                    </DrcTooltip>
                )}
            </>
        );
    };
    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['GrowerCategory', 'Geography', 'FamilyEntity', 'LocationCode', 'VendorCode', 'CustomerCode']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'80px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(key, col, others);
                        }}
                    />
                </div>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer, vendorCodeReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    locationLookUp: masterReducer[LOOKUP_FIELDS.LocationLookUp],
    familyEntityLookUp: masterReducer[LOOKUP_FIELDS.FamilyEntityLookUp],
    growerModule: masterReducer[LOOKUP_FIELDS.GrowerModule],
    categoryLookup: masterReducer[LOOKUP_FIELDS.GrowerCategoryLookUp],
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    vendorCodeOptions: vendorCodeReducer.vendorCodeOptions,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(GrowerCategory))));
