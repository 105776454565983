import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { downloadFile, displayErrorMessage, makeColumns } from '../../utils/helper';
import MasterDataUtilities from '../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../services/api';
import { designColors, jeShanghaiFamilyEntity, jeShanghaiFinal } from '../../data/constants';
import PrimeReactTable from '../../components/PrimeReactTable';
import NoData from '../../components/NoData';
import { Typography } from '@material-ui/core';
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    icon: {
        marginRight: 5
    }
});

class JEShanghai extends Component {
    state = {
        data1: [],
        data2: [],
        data3: [],
        dataExport: [],
        pageData1: [],
        pageData2: [],
        pageData3: [],
        pageNum1: 0,
        pageNum2: 0,
        pageNum3: 0,
        pageSize: 50,
        totalCount1: 0,
        totalCount2: 0,
        totalCount3: 0,
        columns1: [],
        columns2: []
    };

    excludingFields = [];
    readOnly = true;
    uniqueKeyField = 'GrowerID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.props.loadFilteredData(this.loadData.bind(this));
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }
    updateTableData = (response, hasFrozenColumns = false) => {
        let data1 = response?.display?.FamilyEntityEntryBooking || [];
        let dataExport = response?.raw?.data?.FamilyEntityEntryBooking || [];
        let data2 = response?.display['ShanghaiEntryBooking-Preliminary'] || [];
        let data3 = response?.display['ShanghaiEntryBooking-Final'] || [];
        let columns1 = data1.length
            ? makeColumns(
                  Object.keys(data1[0]),
                  this.excludingFields,
                  null,
                  false,
                  [],
                  [],
                  this.props.filters.ReportType === 'Preliminary' ? jeShanghaiFamilyEntity : jeShanghaiFinal
              )
            : [];
        let columns2 = data2.length ? makeColumns(Object.keys(data1[0]), this.excludingFields, null, false, [], [], jeShanghaiFinal) : [];
        let start1 = Math.floor(this.state.pageNum1 / this.state.pageSize) * this.state.pageSize;
        let start2 = Math.floor(this.state.pageNum2 / this.state.pageSize) * this.state.pageSize;
        let start3 = Math.floor(this.state.pageNum3 / this.state.pageSize) * this.state.pageSize;
        this.setState({
            data1,
            data2,
            data3,
            dataExport,
            totalCount1: data1.length || 0,
            totalCount2: data2.length || 0,
            totalCount3: data3.length || 0,
            pageData1: data1.slice(start1, start1 + this.state.pageSize) || [],
            pageData2: data2.slice(start2, start2 + this.state.pageSize) || [],
            pageData3: data3.slice(start3, start3 + this.state.pageSize) || [],
            columns1,
            columns2,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            var data = await Call(
                {
                    name: 'JeShanghai',
                    url: APIEndPoints.POST_JOURNAL_ENTRY_REPORT(),
                    method: 'POST',
                    options: {},
                    data: {
                        SearchBy: loggedInUser,
                        ...this.props.filters
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (pageNum, pageData, data) => (event) => {
        let start = Math.floor(event.first / this.state.pageSize) * this.state.pageSize;
        this.setState({ [pageNum]: event.first, [pageData]: this.state[data].slice(start, start + this.state.pageSize) });
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    exportData = async (name, data, col) => {
        try {
            this.props.showLoadingScreenAction(
                <React.Fragment>
                    <h4 style={{ fontSize: '1.5rem' }}>
                        <DrcTranslate>{'Preparing download'}</DrcTranslate>
                    </h4>
                    <div>
                        <DrcTranslate>{'Your download should start in a few seconds.'}</DrcTranslate>
                    </div>
                </React.Fragment>
            );
            this.props.hideLoadingScreenAction();
            downloadFile(`${name}_${this.props.poolWeek.value}_${this.props.filters.ReportType}.xlsx`, data, col, []);
        } catch (err) {
            console.log(err);
        }
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.tableContainer}>
                <div className={classes.actionContainer}>
                    <Typography variant="h6" noWrap style={{ color: designColors.blue }}>
                        <DrcTranslate>{'JE - Shanghai'}</DrcTranslate>
                    </Typography>
                    <DrcButton
                        onClick={() =>
                            this.exportData(
                                'JE-ShanghaiReport',
                                this.state.dataExport,
                                this.props.filters.ReportType === 'Preliminary' ? jeShanghaiFamilyEntity : jeShanghaiFinal
                            )
                        }
                        style={{ fontWeight: 'bold', color: designColors.blue }}
                        disabled={!this.state.data1.length}
                    >
                        <VerticalAlignBottomIcon className={classes.icon} /> <DrcTranslate>{'Export'}</DrcTranslate>
                    </DrcButton>
                </div>
                {!!this.state.data1.length ? (
                    <PrimeReactTable
                        paginator={this.state.data1.length > 0}
                        currentPage={this.state.pageNum1}
                        onPage={this.onPage('pageNum1', 'pageData1', 'data1')}
                        columns={this.state.columns1}
                        value={this.state.pageData1}
                        gridStyles={classes.gridStyles}
                        totalRecords={this.state.totalCount1}
                        resultCount={this.state.totalCount1}
                        pageSize={this.state.pageSize}
                        uniqueKeyField={this.uniqueKeyField}
                        tableSelectionMode="checkbox"
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                    />
                ) : (
                    <NoData />
                )}
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(JEShanghai)));
