import React from 'react';
import { Divider } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcButton, DrcCheckbox, DrcDialog, DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, makeOptions, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import Grid from '@material-ui/core/Grid';
import { customerCodeDsColumns, LOOKUP_FIELDS } from '../../../data/constants';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { numeric, maxlen } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const pageTitle = 'Customer Code Category';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    dialogWrapper: {
        '& .MuiDialog-paperWidthSm': {
            overflow: 'hidden',
            maxWidth: '900px !important', //TO override dialog maxWidth
            minHeight: '300px',
            maxHeight: '70vh',
            minWidth: '700px !important'
        }
    },
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0px 0px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class CustomerCodeCategory extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        savebuttonDisabled: false,
        showDialog: false,
        deleteId: 0
    };
    excludingFields = ['Actions', 'CustomerCodeMapID', 'BusinessUnit'];
    readOnly = false;
    uniqueKeyField = 'CustomerCodeMapID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;
    validationFields = {
        Category: {
            key: 'Category',
            validations: []
        },
        ExcludeAveragePricing: {
            key: '',
            validations: []
        },
        CustomerCode: {
            key: 'CustomerCode',
            validations: [maxlen, numeric],
            customProps: { maxlen: 100 }
        }
    };

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true }, savebuttonDisabled: false });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, savebuttonDisabled: false });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    deleteData = (rowData) => {
        this.setState({ showDialog: true, deleteId: rowData.CustomerCodeMapID });
    };
    deleteHandler = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.PUT_CUSTOMER_CODE_CATEGORY(),
                    method: 'DELETE',
                    options: {},
                    data: { ID: this.state.deleteId }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Deleted Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError(error);
        }
        this.setState({ showDialog: false });
    };

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'CustomerCodeCategory',
                    url: APIEndPoints.PUT_CUSTOMER_CODE_CATEGORY(),
                    method: 'PUT',
                    options: {},
                    data: {
                        CustomerCodeMapID: this.state.editingRow['CustomerCodeMapID'],
                        ExcludeNormalSalesAmt: this.state.editingRow['ExcludeNormalSalesAmt'] ? 1 : 0,
                        ExcludeNormalSalesQty: this.state.editingRow['ExcludeNormalSalesQty'] ? 1 : 0,
                        ExcludeSettlementPriceQty: this.state.editingRow['ExcludeSettlementPriceQty'] ? 1 : 0,
                        ExcludeSettlementPriceAmt: this.state.editingRow['ExcludeSettlementPriceAmt'] ? 1 : 0,
                        CustomerCode: parseInt(this.state.editingRow['CustomerCode']),
                        Category: this.state.editingRow['Category'],
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.PUT_CUSTOMER_CODE_CATEGORY(),
                    method: 'POST',
                    options: {},
                    data: {
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        ExcludeNormalSalesAmt: this.state.newRecord['ExcludeNormalSalesAmt'] ? 1 : 0,
                        ExcludeNormalSalesQty: this.state.newRecord['ExcludeNormalSalesQty'] ? 1 : 0,
                        ExcludeSettlementPriceQty: this.state.newRecord['ExcludeSettlementPriceQty'] ? 1 : 0,
                        ExcludeSettlementPriceAmt: this.state.newRecord['ExcludeSettlementPriceAmt'] ? 1 : 0,
                        CustomerCode: parseInt(this.state.newRecord['CustomerCode']),
                        ModifiedBy: loggedInUser,
                        Category: this.state.newRecord['Category'].value,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );

            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate Record');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError();
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                isDeletable
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => this.state.savebuttonDisabled}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  [
                      'CustomerCode',
                      'Category',
                      'ExcludeNormalSalesAmt',
                      'ExcludeNormalSalesQty',
                      'ExcludeSettlementPriceQty',
                      'ExcludeSettlementPriceAmt'
                  ],
                  [],
                  customerCodeDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            convertedData: response.converted || [],
            displayData: response.display || [],
            unfreeze: !hasFrozenColumns
        });

        this.setState((prevState) => ({
            columns: prevState.columns.map((obj) => {
                switch (obj.key) {
                    case 'ExcludeSettlementPriceAmt':
                        Object.assign(obj, { columnTemplate: this.colTempExcludeSettlementPriceAmt });
                        break;
                    case 'ExcludeSettlementPriceQty':
                        Object.assign(obj, { columnTemplate: this.colTempExcludeSettlementPriceQty });
                        break;
                    case 'ExcludeNormalSalesQty':
                        Object.assign(obj, { columnTemplate: this.colTempExcludeNormalSalesQty });
                        break;
                    case 'ExcludeNormalSalesAmt':
                        Object.assign(obj, { columnTemplate: this.colTempExcludeNormalSalesAmt });
                        break;
                }
                return obj;
            })
        }));
    };

    colTempExcludeSettlementPriceAmt = (rowData) => {
        return <DrcCheckbox checked={rowData.ExcludeSettlementPriceAmt} onChange={() => null} />;
    };
    colTempExcludeSettlementPriceQty = (rowData) => {
        return <DrcCheckbox checked={rowData.ExcludeSettlementPriceQty} onChange={() => null} />;
    };
    colTempExcludeNormalSalesQty = (rowData) => {
        return <DrcCheckbox checked={rowData.ExcludeNormalSalesQty} onChange={() => null} />;
    };
    colTempExcludeNormalSalesAmt = (rowData) => {
        return <DrcCheckbox checked={rowData.ExcludeNormalSalesAmt} onChange={() => null} />;
    };

    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'CustomerCodeCategory',
                    url: APIEndPoints.GET_CUSTOMER_CODE_CATEGORY(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    getRowEditor = (key, col, others) => {
        switch (key) {
            case 'Category':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.customerCodeCategoryLookUp, 'LookupCode', 'LookupCode'));
            case 'ExcludeSettlementPriceAmt':
            case 'ExcludeSettlementPriceQty':
            case 'ExcludeNormalSalesQty':
            case 'ExcludeNormalSalesAmt':
                return this.switchEditor(key);
            case 'CustomerCode':
                let field = this.validationFields[key];
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
        }
    };
    switchEditor = (key) => {
        return (
            <DrcCheckbox
                checked={this.state.editingRow[key]}
                value={this.state.editingRow[key]}
                onChange={(_e, val) => {
                    this.setState((_prevState) => ({
                        editingRow: { ...this.state.editingRow, [key]: val }
                    }));
                }}
            />
        );
    };
    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                    {...customProps}
                />
            </Form>
        );
    };
    selectCellEditor = (key, col, others, option) => {
        return (
            <DrcSelect
                options={option}
                label="Select"
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={option.find((itm) => itm.label === this.state.editingRow[key])}
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     * @memberof CustomerCodeCategory
     */
    handleValidationDetected = (validate) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            this.setState({ savebuttonDisabled: true });
        } else {
            this.setState({ savebuttonDisabled: false });
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['CustomerCodeCategory', 'CustomerCode']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    {!!this.state.columns.length && (
                        <DrcButton
                            onClick={(event) => {
                                this.setState({ showAddNew: true });
                            }}
                            noStyle
                            title="Add"
                            disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                        >
                            <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                            <DrcTranslate>{'Add'}</DrcTranslate>
                        </DrcButton>
                    )}
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'100px'}
                        rowSelect={false}
                        selectionMode="multiple"
                        tableSelectionMode="checkbox"
                        selected={this.state.selectedRow}
                        selectedFrozen={true}
                        selectedClass={classes.selectedClass}
                        selectionChange={(e) => {
                            this.setState({ selectedRow: e.value });
                        }}
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(key, col, others);
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    // className={classes.dialogWrapper}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    this.state.savebuttonDisabled
                                        ? this.state.savebuttonDisabled
                                        : this.state.newRecord.CustomerCode == undefined || this.state.newRecord.Category == undefined
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <GGSFormInput
                                label={<DrcTranslate>{'Customer Code'}</DrcTranslate>}
                                required
                                // add validations for field
                                validations={this.validationFields['CustomerCode'].validations}
                                // add custom props for field
                                {...this.validationFields['CustomerCode'].customProps}
                                validationDetected={(validate) => this.handleValidationDetected(validate)}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, CustomerCode: e.target.value } }))
                                }
                            />
                            <DrcSelect
                                label={<DrcTranslate>{'Category'}</DrcTranslate>}
                                required
                                onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Category: option } }))}
                                value={this.state.newRecord['Category']}
                                className={this.props.classes.input}
                                options={makeOptions(this.props.customerCodeCategoryLookUp, 'LookupCode', 'LookupCode')}
                            />
                            {/* <DrcSwitch
                                checked={this.state.newRecord['ExcludeAveragePricing'] === 'Y'}
                                value={this.state.newRecord['ExcludeAveragePricing']}
                                onChange={(e, val) => {
                                    this.setState((prevState) => ({
                                        newRecord: { ...prevState.newRecord, ExcludeAveragePricing: val ? 'Y' : 'N' }
                                    }));
                                }}
                            >
                                <DrcTranslate>{'Exclude Average Pricing'}</DrcTranslate>
                            </DrcSwitch> */}
                            <FormControlLabel
                                control={
                                    <DrcCheckbox
                                        checked={this.state.newRecord['ExcludeNormalSalesAmt']}
                                        value={this.state.newRecord['ExcludeNormalSalesAmt']}
                                        onChange={(e, val) => {
                                            this.setState((prevState) => ({
                                                newRecord: { ...prevState.newRecord, ExcludeNormalSalesAmt: val }
                                            }));
                                        }}
                                    />
                                }
                                label={<DrcTranslate>{'Exclude Normal Sales Amt'}</DrcTranslate>}
                            />
                            <FormControlLabel
                                control={
                                    <DrcCheckbox
                                        checked={this.state.newRecord['ExcludeNormalSalesQty']}
                                        value={this.state.newRecord['ExcludeNormalSalesQty']}
                                        onChange={(e, val) => {
                                            this.setState((prevState) => ({
                                                newRecord: { ...prevState.newRecord, ExcludeNormalSalesQty: val }
                                            }));
                                        }}
                                    />
                                }
                                label={<DrcTranslate>{'Exclude Normal Sales Qty'}</DrcTranslate>}
                            />
                            <FormControlLabel
                                control={
                                    <DrcCheckbox
                                        checked={this.state.newRecord['ExcludeSettlementPriceAmt']}
                                        value={this.state.newRecord['ExcludeSettlementPriceAmt']}
                                        onChange={(e, val) => {
                                            this.setState((prevState) => ({
                                                newRecord: { ...prevState.newRecord, ExcludeSettlementPriceAmt: val }
                                            }));
                                        }}
                                    />
                                }
                                label={<DrcTranslate>{'Exclude Settlement Price Amt'}</DrcTranslate>}
                            />
                            <FormControlLabel
                                control={
                                    <DrcCheckbox
                                        checked={this.state.newRecord['ExcludeSettlementPriceQty']}
                                        value={this.state.newRecord['ExcludeSettlementPriceQty']}
                                        onChange={(e, val) => {
                                            this.setState((prevState) => ({
                                                newRecord: { ...prevState.newRecord, ExcludeSettlementPriceQty: val }
                                            }));
                                        }}
                                    />
                                }
                                label={<DrcTranslate>{'Exclude Settlement Price Qty'}</DrcTranslate>}
                            />
                        </Grid>
                    </Form>
                </DrcDialog>
                <DrcDialog
                    title="Confirm Delete"
                    open={this.state.showDialog}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showDialog: false });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.deleteHandler}>
                                <DrcTranslate>{'Delete'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                ></DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    customerCodeCategoryLookUp: masterReducer[LOOKUP_FIELDS.CustomerCodeCategoryLookUp],
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(CustomerCodeCategory))));
