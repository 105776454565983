import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TablePagination from '@material-ui/core/TablePagination';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import ExpandedRowDetail from './ExpandedRowDetail';
import Checkbox from '@material-ui/core/Checkbox';
import { designColors, getAccordionColor } from '../data/constants';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto'
        // padding: '0 0 0 24px'
    },
    table: {
        minWidth: 650
    },
    tableRoot: {
        '& .MuiTableCell-head': {
            backgroundColor: `${designColors.grey}`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.palette.grey['600']} !important`
            }
        }
    },
    container: {
        // maxHeight: 440
    },
    tbody: {
        '& tr:nth-child(odd)': {
            backgroundColor: `${theme.light.grid.odd} !important`,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.odd} !important`
            }
        },
        '& tr:nth-child(even)': {
            backgroundColor: `${theme.light.grid.even} `,
            [theme.darkTheme]: {
                backgroundColor: `${theme.dark.grid.even} `
            }
        }
    }
}));

const ExpandableTableRow = ({ children, expandComponent, isRowExpandable, onRowClick, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
        <>
            <TableRow
                {...otherProps}
                onClick={() => {
                    isRowExpandable && setIsExpanded(!isExpanded);
                    onRowClick();
                }}
            >
                {children}
            </TableRow>
            {isExpanded && <TableRow>{expandComponent}</TableRow>}
        </>
    );
};

export default function DataTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { isRowExpandable, isSelectable, isPaginated, onRowClick = () => null } = props;
    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                        <TableRow className={classes.tableRoot}>
                            {(props.columns || []).map((col, indx) => (
                                <TableCell key={indx}>{col.name}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tbody}>
                        {(props.data || []).map((row, indx) => (
                            <ExpandableTableRow
                                key={indx}
                                isRowExpandable={isRowExpandable || false}
                                onRowClick={() => onRowClick(row)}
                                expandComponent={
                                    <TableCell colSpan="5" style={{ backgroundColor: `${designColors.grey}` }}>
                                        <ExpandedRowDetail data={row} />
                                    </TableCell>
                                }
                            >
                                {(props.columns || []).map((col, index) =>
                                    index === 0 && isRowExpandable ? (
                                        <TableCell component="th" scope="row" style={{ padding: '16px 0' }}>
                                            <Box
                                                component="span"
                                                mr={1}
                                                style={{
                                                    padding: '8px 0',
                                                    border: `4px solid ${getAccordionColor(theme, row.Status)}`,
                                                    borderRadius: 8,
                                                    backgroundColor: `${getAccordionColor(theme, row.Status)}`
                                                }}
                                            ></Box>

                                            {row[col['key']]}
                                        </TableCell>
                                    ) : index === 0 && isSelectable ? (
                                        <TableCell className="selectCheckbox" padding="checkbox">
                                            <Checkbox
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    console.log(event);
                                                }}
                                                className="selectCheckbox"
                                                checked={false}
                                            />
                                        </TableCell>
                                    ) : (
                                        <TableCell> {row[col['key']]}</TableCell>
                                    )
                                )}
                            </ExpandableTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {isPaginated && (
                <TablePagination
                    rowsPerPageOptions={props.rowsPerPageOptions || [10, 25, 50]}
                    component="div"
                    count={props.total}
                    rowsPerPage={props.rowsPerPage || 5}
                    page={props.page}
                    onPageChange={props.handleChangePage}
                    onRowsPerPageChange={props.handleChangeRowsPerPage}
                />
            )}
        </Paper>
    );
}
