import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcButton, DrcDialog, DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeColumns, makeOptions, userBusinessUnit, getAppliedFilters, displayErrorMessage } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { decimal } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { currencyOptions, itemShippingRatesDsColumns, LOOKUP_FIELDS } from '../../../data/constants';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';

const pageTitle = 'Item Shipping Rates';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    selectOverride: {
        width: '100%',
        display: 'inline-block'
    }
});

class ItemShippingRates extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        showAddNew: false,
        newRecord: {},
        itemOptions: [],
        invalidFields: [],
        editingRow: {}
    };
    excludingFields = ['Actions', 'ItemShipRateID'];
    readOnly = false;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    validationFields = {
        FreightRate: {
            key: 'FreightRate',
            validations: [decimal],
            customProps: {}
        },
        PackageRate: {
            key: 'PackageRate',
            validations: [decimal],
            customProps: {}
        }
    };

    componentDidMount() {
        this.loadData();
        this.getItemList();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = async (_event, data, _index) => {
        this.setState({
            editingRow: {
                ...data,
                PackageRate: data.getPreviousData().PackageRate + '',
                FreightRate: data.getPreviousData().FreightRate + '',
                [data[this.uniqueKeyField]]: true
            }
        });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    deleteData = async (_rowData) => null;

    getItemList = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_ITEM_LIST(userBusinessUnit()),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            const itemOptions = makeOptions(data.raw.data.Data, 'ItemID', 'ItemID');
            this.setState((prevState) => ({ ...prevState, itemOptions }));
        } catch (error) {
            this.showError(error);
        }
    };

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'ItemShippingRates',
                    url: APIEndPoints.PUT_ITEM_SHIPPING_RATES(),
                    method: 'PUT',
                    options: {},
                    data: {
                        ItemID: this.state.editingRow[this.uniqueKeyField],
                        FreightRate: '' + this.state.editingRow['FreightRate'],
                        Commodity: this.state.editingRow['Commodity'],
                        ItemShipRateID: this.state.editingRow['ItemShipRateID'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        PackageUOM: this.state.editingRow['PackageUOM'],
                        FreightUOM: this.state.editingRow['FreightUOM'],
                        Currency: this.state.editingRow['Currency'],
                        PackageRate: '' + this.state.editingRow['PackageRate'],
                        ModifiedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError(error);
        }
    };

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'ItemShippingRates',
                    url: APIEndPoints.PUT_ITEM_SHIPPING_RATES(),
                    method: 'POST',
                    options: {},
                    data: {
                        ItemID: this.state.newRecord['ItemID'].value,
                        Commodity: this.state.newRecord['Commodity'],
                        Currency: this.state.newRecord['Currency'].value,
                        PackageUOM: this.state.newRecord['PackageUOM'].value,
                        PackageRate: this.state.newRecord['PackageRate'],
                        FreightUOM: this.state.newRecord['FreightUOM'].value,
                        FreightRate: this.state.newRecord['FreightRate'],
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate Record');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    cannotDelete = (_row) => null;

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['PackageUOM', 'PackageRate', 'FreightUOM', 'FreightRate'],
                  ['ItemID'],
                  itemShippingRatesDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ItemShippingRates',
                    url: APIEndPoints.GET_ITEM_SHIPPING_RATES(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    getRowEditor = (key, col, others) => {
        var field = this.validationFields[key] ?? {};
        switch (key) {
            case 'PackageUOM':
            case 'FreightUOM':
                return this.selectCellEditor(key, col, others);
            case 'PackageRate':
            case 'FreightRate':
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
        }
    };

    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) => {
                        return this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }));
                    }}
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };

    selectCellEditor = (key, _col, _others) => {
        return (
            <DrcSelect
                options={makeOptions(this.props.uomLookUp, 'LookupCode', 'LookupCode')}
                label="ItemShipping"
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={this.props.uomLookUp.find((itm) => itm.label === this.state.editingRow[key])}
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };
    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['Commodity', 'Currency', 'PackageUOM', 'FreightUOM', 'ItemID', 'SettlementCurrency']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                {!!this.state.columns.length && (
                    <div className={classes.actionContainer}>
                        <DrcButton
                            onClick={(_event) => {
                                this.setState({ showAddNew: true });
                            }}
                            noStyle
                            title="Add"
                            disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                        >
                            <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                            <DrcTranslate>{'Add'}</DrcTranslate>
                        </DrcButton>
                    </div>
                )}
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'170px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(key, col, others);
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord.ItemID &&
                                        this.state.newRecord.Currency &&
                                        this.state.newRecord.PackageUOM &&
                                        this.state.newRecord.PackageRate &&
                                        this.state.newRecord.FreightUOM &&
                                        this.state.newRecord.FreightRate
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DrcSelect
                                label="Item ID"
                                required
                                onChange={(option) =>
                                    this.setState((prevState) => ({
                                        newRecord: { ...prevState.newRecord, ItemID: option, Commodity: option.Commodity }
                                    }))
                                }
                                value={this.state.newRecord['ItemID']}
                                className={classes.selectOverride}
                                options={this.state.itemOptions}
                            />
                            <DrcSelect
                                label="Currency"
                                required
                                onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Currency: option } }))}
                                value={this.state.newRecord['Currency']}
                                className={classes.selectOverride}
                                options={currencyOptions}
                            />
                            <DrcSelect
                                label="PackageUOM"
                                required
                                onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, PackageUOM: option } }))}
                                value={this.state.newRecord['PackageUOM']}
                                className={classes.selectOverride}
                                options={makeOptions(this.props.uomLookUp, 'LookupCode', 'LookupCode')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Package Rate'}
                                required
                                validations={this.validationFields['PackageRate'] ? this.validationFields['PackageRate'].validations : []}
                                {...(this.validationFields['PackageRate'] ? this.validationFields['PackageRate'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, PackageRate: e.target.value } }))
                                }
                                value={this.state.newRecord['PackageRate']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'PackageRate')}
                            />
                            <DrcSelect
                                label="FreightUOM"
                                required
                                onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, FreightUOM: option } }))}
                                value={this.state.newRecord['FreightUOM']}
                                className={this.props.classes.input}
                                options={makeOptions(this.props.uomLookUp, 'LookupCode', 'LookupCode')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Freight Rate'}
                                required
                                validations={this.validationFields['FreightRate'] ? this.validationFields['FreightRate'].validations : []}
                                {...(this.validationFields['FreightRate'] ? this.validationFields['FreightRate'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, FreightRate: e.target.value } }))
                                }
                                value={this.state.newRecord['FreightRate']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'FreightRate')}
                            />
                        </Grid>
                    </Form>
                </DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    uomLookUp: masterReducer[LOOKUP_FIELDS.UOMLookUp],
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(ItemShippingRates))));
