import {
    SET_MASTER_DATA_REDIRECT_URL,
    SET_MASTER_DATA_INITIALIZED,
    SET_CURRENT_POOL_WEEk,
    SET_CURRENT_YEAR_POOL_WEEk,
    SET_LOOKUP_VALUES,
    SET_USER_DETAIL,
    SET_USER_LANG,
    SET_USER_PREFERENCE
} from '../actions/MasterActions';
import { LOOKUP_FIELDS } from '../data/constants';

const REDIRECT_URL_KEY = 'REDIRECT_URL';
const DEFAULT_REDIRECT_URL = '/SettlementWorkbench/';
const REDIRECT_URL = sessionStorage.getItem(REDIRECT_URL_KEY) || DEFAULT_REDIRECT_URL;

const initialState = {
    isInitialized: false,
    redirectUrl: REDIRECT_URL,
    userLang: sessionStorage.getItem('GGS_UserLang') || 'en-US',
    userOktaProfile: {},
    currentPoolWeek: [],
    yearPoolWeeks: [],
    [LOOKUP_FIELDS.LocationLookUp]: [],
    [LOOKUP_FIELDS.CommodityLookup]: [],
    [LOOKUP_FIELDS.CustomerCodeCategoryLookUp]: [],
    [LOOKUP_FIELDS.UOMLookUp]: [],
    [LOOKUP_FIELDS.GrowerCategoryLookUp]: [],
    [LOOKUP_FIELDS.ApplicationLookUp]: [],
    [LOOKUP_FIELDS.EstimateGroupLookUp]: [],
    [LOOKUP_FIELDS.CoolerNameLookUp]: [],
    [LOOKUP_FIELDS.FamilyEntityLookUp]: [],
    [LOOKUP_FIELDS.CommodityOffsetWeekLookUp]: [],
    [LOOKUP_FIELDS.GrowerFixedPriceLookUp]: [],
    [LOOKUP_FIELDS.AdjustmentCategory]: [],
    [LOOKUP_FIELDS.GrowerModule]: []
};

const masterReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MASTER_DATA_REDIRECT_URL:
            if (action.payload === '/' || action.payload === '/implicit/callback' || action.payload === '/InitializeApplication/') {
                return state;
            }

            sessionStorage.setItem(REDIRECT_URL_KEY, action.payload);

            return Object.assign({}, state, {
                redirectUrl: action.payload
            });
        case SET_MASTER_DATA_INITIALIZED:
            return Object.assign({}, state, {
                isInitialized: action.payload
            });

        case SET_CURRENT_POOL_WEEk:
            return Object.assign({}, state, {
                currentPoolWeek: action.payload
            });
        case SET_CURRENT_YEAR_POOL_WEEk:
            return Object.assign({}, state, {
                // yearPoolWeeks: action.payload
                yearPoolWeeks: [...new Map((action.payload || []).map((item) => [item['PoolWeek'], item])).values()].sort(
                    (a, b) => b.PoolWeek - a.PoolWeek
                )
            });
        case SET_LOOKUP_VALUES:
            return Object.assign({}, state, {
                [action.payload.lookup]: action.payload.value
            });
        case SET_USER_DETAIL:
            return Object.assign({}, state, {
                userLang: action.payload.lang || 'en-US',
                userOktaProfile: action.payload.profile || {}
            });
        case SET_USER_LANG:
            return Object.assign({}, state, {
                userLang: action.payload || 'en-US'
            });
        case SET_USER_PREFERENCE:
            return Object.assign({}, state, {
                userPreference: action.payload
            });
        default:
            return state;
    }
};

export default masterReducer;
