import React from 'react';
import { Divider } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcDialog, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, makeColumns, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Grid from '@material-ui/core/Grid';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { decimal, maxValue } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { ImportSettelementRateColumns } from '../../../data/constants';

const pageTitle = 'Import Settlement Rate';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class ImportSettlementRate extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        showDialog: false,
        deleteId: 0,
        invalidFields: []
    };
    validationFields = {
        Variety: {
            key: 'Variety',
            validations: [],
            customProps: {}
        },
        CCPercent: {
            key: 'CCPercent',
            validations: [decimal, maxValue],
            customProps: { maxvalue: 100 }
        },
        DSAServiceFee: {
            key: 'DSAServiceFee',
            validations: [decimal],
            customProps: {}
        },
        Module: {
            key: 'Module',
            validations: [],
            customProps: {}
        },
        Breeder: {
            key: 'Breeder',
            validations: [],
            customProps: {}
        }
    };
    excludingFields = ['Actions', 'ImportSettlementRateID'];
    readOnly = false;
    uniqueKeyField = 'ImportSettlementRateID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);

        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true } });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) =>  !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    deleteData = (rowData) => {
        this.setState({ showDialog: true, deleteId: rowData.ImportSettlementRateID });
    };
    deleteHandler = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ImportSettlementRate',
                    url: APIEndPoints.PUT_IMPORT_SETTLEMENT_RATE(),
                    method: 'DELETE',
                    options: {},
                    data: { ID: this.state.deleteId }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Deleted Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError();
        }
        this.setState({ showDialog: false });
    };

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'ImportSettlementRate',
                    url: APIEndPoints.PUT_IMPORT_SETTLEMENT_RATE(),
                    method: 'PUT',
                    options: {},
                    data: {
                        ImportSettlementRateID: this.state.editingRow['ImportSettlementRateID'],
                        BusinessUnit: userBusinessUnit(),
                        Variety: this.state.editingRow['Variety'],
                        CCPercent: this.state.editingRow['CCPercent'],
                        DSAServiceFee: this.state.editingRow['DSAServiceFee'],
                        ModifiedDateTime: new Date().toISOString(),
                        Module: this.state.editingRow['Module'],
                        Breeder: this.state.editingRow['Breeder'],
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if (!data) {
                this.setState({ editingRow: {} });
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) =>  !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'ImportSettlementRate',
                    url: APIEndPoints.PUT_IMPORT_SETTLEMENT_RATE(),
                    method: 'POST',
                    options: {},
                    data: {
                        BusinessUnit: userBusinessUnit(),
                        Variety: this.state.newRecord['Variety'],
                        CreatedBy: loggedInUser,
                        CCPercent: this.state.newRecord['CCPercent'],
                        DSAServiceFee: this.state.newRecord['DSAServiceFee'],
                        ModifiedDateTime: new Date().toISOString(),
                        Module: this.state.newRecord['Module'],
                        Breeder: this.state.newRecord['Breeder'],
                        ModifiedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate Record');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                isDeletable
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['Variety', 'CCPercent', 'DSAServiceFee', 'Module', 'Breeder'],
                  ['Actions'],
                  ImportSettelementRateColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ImportSettlementRate',
                    url: APIEndPoints.GET_IMPORT_SETTLEMENT_RATE(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit()
                    ),
                    method: 'GET',
                    options: {}
                    // fakeData: ImportSettlementRateJson
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getRowEditor = (key, col, others) => {
        switch (key) {
            case 'Variety':
            case 'CCPercent':
            case 'DSAServiceFee':
            case 'Module':
            case 'Breeder':
                var field = this.validationFields[key];
                return this.inputTextEditor(key, col, others, field.validations, field.customProps);
        }
    };

    inputTextEditor = (key, _col, _others, validations = [], customProps = {}) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) => {
                        return this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }));
                    }}
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };
    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}></div>
                <div className={classes.pageActions}>
                    <div></div>
                    {!!this.state.columns.length && (
                        <div className={classes.actionContainer}>
                            <DrcButton
                                onClick={(_event) => {
                                    this.setState({ showAddNew: true });
                                }}
                                noStyle
                                title="Add"
                                disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                            >
                                <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                                <DrcTranslate>{'Add'}</DrcTranslate>
                            </DrcButton>
                        </div>
                    )}
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'100px'}
                        rowSelect={false}
                        selectionMode="multiple"
                        tableSelectionMode="checkbox"
                        selected={this.state.selectedRow}
                        selectedFrozen={true}
                        selectedClass={classes.selectedClass}
                        selectionChange={(e) => {
                            this.setState({ selectedRow: e.value });
                        }}
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(key, col, others);
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord['Variety'] &&
                                        this.state.newRecord['CCPercent'] &&
                                        this.state.newRecord['DSAServiceFee'] &&
                                        this.state.newRecord['Module'] &&
                                        this.state.newRecord['Breeder']
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <GGSFormInput
                                label={'Variety'}
                                required
                                validations={this.validationFields['Variety'] ? this.validationFields['Variety'].validations : []}
                                {...(this.validationFields['Variety'] ? this.validationFields['Variety'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Variety: e.target.value } }))}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'Variety')}
                            />
                            <GGSFormInput
                                label={'CC Percent'}
                                required
                                validations={this.validationFields['CCPercent'] ? this.validationFields['CCPercent'].validations : []}
                                {...(this.validationFields['CCPercent'] ? this.validationFields['CCPercent'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, CCPercent: e.target.value } }))}
                                value={this.state.newRecord['CCPercent']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'CCPercent')}
                            />
                            <GGSFormInput
                                label={'DSA Service Fee'}
                                required
                                validations={this.validationFields['DSAServiceFee'] ? this.validationFields['DSAServiceFee'].validations : []}
                                {...(this.validationFields['DSAServiceFee'] ? this.validationFields['DSAServiceFee'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, DSAServiceFee: e.target.value } }))
                                }
                                value={this.state.newRecord['DSAServiceFee']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'DSAServiceFee')}
                            />
                            <GGSFormInput
                                label={'Module'}
                                required
                                validations={this.validationFields['Module'] ? this.validationFields['Module'].validations : []}
                                {...(this.validationFields['Module'] ? this.validationFields['Module'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Module: e.target.value } }))}
                                value={this.state.newRecord['Module']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'Module')}
                            />
                            <GGSFormInput
                                label={'Breeder'}
                                required
                                validations={this.validationFields['Breeder'] ? this.validationFields['Breeder'].validations : []}
                                {...(this.validationFields['Breeder'] ? this.validationFields['Breeder'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Breeder: e.target.value } }))}
                                value={this.state.newRecord['Breeder']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'Breeder')}
                            />
                        </Grid>
                    </Form>
                </DrcDialog>
                <DrcDialog
                    title="Confirm Delete"
                    open={this.state.showDialog}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showDialog: false });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton isPrimary onClick={this.deleteHandler}>
                                <DrcTranslate>{'Delete'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                ></DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(ImportSettlementRate)));
