import React from 'react';
import PrimeReactTable from '../../components/PrimeReactTable';
import { withStyles } from '@material-ui/core/styles';
import { DrcInput } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../styles/gridStyles';
import { middlewareConfig } from '../../data/constants';
import { Middleware } from '@driscollsinc/one-ring';
import { showLoadingScreenAction, hideLoadingScreenAction } from '../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeColumns } from '../../utils/helper';
import ActionsFormatter from '../../components/cellFormatters/ActionFormatter';

const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    }
});

class CommoditiesTable extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false
    };
    excludingFields = ['Actions'];
    readOnly = false;
    uniqueKeyField = 'CommodityId';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true } });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) => null;
    deleteData = async (_rowData) => null;
    confirmEdit = async (_event, _rowData) => null;
    cannotDelete = (_row) => null;

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={false}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response.Results || [];
        let columns = data.length ? makeColumns(Object.keys(data[0]), [], this.getActionsCell, false, [], []) : [];
        this.setState({
            data,
            totalCount: response.totalCount,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            Middleware.Send('CommoditiesTable', token, '', 'GET', {}, { ...middlewareConfig(true, true), allowEmptyValues: true })
                .then((resp) => {
                    this.response = resp;
                    this.updateDataTable(null, true);
                    this.props.hideLoadingScreenAction();
                })
                .catch((error) => {
                    this.props.hideLoadingScreenAction();
                    console.log({ error });
                });
        } catch (error) {
            this.props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    inputTextEditor = (key, col, others) => {
        return <DrcInput className={this.props.classes.input} onChange={(e) => console.log(key, col, e.target.value)} value={others.rowData[key]} />;
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    render() {
        const { classes } = this.props;
        return (
            <PrimeReactTable
                value={this.state.data}
                columns={this.state.columns}
                frozenWidth={'140px'}
                editMode="row"
                rowSelect={true}
                selectionMode="multiple"
                tableSelectionMode="checkbox"
                selected={this.state.selectedRow}
                selectedFrozen={true}
                selectedClass={classes.selectedClass}
                selectionChange={(e) => {
                    console.log('e: ', e, e.value[e.value.length - 1]);
                    this.setState({ selectedRow: e.value });
                    this.props.addChipsCommodity(e.value);
                }}
                uniqueKeyField={this.uniqueKeyField}
                editingRows={this.state.editingRow}
                paginator={this.state.data.length > 0}
                currentPage={this.state.pageNum}
                pageSize={this.state.pageSize}
                onPage={this.onPage}
                totalRecords={this.state.totalCount}
                editor={(key, col, others) => {
                    return this.inputTextEditor(key, col, others);
                }}
            />
        );
    }
}

export default connect(null, { showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(withStyles(styles)(CommoditiesTable)));
