import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import Edit from '@material-ui/icons/Edit';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import { designColors } from '../../data/constants';
const styles = (theme) => ({
    editButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: theme.palette.primary.main,
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: `${designColors.hover}`
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: `${designColors.hover}`,
                backgroundColor: '#0000'
            }
        }
    },
    disabledEditButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#ccc!important',
        border: 'none!important',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: `${designColors.hover}`
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            color: '#5e5e5e!important',
            '&:hover': {
                border: 'none',
                color: '#777!important',
                backgroundColor: '#0000'
            }
        }
    },
    deleteButton: {
        minWidth: '25px',
        maxWidth: '25px',
        margin: '4px 8px 8px 0',
        color: '#e34843',
        border: 'none',
        '&:hover': {
            border: 'none',
            backgroundColor: '#ffffff00',
            color: '#ec110a'
        },
        [theme.darkTheme]: {
            backgroundColor: '#0000',
            '&:hover': {
                border: 'none',
                color: '#ec110a',
                backgroundColor: '#0000'
            }
        }
    }
});

class ActionFormatter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rowUnderEditUniqueKey: null
        };
        this.originalRows = [];
    }

    getIndex = (key, value) => {
        return this.props.records.findIndex((item) => {
            return item[key] === value;
        });
    };

    editAttribute = (event, data, rowUnderEditUniqueKeyValue) => {
        if (!this.props.rowEdit) {
            this.props.initRowEdit(event, data, rowUnderEditUniqueKeyValue);
            return;
        }
        this.props.objState.setState({ rowUnderEditUniqueKey: rowUnderEditUniqueKeyValue }, () => {
            let index = this.getIndex(this.props.uniqueKeyField, rowUnderEditUniqueKeyValue);
            this.originalRows[index] = { ...this.props.records[index] };
            this.props.initRowEdit(event, data, index);
        });
    };

    confirmEdit = (event, data, rowUnderEditUniqueKeyValue) => {
        this.props.objState.setState({ rowUnderEditUniqueKey: -1 }, () => {
            this.props.confirmEdit(event, data, rowUnderEditUniqueKeyValue);
        });
    };

    cancelEdit = (event, data, rowUnderEditUniqueKeyValue) => {
        let index = this.getIndex(this.props.uniqueKeyField, rowUnderEditUniqueKeyValue);
        if (!data.new) {
            this.props.records[index] = this.originalRows[index];
            delete this.originalRows[index];
        }
        this.props.objState.setState({ rowUnderEditUniqueKey: -1 }, () => {
            this.props.cancelEdit(event, data, index);
        });
    };

    render() {
        const { classes, rowUnderEditUniqueKeyValue, row, isDeletable } = this.props;

        const cannotEdit = this.props.cannotEdit(row);
        const cannotDelete = this.props.cannotDelete(row);

        return this.props.objState.state.rowUnderEditUniqueKey !== -1 &&
            (this.props.objState.state.rowUnderEditUniqueKey === rowUnderEditUniqueKeyValue || row.new) ? (
                <div>
                    <DrcButton
                        size="small"
                        onClick={(event) => this.confirmEdit(event, row, rowUnderEditUniqueKeyValue)}
                        isSecondary
                        noStyle
                        className={classes.editButton}
                        title="Accept"
                        disabled={this.props.checkRowValidation ? this.props.checkRowValidation() : false}
                    >
                        <CheckIcon />
                    </DrcButton>
                    <DrcButton
                        size="small"
                        onClick={(event) => this.cancelEdit(event, row, rowUnderEditUniqueKeyValue)}
                        isSecondary
                        noStyle
                        className={classes.deleteButton}
                        title="Cancel"
                    >
                        <ClearIcon />
                    </DrcButton>
                </div>
            ) : (
                <div>
                    {isDeletable && (
                        <DrcButton
                            size="small"
                            isSecondary
                            noStyle
                            className={cannotDelete ? classes.disabledEditButton : classes.deleteButton}
                            disabled={cannotDelete}
                            onClick={() => this.props.deleteAttribute(row)}
                            title="Delete"
                        >
                            <DeleteIcon />
                        </DrcButton>
                    )}
                    <DrcButton
                        size="small"
                        isSecondary
                        noStyle
                        className={cannotEdit ? classes.disabledEditButton : classes.editButton}
                        onClick={(event) => this.editAttribute(event, row, rowUnderEditUniqueKeyValue)}
                        title="Edit"
                        disabled={cannotEdit}
                    >
                        <Edit />
                    </DrcButton>
                    {/* <DrcButton
                        size="small"
                        isSecondary
                        noStyle
                        className={cannotEdit ? classes.disabledEditButton : classes.editButton}
                        onClick={(event) => this.props.showGraphVew(event, row, rowUnderEditUniqueKeyValue)}
                        title="Graph"
                        disabled={cannotEdit}
                    >
                        <ShowChartIcon />
                    </DrcButton> */}
                </div>
            );
    }
}

export default withStyles(styles)(ActionFormatter);
