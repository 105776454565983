import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import Header from './components/Header';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureGroupRoute,
    DrcPageNotFound,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcMain,
    DrcPanel,
    DrcMediaQueries,
    Helmet,
    DrcVersionInfo,
    DrcImplicitCallback,
    DrcLoading,
    DrcBackdrop,
    DrcTranslate,
    DrcPageLogin
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import {
    showReportBackLinkAction,
    hideCommonDialogAction,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    addInfo,
    setCommonDialogAction,
    setPageTitleAction,
    showToast,
    serviceWorkerUpdate
} from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized, setUserLang } from './actions/MasterActions';
import MasterDataUtilities from './data/MasterDataUtilities';
import LoggingUtilities from './data/LoggingUtilities';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import APP_TRANSLATIONS from './data/appTranslations';
import BackgroundPng from './Images/Backgrounds/background-blacks.png';
import BackgroundWebP from './Images/Backgrounds/background-blacks.webp';
import WeeklySettlementStatus from './pages/WeeklySettlementStatus';
import PricingPool from './pages/PricingPool';
import SettlementWorkbenchDetails from './pages/SettlementWorkbench/SettlementWorkbenchDetails';
import PoolWeek from './pages/DataSetup/PoolWeek/PoolWeek';
import ItemUomConversion from './pages/DataSetup/ItemUomConversion/ItemUomConversion';
import ItemShippingRates from './pages/DataSetup/ItemShippingRates';
import RepackagingSkuMapping from './pages/DataSetup/RepackagingSkuMapping/RepackagingSkuMapping';
import GrowerCategory from './pages/DataSetup/GrowerCategory/GrowerCategory';
import SettlementRate from './pages/DataSetup/SettlementRate/SettlementRate';
import LookUp from './pages/DataSetup/LookUp/LookUp';
import CustomerCodeCategory from './pages/DataSetup/CustomerCodeCategory/CustomerCodeCategory';
import { designColors } from './data/constants';
import { createHashHistory } from 'history';
import VatRateMaintenance from './pages/DataSetup/VatRateMaintenance/VatRateMaintenance';
import ConversionRate from './pages/DataSetup/ConversionRate/ConversionRate';
import Reports from './pages/Reports/Reports';
import { languagesSupportedMap } from './data/translations.js';
import { ReactComponent as SW } from './Images/SVG/SW_White.svg';
import { ReactComponent as SWSelected } from './Images/SVG/SW_Blue.svg';
import { ReactComponent as RE } from './Images/SVG/RE_White.svg';
import { ReactComponent as RESelected } from './Images/SVG/RE_Blue.svg';
import { ReactComponent as PP } from './Images/SVG/PP_White.svg';
import { ReactComponent as PPSelected } from './Images/SVG/PP_Blue.svg';
import { ReactComponent as DS } from './Images/SVG/DS_White.svg';
import { ReactComponent as DSSelected } from './Images/SVG/DS_Blue.svg';
import UserPreference from './pages/UserPreference';
import GrowerFixedPrice from './pages/DataSetup/GrowerFixedPrice/GrowerFixedPrice';
import GrowerPercentage from './pages/DataSetup/GrowerPercentage/GrowerPercentage';
import ImportSettlementRate from './pages/DataSetup/ImportSettlementRate/ImportSettelementRate';
import JournalAccount from './pages/DataSetup/JournalAccount/JournalAccount';
import { userBusinessUnit } from './utils/helper';
const Releases = lazy(() => import('./pages/Releases'));

const allGroups = [...window.config.OKTA_GGS_ADMIN_GROUPS, ...window.config.OKTA_USER_GROUPS, ...window.config.OKTA_ADMIN_GROUPS];
const SITE_NAME = 'GGS';

var styles = (theme) => ({
    '@global': {
        '::-webkit-scrollbar': {
            /* width */ width: '10px',
            height: '10px'
        },
        '::-webkit-scrollbar-track': {
            /* Track */ boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },
        '::-webkit-scrollbar-thumb': {
            /* Handle */ backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        },
        '.MuiTypography-root': {
            fontFamily: 'Rubik'
        },
        '.MuiTableCell-root': {
            fontFamily: 'Rubik'
        }
    },
    logoLink: {
        left: 'calc(50vw - 70px)',
        position: 'absolute'
    },
    logo: {
        height: 40,
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    logoShowAlways: {
        height: 40
    },
    header: {
        background: 'transparent',
        // backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), 97),
        backgroundColor: DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(designColors.blue), 97),
        '& .MuiToolbar-root': {
            padding: '0px 12px'
        },
        '& .titleContainer h1': {
            display: 'contents'
        },
        '& .title': {
            textShadow: 'none'
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: 'hsla(341, 57%, 15%, 1)',
            '& .title': {
                color: 'hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        },
        '@media print': {
            display: 'none'
        }
    },
    loaderWithMessage: {
        width: '644px',
        left: 'calc(50vw - 322px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#ddd'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    drawerIcon: {
        width: 20,
        height: 20
    },
    legendClassName: {
        fontFamily: 'Rubik'
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createHashHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            // theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
            theme: DrcThemeUtilities.CreateSimpleTheme(designColors.blue)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: languagesSupportedMap,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...APP_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/SettlementWorkbench/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hideCommonDialog();
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    updateServiceWorker = () => {
        const registrationWaiting = this.props.serviceWorkerRegistration.waiting;

        if (registrationWaiting) {
            registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

            registrationWaiting.addEventListener('statechange', (e) => {
                if (e.target.state === 'activated') {
                    this.props.serviceWorkerUpdate();
                    window.location.href = '/logOut';
                }
            });
        }
    };
    componentDidUpdate(prevProps) {
        const savedUserLang = sessionStorage.getItem('GGS_UserLang') || 'en-US';
        if (this.props.userLang !== savedUserLang) {
            this.props.setUserLang(savedUserLang);
        } else if (prevProps.userLang !== this.props.userLang) {
            this.props.setActiveLanguage(this.props.userLang);
        } else if (savedUserLang !== this.props.activeLanguage.code) {
            this.props.setActiveLanguage(savedUserLang);
        }
    }
    closeNewVersionDialog = () => {
        this.props.serviceWorkerUpdate();
    };

    render() {
        var { classes } = this.props;
        const isDocUser = userBusinessUnit() === 'DOC';
        const menuItems = [
            // { key: 'WSS', name: 'Weekly Settlement Status', route: '/WeeklySettlementStatus/' },
            {
                icon: <SW className={classes.drawerIcon} />,
                selectedIcon: <SWSelected className={classes.drawerIcon} />,
                name: <DrcTranslate>Settlement Workbench</DrcTranslate>,
                route: '/SettlementWorkbench/',
                index: 0
            },
            {
                icon: <PP className={classes.drawerIcon} />,
                selectedIcon: <PPSelected className={classes.drawerIcon} />,
                name: <DrcTranslate>Pricing Pool</DrcTranslate>,
                route: '/PricingPool/',
                index: 1
            },
            // { key: 'SP', name: <DrcTranslate>Settlement Pool</DrcTranslate>, route: '/SettlementPool/' },
            {
                icon: <DS className={classes.drawerIcon} />,
                selectedIcon: <DSSelected className={classes.drawerIcon} />,
                name: <DrcTranslate>Data Setup</DrcTranslate>,
                items: [
                    { name: <DrcTranslate>Pool Week</DrcTranslate>, route: '/PoolWeek' },
                    { name: <DrcTranslate>Item UOM Conversion</DrcTranslate>, route: '/ItemUomConversion' },
                    { name: <DrcTranslate>Item Shipping Rates</DrcTranslate>, route: '/ItemShippingRates' },
                    { name: <DrcTranslate>Repackaging SKU Mapping</DrcTranslate>, route: '/RepackagingSkuMapping' },
                    { name: <DrcTranslate>Grower Category</DrcTranslate>, route: '/GrowerCategory' },
                    { name: <DrcTranslate>Grower Fixed Price</DrcTranslate>, route: '/GrowerFixedPrice' },
                    { name: <DrcTranslate>Settlement Rate</DrcTranslate>, route: '/SettlementRate' },
                    { name: <DrcTranslate>Customer Code Category</DrcTranslate>, route: '/CustomerCodeCategory' },
                    { name: <DrcTranslate>Grower Percentage</DrcTranslate>, route: '/GrowerPercentage' },
                    { name: <DrcTranslate>VAT Rate Maintenance</DrcTranslate>, route: '/VatRateMaintenance' },
                    { name: <DrcTranslate>Lookup</DrcTranslate>, route: '/LookUp' },
                    { name: <DrcTranslate>Forex Rate</DrcTranslate>, route: '/ConversionRate' },
                    { name: <DrcTranslate>Import Settlement Rate</DrcTranslate>, route: '/ImportSettlementRate' },
                    { name: <DrcTranslate>Journal Account</DrcTranslate>, route: '/JournalAccount' }
                ],
                index: 2
            },
            {
                icon: <RE className={classes.drawerIcon} />,
                selectedIcon: <RESelected className={classes.drawerIcon} />,
                name: <DrcTranslate>Reports</DrcTranslate>,
                route: '/Reports/',
                index: 3
            }
        ];
        if (isDocUser) {
            menuItems.splice(1, 1);
        }
        return (
            <DrcThemeProvider theme={this.state.theme} fontFamily="Rubik">
                <DrcEnvironmentMarker />
                <Helmet>
                    <title>{this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <Header allLinks={menuItems} />
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <DrcLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <DrcPageLogin
                                            {...props}
                                            poly
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                        />
                                    )}
                                />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/WeeklySettlementStatus/"
                                    exact
                                    component={WeeklySettlementStatus}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/PricingPool/" exact component={PricingPool} groupsAllowed={isDocUser ? [] : allGroups} />
                                <DrcSecureGroupRoute
                                    path="/SettlementWorkbench/"
                                    exact
                                    component={WeeklySettlementStatus}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/Reports/" exact component={Reports} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/PoolWeek" exact component={PoolWeek} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/LookUp" exact component={LookUp} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ItemUomConversion" exact component={ItemUomConversion} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ItemShippingRates" exact component={ItemShippingRates} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/SettlementRate" exact component={SettlementRate} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/RepackagingSkuMapping"
                                    exact
                                    component={RepackagingSkuMapping}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/GrowerCategory" exact component={GrowerCategory} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/GrowerFixedPrice" exact component={GrowerFixedPrice} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/CustomerCodeCategory" exact component={CustomerCodeCategory} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/VatRateMaintenance" exact component={VatRateMaintenance} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/GrowerPercentage" exact component={GrowerPercentage} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ConversionRate" exact component={ConversionRate} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ImportSettlementRate" exact component={ImportSettlementRate} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/JournalAccount" exact component={JournalAccount} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute
                                    path="/SettlementWorkbench/:poolWeek/Details"
                                    exact
                                    component={SettlementWorkbenchDetails}
                                    groupsAllowed={allGroups}
                                />
                                <Route
                                    path="/implicit/callback"
                                    render={(props) => (
                                        <DrcImplicitCallback
                                            {...props}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                            legendClassName={classes.legendClassName}
                                        />
                                    )}
                                />
                                <Route path="/UserPreferences" exact component={UserPreference} />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    <DrcVersionInfo allowClick={this.props.isInitialized} />
                    {/* <DrcLoading hidden={!this.props.showLoadingScreen} text={this.props.loadingScreenMessage} /> */}
                    <DrcBackdrop isLoading show={this.props.showLoadingScreen} loadingMessage={this.props.loadingScreenMessage} />
                    <DrcDialog
                        isError={this.props.commonDialog.isMessage}
                        title={this.props.commonDialog.title}
                        open={this.props.commonDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.commonDialog.content}
                    </DrcDialog>
                    <DrcDialog
                        title={'New version available'}
                        open={this.props.serviceWorkerUpdated}
                        buttons={
                            <>
                                <DrcButton onClick={this.closeNewVersionDialog}>Close</DrcButton>
                                <DrcButton isPrimary onClick={this.updateServiceWorker}>
                                    Ok
                                </DrcButton>
                            </>
                        }
                    >
                        <div>Refresh to update your app?</div>
                    </DrcDialog>
                </Router>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                    open={this.props.toastMessage !== ''}
                    autoHideDuration={5000}
                    onClose={this.handleCloseNotification}
                    message={<span id="message-id">{this.props.toastMessage}</span>}
                />
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showReportBackLink: state.rootReducer.showReportBackLink,
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        userLang: state.masterReducer.userLang,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess,
        serviceWorkerRegistration: state.rootReducer.serviceWorkerRegistration,
        serviceWorkerUpdated: state.rootReducer.serviceWorkerUpdated
    };
}

const mapDispatchToProps = (dispatch) => ({
    hideCommonDialog: () => dispatch(hideCommonDialogAction()),
    showReportBackLinkAction: () => dispatch(showReportBackLinkAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setUserLang: (lang) => dispatch(setUserLang(lang)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess)),
    serviceWorkerUpdate: () => dispatch(serviceWorkerUpdate())
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
