import React, { Component } from 'react';
import PrimeReactTable from '../../../components/PrimeReactTable';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcButton, DrcDialog, DrcTranslate, DrcSelect, DrcMain } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Grid from '@material-ui/core/Grid';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { alphaNumeric, decimalWithSign, maxlen, numeric, precision, required } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { categoryOptions, customerCodeOptions, designColors, repackageMovementColumns } from '../../../data/constants';
import Filter from '../../../components/Filter/Filter';
import NoData from '../../../components/NoData';

const styles = (theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class RepackageMovement extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        repackageOptions: [{}],
        invalidFields: []
    };
    validationFields = {
        IncludeQuality: {
            key: 'IncludeQuality',
            validations: [decimalWithSign, precision],
            customProps: { precision: 2 }
        },
        OriginalItemID: {
            key: 'OriginalItemID',
            validations: [numeric, required, maxlen],
            customProps: { required: true, maxlen: 6 }
        },
        RepackageItemID: {
            key: 'RepackageItemID',
            validations: [numeric, required, maxlen],
            customProps: { required: true, maxlen: 6 }
        },
        OpeningBalanceKG: {
            key: 'OpeningBalanceKG',
            validations: [decimalWithSign, precision],
            customProps: { precision: 2 }
        },
        Remark: {
            key: 'Remark',
            validations: [maxlen, alphaNumeric],
            customProps: { maxlen: 256 }
        },
        SalesQty: {
            key: 'SalesQty',
            validations: [decimalWithSign, precision],
            customProps: { precision: 2 }
        },
        ReceiptQty: {
            key: 'ReceiptQty',
            validations: [decimalWithSign, precision],
            customProps: { precision: 2 }
        },
        SalesOrderSold: {
            key: 'SalesOrderSold',
            validations: [decimalWithSign, precision],
            customProps: { precision: 2 }
        }
    };
    excludingFields = ['Actions'];
    readOnly = false;
    uniqueKeyField = 'RepackageMovementID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        this.getRepackageOptions();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({ editingRow: { ...data, [data[this.uniqueKeyField]]: true }, invalidFields: [] });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };
    cannotEdit = (_row) => null;

    deleteData = (_rowData) => null;

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            const { OpeningBalanceKG, SalesQty, ReceiptQty, SalesOrderSold, IncludeQuality } = this.state.editingRow;

            let data = await Call(
                {
                    name: 'RepackageMovement',
                    url: APIEndPoints.PUT_REPACKAGE_MOVEMENT(),
                    method: 'PUT',
                    options: {},
                    data: {
                        OpeningBalanceKG: '' + (this.state.editingRow['OpeningBalanceKG'] || 0),
                        RepackageMovementID: this.state.editingRow['RepackageMovementID'],
                        OriginalItemID: '' + this.state.editingRow['OriginalItemID'],
                        Remark: this.state.editingRow['Remark'],
                        IncludeQuality: '' + (this.state.editingRow['IncludeQuality'] || 0),
                        DamageLoss: '' + parseFloat(((IncludeQuality || 0) / (parseFloat(SalesOrderSold || 1) || 1)) * 100).toFixed(2),
                        Category: this.state.editingRow['Category'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        ReceiptQty: '' + (this.state.editingRow['ReceiptQty'] || 0),
                        ClosingBalanceKG: '' + (+(OpeningBalanceKG || 0) + +(SalesQty || 0) + +(ReceiptQty || 0) - +(SalesOrderSold || 0)),
                        RepackageItemID: '' + this.state.editingRow['RepackageItemID'],
                        PoolWeek: this.state.editingRow['PoolWeek'],
                        SalesQty: '' + (this.state.editingRow['SalesQty'] || 0),
                        SalesOrderSold: '' + (this.state.editingRow['SalesOrderSold'] || 0),
                        CustomerCode: '' + (this.state.editingRow['CustomerCode'] || ''),
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if ((data.errors.length && !data.raw) || data.raw.data.Status === 409) {
                this.setState({ editingRow: {} });
                return this.showError(data.raw.data.Message);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError('', error);
        }
    };
    cannotDelete = (_row) => null;

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            const { OpeningBalanceKG, SalesQty, ReceiptQty, SalesOrderSold, IncludeQuality } = this.state.newRecord;
            let data = await Call(
                {
                    name: 'RepackageMovement',
                    url: APIEndPoints.PUT_REPACKAGE_MOVEMENT(),
                    method: 'POST',
                    options: {},
                    data: {
                        Category: this.state.newRecord['Category'].value,
                        OpeningBalanceKG: this.state.newRecord['OpeningBalanceKG'] ? this.state.newRecord['OpeningBalanceKG'] : '0.0',
                        RepackageMovementID: this.state.newRecord['RepackageMovementID'],
                        OriginalItemID: '' + this.state.newRecord['OriginalItemID'],
                        Remark: this.state.newRecord['Remark'],
                        IncludeQuality: this.state.newRecord['IncludeQuality'] ? '' + this.state.newRecord['IncludeQuality'] : '0.0',
                        DamageLoss: '' + parseFloat(((IncludeQuality || 0) / (parseFloat(SalesOrderSold || 1) || 1)) * 100).toFixed(2),
                        ReceiptQty: this.state.newRecord['ReceiptQty'] ? '' + this.state.newRecord['ReceiptQty'] : '0.0',
                        ClosingBalanceKG: '' + (+(OpeningBalanceKG || 0) + +(SalesQty || 0) + +(ReceiptQty || 0) - +(SalesOrderSold || 0)),
                        RepackageItemID: '' + this.state.newRecord['RepackageItemID'],
                        PoolWeek: this.props.selectedPoolWeek.PoolWeek,
                        SalesQty: this.state.newRecord['SalesQty'] ? '' + this.state.newRecord['SalesQty'] : '0.0',
                        SalesOrderSold: this.state.newRecord['SalesOrderSold'] ? '' + this.state.newRecord['SalesOrderSold'] : '0.0',
                        CustomerCode: this.state.newRecord['CustomerCode'] ? '' + this.state.newRecord['CustomerCode'] : '',
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if ((data.errors.length && !data.raw) || data.raw.data.Status === 409) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.raw.data.Message);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError('', error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() =>
                    !!this.state.invalidFields.length || !this.state.editingRow.OriginalItemID || !this.state.editingRow.RepackageItemID
                }
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  [
                      'IncludeQuality',
                      'RepackageItemID',
                      'OriginalItemID',
                      'OpeningBalanceKG',
                      'Category',
                      'SalesQty',
                      'ReceiptQty',
                      'SalesOrderSold',
                      'Remark'
                  ],
                  ['RepackageMovementID'],
                  repackageMovementColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'RepackageMovement',
                    url: APIEndPoints.GET_REPACKAGE_MOVEMENT(
                        userBusinessUnit(),
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        getAppliedFilters(this.props.filters).includes('poolweek')
                            ? getAppliedFilters(this.props.filters)
                            : getAppliedFilters(this.props.filters) +
                                  `&poolweek=${this.props.selectedPoolWeek.PoolWeek || this.props.match.params.poolWeek}`
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError('', data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError('', error);
        }
    };

    getRepackageOptions = async () => {
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'RepackagingSkuMapping',
                    url: APIEndPoints.GET_REPACKAGING_SKU_MAPPING(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit()
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError('', data.errors[0]);
            }
            const repackageOptions = (data.raw.data.Data || []).map((itm) => ({
                ...itm,
                label: `${itm.RepackageItemID} - ${itm.OriginalItemID}`,
                value: `${itm.RepackageItemID} - ${itm.OriginalItemID}`
            }));
            this.setState((prevState) => ({ ...prevState, repackageOptions }));
        } catch (error) {
            this.showError('', error);
        }
    };

    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key];
        switch (key) {
            case 'Category':
                return this.selectCellEditor(key, col, others, categoryOptions);
            case 'RepackageItemID':
                if (this.state.editingRow['Category'] === 'Direct Pack')
                    return this.inputTextEditor(key, col, others, field.validations, field.customProps);
                return this.selectCellEditor(key, col, others, this.state.repackageOptions);
            case 'OpeningBalanceKG':
            case 'IncludeQuality':
            case 'Remark':
            case 'SalesQty':
            case 'ReceiptQty':
            case 'SalesOrderSold':
                return this.inputTextEditor(key, col, others, field.validations, field.customProps);
            case 'OriginalItemID':
                if (this.state.editingRow['Category'] === 'Direct Pack')
                    return this.inputTextEditor(key, col, others, field.validations, field.customProps);
                return this.state.editingRow[key];
        }
    };

    inputTextEditor = (key, _col, _others, validations = [], customProps = {}) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    maxvalue={100}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    handleCategoryChangeOnEdit = (key, option) => {
        if (key === 'RepackageItemID' && this.state.editingRow['Category'] !== 'Direct Pack') {
            this.setState((prevState) => ({
                ...prevState,
                editingRow: { ...prevState.editingRow, [key]: option.RepackageItemID, OriginalItemID: option.OriginalItemID },
                [key]: option
            }));
        } else if (key === 'Category') {
            if (option.value !== this.state.editingRow[key]) {
                option.value === 'Direct Pack'
                    ? this.setState({
                          editingRow: { ...this.state.editingRow, [key]: option.value, OriginalItemID: '' }
                      })
                    : this.setState({
                          editingRow: {
                              ...this.state.editingRow,
                              [key]: option.value,
                              OriginalItemID:
                                  (
                                      this.state.repackageOptions.find(
                                          (itm) =>
                                              itm.value === this.state.editingRow.RepackageItemID &&
                                              (this.state.RepackageItemID || {}).OriginalItemID === itm.OriginalItemID
                                      ) || {}
                                  ).OriginalItemID || ''
                          }
                      });
            }
        } else {
            this.setState({
                editingRow: { ...this.state.editingRow, [key]: option.value },
                [key]: option
            });
        }
    };

    selectCellEditor = (key, _col, _others, option) => {
        return (
            <DrcSelect
                options={option}
                label="Select"
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(e) => this.handleCategoryChangeOnEdit(key, e)}
                value={
                    key === 'RepackageItemID'
                        ? option.find(
                              (itm) => itm.value === `${this.state.editingRow['RepackageItemID']} - ${this.state.editingRow['OriginalItemID']}`
                          )
                        : option.find((itm) => itm.value === this.state.editingRow[key])
                }
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (msg, err) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.cardContainer}>
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['RepackageItemId', 'OriginalItemId', 'CustomerCode', 'Category']}
                    />
                </div>

                <div className={classes.pageActions}>
                    <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                        style={{ marginLeft: 'auto' }}
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton>
                </div>
                <DrcMain className={classes.container}>
                    {!!this.state.data.length ? (
                        <PrimeReactTable
                            value={this.state.data}
                            columns={this.state.columns}
                            editMode="row"
                            frozenWidth={'80px'}
                            rowSelect={false}
                            selectionMode="multiple"
                            tableSelectionMode="checkbox"
                            selected={this.state.selectedRow}
                            selectedFrozen={true}
                            selectedClass={classes.selectedClass}
                            selectionChange={(e) => {
                                this.setState({ selectedRow: e.value });
                            }}
                            uniqueKeyField={this.uniqueKeyField}
                            editingRows={this.state.editingRow}
                            paginator={this.state.data.length > 0}
                            currentPage={this.state.pageNum}
                            pageSize={this.state.pageSize}
                            onPage={this.onPage}
                            totalRecords={this.state.totalCount}
                            editor={(key, col, others) => {
                                return this.getRowEditor(
                                    key,
                                    col,
                                    others,
                                    this.validationFields[key] ? this.validationFields[key].validations : [],
                                    this.validationFields[key] ? this.validationFields[key].customProps : {}
                                );
                            }}
                        />
                    ) : (
                        <NoData />
                    )}
                </DrcMain>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord['OriginalItemID'] &&
                                        this.state.newRecord['Category'] &&
                                        this.state.newRecord['RepackageItemID']
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DrcSelect
                                label="Category"
                                required
                                onChange={(option) =>
                                    this.setState((prevState) => ({
                                        newRecord: { ...prevState.newRecord, Category: option, OriginalItemID: null, RepackageItemID: null }
                                    }))
                                }
                                value={this.state.newRecord['Category']}
                                className={this.props.classes.input}
                                options={categoryOptions}
                            />

                            {this.state.newRecord['Category'] &&
                                (this.state.newRecord['Category'].value == 'Direct Pack' ? (
                                    <>
                                        <GGSFormInput
                                            type="text"
                                            label={'Repackage Item ID'}
                                            required
                                            validations={
                                                this.validationFields['RepackageItemID'] ? this.validationFields['RepackageItemID'].validations : []
                                            }
                                            {...(this.validationFields['RepackageItemID']
                                                ? this.validationFields['RepackageItemID'].customProps
                                                : {})}
                                            className={this.props.classes.input}
                                            onChange={(e) =>
                                                this.setState((prevState) => ({
                                                    newRecord: {
                                                        ...prevState.newRecord,
                                                        RepackageItemID: e.target.value
                                                    }
                                                }))
                                            }
                                            value={this.state.newRecord['RepackageItemID']}
                                            validationDetected={(validate) => this.handleValidationDetected(validate, 'RepackageItemID')}
                                        />
                                        <GGSFormInput
                                            type="text"
                                            label={'Original Item ID'}
                                            required
                                            readOnly={true}
                                            validations={
                                                this.validationFields['OriginalItemID'] ? this.validationFields['OriginalItemID'].validations : []
                                            }
                                            {...(this.validationFields['OriginalItemID'] ? this.validationFields['OriginalItemID'].customProps : {})}
                                            className={this.props.classes.input}
                                            onChange={(e) =>
                                                this.setState((prevState) => ({
                                                    newRecord: { ...prevState.newRecord, OriginalItemID: e.target.value }
                                                }))
                                            }
                                            value={this.state.newRecord['OriginalItemID']}
                                            validationDetected={(validate) => this.handleValidationDetected(validate, 'OriginalItemID')}
                                        />
                                    </>
                                ) : (
                                    <DrcSelect
                                        label="Repackage Item ID"
                                        required
                                        onChange={(option) =>
                                            this.setState((prevState) => ({
                                                ...prevState,
                                                newRecord: {
                                                    ...prevState.newRecord,
                                                    RepackageItemID: option.RepackageItemID,
                                                    OriginalItemID: option.OriginalItemID
                                                }
                                            }))
                                        }
                                        value={this.state.repackageOptions.find(
                                            (itm) =>
                                                itm.value === `${this.state.newRecord['RepackageItemID']} - ${this.state.newRecord['OriginalItemID']}`
                                        )}
                                        className={this.props.classes.input}
                                        options={this.state.repackageOptions}
                                    />
                                ))}

                            <DrcSelect
                                label="Customer Code"
                                onChange={(option) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, CustomerCode: option.value } }))
                                }
                                value={customerCodeOptions.find((itm) => itm.value === this.state.newRecord['CustomerCode'])}
                                className={this.props.classes.input}
                                options={customerCodeOptions}
                            />

                            <GGSFormInput
                                type="text"
                                label={'Opening Balance KG'}
                                validations={this.validationFields['OpeningBalanceKG'] ? this.validationFields['OpeningBalanceKG'].validations : []}
                                {...(this.validationFields['OpeningBalanceKG'] ? this.validationFields['OpeningBalanceKG'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, OpeningBalanceKG: e.target.value } }))
                                }
                                value={this.state.newRecord['OpeningBalanceKG']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'OpeningBalanceKG')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Remark'}
                                validations={this.validationFields['Remark'] ? this.validationFields['Remark'].validations : []}
                                {...(this.validationFields['Remark'] ? this.validationFields['Remark'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Remark: e.target.value } }))}
                                value={this.state.newRecord['Remark']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'Remark')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Include Quality'}
                                validations={this.validationFields['IncludeQuality'] ? this.validationFields['IncludeQuality'].validations : []}
                                {...(this.validationFields['IncludeQuality'] ? this.validationFields['IncludeQuality'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, IncludeQuality: e.target.value } }))
                                }
                                value={this.state.newRecord['IncludeQuality']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'IncludeQuality')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Receipt Qty'}
                                validations={this.validationFields['ReceiptQty'] ? this.validationFields['ReceiptQty'].validations : []}
                                {...(this.validationFields['ReceiptQty'] ? this.validationFields['ReceiptQty'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, ReceiptQty: e.target.value } }))
                                }
                                value={this.state.newRecord['ReceiptQty']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'ReceiptQty')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Sales Qty'}
                                validations={this.validationFields['SalesQty'] ? this.validationFields['SalesQty'].validations : []}
                                {...(this.validationFields['SalesQty'] ? this.validationFields['SalesQty'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, SalesQty: e.target.value } }))}
                                value={this.state.newRecord['SalesQty']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'SalesQty')}
                            />
                            <GGSFormInput
                                type="text"
                                label={'Sales Order Sold'}
                                validations={this.validationFields['SalesOrderSold'] ? this.validationFields['SalesOrderSold'].validations : []}
                                {...(this.validationFields['SalesOrderSold'] ? this.validationFields['SalesOrderSold'].customProps : {})}
                                className={this.props.classes.input}
                                onChange={(e) =>
                                    this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, SalesOrderSold: e.target.value } }))
                                }
                                value={this.state.newRecord['SalesOrderSold']}
                                validationDetected={(validate) => this.handleValidationDetected(validate, 'SalesOrderSold')}
                            />
                        </Grid>
                    </Form>
                </DrcDialog>
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, weeklySettlementStatusReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    filters: filterReducer.filters,
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(RepackageMovement))));
