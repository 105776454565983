import { enUS } from './en-US';
import { zhCN } from './zh-CN';

// [English, Spanish, Chinese, French] 1st four order is needed for style-guide
export const languagesSupported = {
    enUS,
    zhCN
};

export const languagesSupportedMap = [
    { name: 'English', code: 'en-US' },
    { name: 'Chinese', code: 'zh-CN' }
];
