import React, { useState, useEffect } from 'react';
import DrcWrapper from '../../../components/DrcWrapper';
import { makeStyles } from '@material-ui/core/styles';
import DataTable from '../../../components/DataTable';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import Stepper from '../../../components/Stepper';
import { designColors, poolWeekDsColumns } from '../../../data/constants';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { displayErrorMessage, getKeyDate, makeColumns } from '../../../utils/helper';
import { DrcTranslate, DrcDatePicker, DrcSelect, DrcButton } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import Grid from '@material-ui/core/Grid';
import { format, isValid } from 'date-fns';
import FilterListSharp from '@material-ui/icons/FilterListSharp';

const pageTitle = 'Pool Week';
const useStyles = makeStyles((theme) => ({
    root: {
        '& .select': {
            top: 2,
            maxWidth: '400px',
            [theme.darkTheme]: {
                backgroundColor: `#303030 !important`
            }
        },
        '& .select > div:first-of-type': {
            backgroundColor: theme.palette.common.white,
            [theme.darkTheme]: {
                backgroundColor: `#303030 !important`
            }
        }
    },
    poolContainerHeader: {
        padding: '10px',
        marginLeft: '30px'
    },
    cardContainer: {
        backgroundColor: '#f9f9f9',
        borderTop: '2px solid #E0E0E0',
        overflowX: 'auto',
        padding: '10px 10px 0px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    card: {
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: '4px',
        padding: '10px 20px',
        [theme.darkTheme]: {
            backgroundColor: `#303030 !important`
        }
    },
    cardContent: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    datePickerClass: {
        marginLeft: '10px'
    },
    optionClass: {
        color: `${designColors.blue}`,
        fontWeight: 'bold',
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'end',
        justifyContent: 'end',
        padding: 12,
        flex: 1
    },
    errorClass: {
        color: 'red',
        fontSize: '10px',
        marginTop: '-2px'
    }
}));
const businessUnitOptions = [
    { label: 'DOC', value: 'DOC' },
    { label: 'DOTA', value: 'DOTA' }
];
const filterOptions = [
    { label: 'Filter by Year', value: 'Year' },
    { label: 'Filter by Date', value: 'Date' },
    { label: 'Filter by KeyDate', value: 'KeyDate' }
];
function PoolWeek(props) {
    const classes = useStyles();
    const [startDate, setStartDate] = useState(format(new Date(), 'yyyy/MM/dd'));
    const [endDate, setEndDate] = useState(format(new Date(), 'yyyy/MM/dd'));
    const [years, setYears] = useState(new Date());
    const [businessUnit, setBusinessUnit] = useState({ label: 'DOC', value: 'DOC' });
    const [searchKeyDate, setSearchKey] = useState();
    const [poolWeekData, setPoolWeekData] = useState([]);
    const [dateError, setDateError] = useState('');
    const [filterSelect, setFilterSelect] = useState({ label: 'Filter by Year', value: 'Year' });
    const [showFilter, setShowFilter] = useState(false);
    const aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

    function filterFunction() {
        if (filterSelect.value === 'Year') {
            getPoolWeekByYear();
        } else if (filterSelect.value === 'KeyDate') {
            getSearchKeyData();
        } else if (filterSelect.value === 'Date') {
            getDataByDate();
        }
    }
    function checkDateValidation() {
        if (new Date(startDate) > new Date(endDate)) {
            setDateError('End Date should be greater than start date');
        } else {
            setDateError('');
        }
    }
    useEffect(() => {
        checkDateValidation();
        if (props.pageTitle !== pageTitle) {
            props.setPageTitle(pageTitle);
        }
    }, [startDate, endDate]);
    useEffect(() => {
        getPoolWeekByYear();
    }, []);

    const getSearchKeyData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();

        try {
            let data = await Call(
                {
                    name: 'poolweek',
                    url: APIEndPoints.GET_CURRENT_POOL_WEEK(
                        searchKeyDate ? (searchKeyDate || '').replace(/\//g, '') : getKeyDate(new Date()),
                        businessUnit.value
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            props.hideLoadingScreenAction();
            setPoolWeekData(data?.display || []);
        } catch (error) {
            showError(error);
        }
    };
    const getDataByDate = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();

        try {
            let data = await Call(
                {
                    name: 'poolweek',
                    url: APIEndPoints.GET_POOL_WEEK_BY_DATE(startDate, endDate, businessUnit.value),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            console.log({ data });
            setPoolWeekData(data?.display || []);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };
    const getColumns = (data = []) => {
        let excludedCol = ['DayDate', 'KeyDate', 'MODIFIEDDATETIME', 'ModifiedBy', 'PoolWeekID'];
        return makeColumns(Object.keys(data[0] || {}), excludedCol, null, false, [], [], poolWeekDsColumns);
    };

    const toggleShowFilter = () => setShowFilter(!showFilter);

    const getPoolWeekByYear = async () => {
        try {
            props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await props.oktaAuth.getAccessToken();
            let userId = DuAuthenticationUtilities.GetUserId(token) || '';
            let firstName = userId.split('.')[0];

            let data = await Call(
                {
                    name: 'poolweek',
                    url: APIEndPoints.POST_POOL_WEEK_YEAR(),
                    method: 'POST',
                    options: {},
                    data: {
                        Year: years.getFullYear(),
                        BusinessUnit: businessUnit.value,
                        SearchBy: firstName
                    }
                },
                token
            );

            if (data.errors.length && !data.raw) {
                return showError(data.errors[0]);
            }
            if (!data) {
                showError();
            } else {
                props.hideLoadingScreenAction();
                setPoolWeekData(data?.display?.resultSet1 || []);
            }
        } catch (error) {
            showError(error);
        }
    };
    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }

    return (
        <DrcWrapper>
            <Stepper page={pageTitle} />
            <div className={classes.poolContainerHeader}>
                <div className={classes.cardContainer}>
                    {showFilter && (
                        <div className={classes.card}>
                            <Grid container spacing={1}>
                                <>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <p>
                                            <DrcTranslate>{'Choose Filter'}</DrcTranslate>
                                        </p>
                                        <DrcSelect
                                            options={filterOptions}
                                            label="Select"
                                            hideLabel={true}
                                            className={classes.root}
                                            onChange={setFilterSelect}
                                            value={filterSelect}
                                            menuShouldBlockScroll={true}
                                            menuPortalTarget={document.body}
                                            menuPlacement={'auto'}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <p>
                                            <DrcTranslate>{'Business Unit'}</DrcTranslate>
                                        </p>
                                        <DrcSelect
                                            options={businessUnitOptions}
                                            label="Select"
                                            hideLabel={true}
                                            className={classes.root}
                                            onChange={setBusinessUnit}
                                            value={businessUnit}
                                            menuShouldBlockScroll={true}
                                            menuPortalTarget={document.body}
                                            menuPlacement={'auto'}
                                        />
                                    </Grid>
                                </>

                                {filterSelect.value === 'Year' && (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <p>
                                            <DrcTranslate>{'Year'}</DrcTranslate>
                                        </p>
                                        <DrcDatePicker
                                            views={['year']}
                                            InputLabelProps={{ shrink: true }}
                                            required={false}
                                            onChange={(newValue) => {
                                                setYears(newValue);
                                            }}
                                            selectedDate={years}
                                            emptyLabel=""
                                            format={'yyyy'}
                                            maxDate={aYearFromNow}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                )}
                                {filterSelect.value === 'Date' && (
                                    <>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <div className={classes.datePickerClass}>
                                                <p>
                                                    <DrcTranslate>{'Start Date'}</DrcTranslate>
                                                </p>
                                                <DrcDatePicker
                                                    InputProps={{ readOnly: true }}
                                                    InputLabelProps={{ shrink: true }}
                                                    required={false}
                                                    onChange={(date) => {
                                                        setStartDate(isValid(date) ? format(date, 'yyyy/MM/dd') : date);
                                                    }}
                                                    selectedDate={startDate ? new Date(startDate) : null}
                                                    emptyLabel=""
                                                />
                                                <p className={classes.errorClass}>{dateError || ''}</p>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={6} md={4} lg={3}>
                                            <div className={classes.datePickerClass}>
                                                <p>
                                                    <DrcTranslate>{'End Date'}</DrcTranslate>
                                                </p>
                                                <DrcDatePicker
                                                    InputProps={{ readOnly: true }}
                                                    InputLabelProps={{ shrink: true }}
                                                    required={false}
                                                    onChange={(date) => {
                                                        setEndDate(isValid(date) ? format(date, 'yyyy/MM/dd') : date);
                                                    }}
                                                    selectedDate={endDate ? new Date(endDate) : null}
                                                    emptyLabel=""
                                                />
                                            </div>
                                        </Grid>
                                    </>
                                )}

                                {filterSelect.value === 'KeyDate' && (
                                    <Grid item xs={12} sm={6} md={4} lg={3}>
                                        <div className={classes.datePickerClass}>
                                            <p>
                                                <DrcTranslate>{'Search Key Date'}</DrcTranslate>
                                            </p>
                                            <DrcDatePicker
                                                InputProps={{ readOnly: true }}
                                                InputLabelProps={{ shrink: true }}
                                                required={false}
                                                onChange={(date) => {
                                                    setSearchKey(isValid(date) ? format(date, 'yyyy/MM/dd') : date);
                                                }}
                                                selectedDate={searchKeyDate ? new Date(searchKeyDate) : null}
                                                emptyLabel=""
                                            />
                                        </div>
                                    </Grid>
                                )}
                                <div className={classes.optionClass}>
                                    <DrcButton onClick={filterFunction} disabled={!!dateError}>
                                        <DrcTranslate>{'Apply'}</DrcTranslate>
                                    </DrcButton>
                                </div>
                            </Grid>
                        </div>
                    )}

                    <div>
                        <DrcButton onClick={toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                {/* Table starts */}
                <DataTable total={poolWeekData.length} data={poolWeekData} columns={getColumns(poolWeekData || [])} />
            </div>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ masterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(PoolWeek));
