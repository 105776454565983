import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import PoolDefinitions from './poolDefinition';
import { designColors, receiptLinesColumns } from '../../../data/constants';
import { DrcButton, DrcMain, DrcTranslate, DrcCollapsiblePanel, DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-components';
import { makeStyles } from '@material-ui/core/styles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import {} from '@driscollsinc/driscolls-react-utilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { makeColumns, userBusinessUnit, getAppliedFilters, displayErrorMessage } from '../../../utils/helper';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import GridStyles from '../../../styles/gridStyles';
import Divider from '@material-ui/core/Divider';
import PrimeReactTable from '../../../components/PrimeReactTable';
import { withRouter } from 'react-router';
import NoData from '../../../components/NoData';
import { SetFilterId } from '../../../actions/SettlementWorkBenchDetailsAction';
import PageHeader from '../PageHeader';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    heading: {
        color: designColors.blue,
        fontWeight: 'bold',
        [theme.darkTheme]: {
            color: `${theme.palette.common.white} !important`
        }
    },
    divider: {
        marginTop: 8,
        marginBottom: 14
    },
    searchIcon: {
        borderRadius: 4,
        padding: 8,
        backgroundColor: designColors.blue,
        color: 'white',
        '&:hover': {
            backgroundColor: designColors.blue,
            opacity: '0.9'
        }
    },
    rootStyles: {
        margin: 0
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
}));

function ReceiptLines(props) {
    const classes = useStyles();
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [pageNum, setPageNum] = useState(0);
    const [pageSize, setPageSize] = useState(50);
    const [totalRecords, setTotalRecords] = useState(50);
    const [showFilter, setShowFilter] = useState(false);
    useEffect(() => {
        getReceiptLines(0);
    }, []);

    const getReceiptLines = async (page = 0) => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            let response = await Call(
                {
                    name: 'ReceiptLines',
                    url: APIEndPoints.GET_RECEIPT_LINES(
                        page / pageSize + 1,
                        pageSize,
                        userBusinessUnit(),
                        props.selectedPoolWeek.PoolWeek || props.match.params.poolWeek,
                        getAppliedFilters(props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (response.errors.length && !response.raw) {
                return showError(response.errors[0]);
            }
            let responseData = response?.display?.Data || [];
            let responseColumns = responseData.length
                ? makeColumns(Object.keys(responseData[0]), [], null, false, [], ['VendorID', 'ReceiptID'], receiptLinesColumns)
                : [];
            setData(responseData || []);
            setTotalRecords(response?.display?.TotalCount || 0);
            setColumns(responseColumns);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };

    const onPage = (event) => {
        setPageNum(event.first);
        getReceiptLines(event.first);
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    const applyFilter = () => {
        setPageNum(0);
        getReceiptLines();
    };

    const toggleShowFilter = () => setShowFilter(!showFilter);

    const resetFilter = () => {
        setPageNum(0);
        getReceiptLines();
    };

    return (
        <div className={classes.cardContainer}>
            {/* <DrcMain className={classes.container}> */}
            <DrcCollapsiblePanel header={<PageHeader />} rootStyles={classes.rootStyles}>
                <PoolDefinitions />
            </DrcCollapsiblePanel>
            {/* <SummaryReview /> */}
            {/* </DrcMain> */}
            <DrcMain className={classes.container}>
                <Typography className={classes.heading}>
                    <DrcTranslate>{'RECEIPT LINES'}</DrcTranslate>
                </Typography>
                <Divider className={classes.divider} />

                <div className={classes.filterContainer}>
                    <Filter showFilter={showFilter} applyFilter={applyFilter} resetFilter={resetFilter} filterNames={['ItemID', 'ReceiptId']} />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                {data.length ? (
                    <PrimeReactTable
                        paginator={data.length > 0}
                        currentPage={pageNum}
                        onPage={onPage}
                        columns={columns}
                        value={data}
                        gridStyles={classes.gridStyles}
                        totalRecords={totalRecords}
                        resultCount={totalRecords}
                        pageSize={pageSize}
                        uniqueKeyField={'ReceiptID'}
                        showReport={true}
                        currentPageReportTemplate={'Showing {first} to {last} of {totalRecords} entries'}
                        frozenWidth="120px"
                    />
                ) : (
                    <NoData />
                )}
            </DrcMain>
        </div>
    );
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, filterReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    selectedSettlementRecord: weeklySettlementStatusReducer.selectedSettlementRecord,
    filters: filterReducer.filters
});
const mapDispatchToProps = (dispatch) => ({
    SetFilterId: (filteredId) => dispatch(SetFilterId(filteredId)),
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(ReceiptLines)));
