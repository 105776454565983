import React, { useState, useEffect } from 'react';
import { DrcMain, DrcTranslate, DrcSelect, DrcButton } from '@driscollsinc/driscolls-react-components';
import { makeStyles } from '@material-ui/core/styles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router';
import { Divider, Grid } from '@material-ui/core';
import { BU, designColors } from '../data/constants';
import DrcWrapper from '../components/DrcWrapper';
import Stepper from '../components/Stepper';
import { languagesSupportedMap } from '../data/translations.js';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const pageTitle = 'User Preferences';
const useStyles = makeStyles((theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    card: {
        backgroundColor: 'white',
        boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 12px',
        borderRadius: '4px',
        padding: '10px 20px',
        [theme.darkTheme]: {
            backgroundColor: `#303030 !important`
        }
    },
    container: {
        width: '100%',
        margin: '8px 0',
        borderRadius: '8px'
    }
}));

function UserPreference(props) {
    const classes = useStyles();
    const [selectedLanguage, setSelectedLanguage] = useState({});
    const [userPreference, setUserPreference] = useState([]);
    const [selectedUserRole, setSelectedUserRole] = useState({});

    const languageOptions = languagesSupportedMap.map((lang) => ({ label: `${lang.name} - ${lang.code}`, value: lang.code }));
    const userRoleOptions = props.userRoles.map(({ display: label, group: value }) => ({
        label,
        value
    }));
    // const userBusinessUnitOptions = [...new Set(props.userRoles.map((role) => role.group.split('-')[1]))]
    //     .filter((bu) => BU.includes(bu))
    //     .map((itm) => ({ label: itm, value: itm }));

    useEffect(() => {
        getUserPreference();
        if (props.pageTitle !== pageTitle) {
            props.setPageTitle(pageTitle);
        }
    }, []);

    const getUserPreference = async () => {
        try {
            props.showLoadingScreenAction('Updating data');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUserEmail = DuAuthenticationUtilities.GetEmail(token);
            let userOktaId = DuAuthenticationUtilities.GetOktaId(token);
            var data = await Call(
                {
                    name: 'UserPreference',
                    url: APIEndPoints.GET_USER_PREFERENCES(userOktaId, loggedInUserEmail),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (!data || (!data.raw && data.errors.length)) {
                return showError(data.errors[0]);
            } else {
                props.hideLoadingScreenAction();
                let userPrefMap = data.raw.data.Data.reduce((obj, itm) => {
                    obj[itm.Name] = itm;
                    return obj;
                }, {});
                setUserPreference(userPrefMap);
                if (userPrefMap.Language) {
                    setSelectedLanguage(languageOptions.find((itm) => itm.value === userPrefMap.Language.Value) || {});
                }
                if (userPrefMap.UserRole) {
                    setSelectedUserRole(userRoleOptions.find((itm) => itm.value === userPrefMap.UserRole.Value) || {});
                }
            }
        } catch (error) {
            showError(error);
        }
    };

    const saveData = async (prefType) => {
        let value = '';
        switch (prefType) {
            case 'Language':
                value = selectedLanguage.value;
                break;
            case 'UserRole':
                value = selectedUserRole.value;
                break;
            default:
                value = '';
        }
        try {
            props.showLoadingScreenAction('Saving data');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUserEmail = DuAuthenticationUtilities.GetEmail(token);
            let userOktaId = DuAuthenticationUtilities.GetOktaId(token);
            var data = await Call(
                {
                    name: 'UserPreference',
                    url: APIEndPoints.POST_USER_PREFERENCES(),
                    method: userPreference[prefType] ? 'PUT' : 'POST',
                    options: {},
                    data: {
                        ...(userPreference[prefType] ? { UserPreferenceID: userPreference[prefType]['UserPreferenceID'] } : {}),
                        OktaID: userOktaId,
                        Email: loggedInUserEmail,
                        Name: prefType,
                        Value: value,
                        ModifiedBy: loggedInUserEmail,
                        CreatedBy: loggedInUserEmail,
                        CreatedDateTime: userPreference[prefType] ? userPreference[prefType].CreatedDateTime : new Date().toISOString(),
                        ModifiedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!data || (!data.raw && data.errors.length)) {
                return showError(data.errors[0]);
            } else {
                props.hideLoadingScreenAction();
                props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                await getUserPreference();
            }
        } catch (error) {
            showError(error);
        }
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    return (
        <DrcWrapper>
            <Stepper page={pageTitle} />
            <Divider />
            <div className={classes.cardContainer}>
                <DrcMain className={classes.card}>
                    <Grid container spacing={1}>
                        <Grid item xs={6} sm={6} md={4}>
                            <DrcSelect
                                label={<DrcTranslate>{'Language'}</DrcTranslate>}
                                className={classes.root}
                                value={selectedLanguage}
                                options={languageOptions}
                                onChange={(e) => setSelectedLanguage(e)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <DrcButton
                                isPrimary
                                onClick={() => {
                                    saveData('Language');
                                }}
                                disabled={!(selectedLanguage.value && selectedLanguage.value !== (userPreference['Language'] || {}).Value)}
                            >
                                <DrcTranslate>{'Save'}</DrcTranslate>
                            </DrcButton>
                        </Grid>
                        <Grid item xs={6} sm={6} md={4}>
                            <DrcSelect
                                label={<DrcTranslate>{'User Role'}</DrcTranslate>}
                                className={classes.root}
                                value={selectedUserRole}
                                options={userRoleOptions}
                                onChange={(e) => setSelectedUserRole(e)}
                            />
                        </Grid>
                        <Grid item xs={6} sm={6} md={2}>
                            <DrcButton
                                isPrimary
                                onClick={() => {
                                    saveData('UserRole');
                                }}
                                disabled={!(selectedUserRole.value && selectedUserRole.value !== (userPreference['UserRole'] || {}).Value)}
                            >
                                <DrcTranslate>{'Save'}</DrcTranslate>
                            </DrcButton>
                        </Grid>
                    </Grid>
                </DrcMain>
            </div>
        </DrcWrapper>
    );
}
const mapStateToProps = (state) => ({
    isMasterDataInitialized: state.masterReducer.isInitialized,
    userRoles: state.rootReducer.userRoles,
    userLang: state.masterReducer.userLang,
    pageTitle: state.rootReducer.pageTitle
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withOktaAuth(UserPreference)));
