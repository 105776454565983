import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { DrcTranslate, DrcSelect } from '@driscollsinc/driscolls-react-components';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { components } from 'react-select';
import GGSFormInput from '../GGSFormInput';
import Form from 'react-validation/build/form';
import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = (_theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '1rem',
        width: '30%',
        minWidth: '20rem'
    },
    input: {
        marginTop: '11px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    asyncItem: {
        height: '45px',
        width: '100%',
        alignSelf: 'center'
    },
    item: {
        width: '100%',
        alignSelf: 'center'
    },
    form: {
        width: '100%'
    }
});

class FilterItem extends Component {
    state = {
        typeAheadString: {}
    };

    makeOptions = (options, label, value) => {
        // here label and value are the key names that needto be used as label and value
        return options.map((e) => {
            e.label = e[label];
            e.value = e[value];
            return e;
        });
    };

    handleInput = (name) => (event) => {
        this.props.filterSelect(name, event.target.value);
        if (!event.target.value) {
            this.props.filterDeselect({ fieldName: name });
        }
    };

    handleSelect = (name) => (event) => {
        this.props.filterSelect(name, event);
    };

    handleCheckbox = (name) => (event) => {
        this.props.filterSelect(name, event.target.value, event.target.checked);
    };
    MultiValue = (props) => <components.MultiValue {...props}>{(props.data.value || '').substring(0, 3)}</components.MultiValue>;
    render() {
        const { classes, filter, handleValidationDetected = () => null } = this.props;
        return (
            <div className={classes.flex}>
                <div className={classes.container}>
                    {!filter.hasOptions && !filter.isAsync && !filter.isDate && !filter.isBool && (
                        <Form className={classes.form}>
                            <GGSFormInput
                                label={<DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate>}
                                className={classes.input}
                                onChange={this.handleInput(filter.fieldName)}
                                onKeyPress={(e) => {
                                    e.key === 'Enter' && e.preventDefault();
                                }}
                                value={filter.value}
                                {...(filter.customProps || {})}
                                validations={filter.validations || []}
                                validationDetected={(validate) => handleValidationDetected(validate, filter.fieldName)}
                            />
                        </Form>
                    )}
                    {filter.hasOptions && !filter.isBerry && !filter.isCheckbox && (
                        <DrcSelect
                            closeMenuOnSelect={false}
                            label={<DrcTranslate>{filter.displayName || filter.fieldName}</DrcTranslate>}
                            onChange={this.handleSelect(filter.fieldName)}
                            className={classes.item}
                            options={this.makeOptions(filter.options, filter.optionLabel, filter.optionValue)}
                            value={filter.selected}
                            isMulti={!!filter.isMulti}
                            controlShouldRenderValue={!filter.isMulti}
                            placeholder={<DrcTranslate>{'Select'}</DrcTranslate>}
                            // components={{ MultiValue: this.MultiValue }}
                        ></DrcSelect>
                    )}
                    {filter.hasOptions && filter.isCheckbox && (
                        <FormControl component="fieldset" margin="dense" fullWidth>
                            <FormLabel component="legend">{filter.displayName || filter.fieldName}</FormLabel>
                            <FormGroup aria-label="position" row>
                                {filter.options.map((itm) => (
                                    <FormControlLabel
                                        value={itm.value}
                                        control={<Checkbox color="primary" checked={filter.selected.includes(itm.value)} />}
                                        label={itm.label}
                                        onChange={this.handleCheckbox(filter.fieldName)}
                                        labelPlacement="start"
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    )}
                </div>
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        berry: state.masterReducer.berry
    };
}

export default withOktaAuth(connect(mapStateToProps)(withRouter(withStyles(styles)(FilterItem))));
