import React from 'react';
import Typography from '@material-ui/core/Typography';
import { designColors } from '../data/constants';
import { makeStyles } from '@material-ui/core/styles';
import { DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { showReportBackLinkAction } from '../actions/actions';
import { setSelectedPoolWeek } from '../actions/WeeklySettlementStatusAction';
import { withOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: 8,
        color: `${designColors.blue}`,
        [theme.darkTheme]: {
            color: `${theme.palette.common.white} !important`
        }
    },
    backButton: {
        float: 'right',
        margin: '0 15px 0 0',
        cursor: 'pointer',
        textTransform: 'uppercase'
    },
    text: {
        paddingLeft: '2rem',
        fontSize: 12,
        color: `${designColors.blue}`,
        [theme.darkTheme]: {
            color: `${theme.palette.common.white} !important`
        }
    }
}));

function Stepper(props) {
    const classes = useStyles();
    const { page = '', tabName = '', showBackLink = false, backLink = '', poolweek = '', isDataSetup, children } = props;

    const handleNavigationPoolweek = (pathname, poolweek, state) => {
        props.showReportBackLink(false);
        props.setSelectedPoolWeek(poolweek);
        props.history.push({
            pathname,
            state
        });
    };

    return (
        <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ margin: '0 8px' }}>
                    <div className={classes.backButton} style={{ display: showBackLink ? 'block' : 'none' }}>
                        <Typography
                            display="inline"
                            noWrap
                            onClick={() => {
                                handleNavigationPoolweek(backLink, poolweek, 0);
                            }}
                            className={`${classes.text} ${classes.link}`}
                        >
                            &lt;&lt; <DrcTranslate>{'Back'}</DrcTranslate>
                        </Typography>
                    </div>
                    <Typography variant="h6" display="inline" noWrap className={classes.text}>
                        {isDataSetup ? (
                            <>
                                <DrcTranslate>{'Home'}</DrcTranslate> / <DrcTranslate>{'Data Setup'}</DrcTranslate>
                            </>
                        ) : (
                            <DrcTranslate>{'Home'}</DrcTranslate>
                        )}
                    </Typography>
                    <Typography variant="h6" display="inline" noWrap style={{ paddingLeft: '0.5rem', fontSize: 12 }}>
                        / {<DrcTranslate>{page}</DrcTranslate>}
                    </Typography>
                </div>
                <div className={classes.header}>
                    <div>
                        <Typography variant="h6" display="inline" noWrap style={{ paddingLeft: '2rem', fontSize: 16 }}>
                            {<DrcTranslate>{page}</DrcTranslate>}
                        </Typography>
                        {tabName && (
                            <Typography variant="h6" display="inline" noWrap style={{ paddingLeft: '0.5rem', fontSize: 14 }}>
                                <DrcTranslate>{tabName}</DrcTranslate>
                            </Typography>
                        )}
                    </div>
                    {children}
                </div>
            </div>
        </>
    );
}

const mapStateToProps = ({ weeklySettlementStatusReducer }) => ({
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek
});

const mapDispatchToProps = (dispatch) => ({
    showReportBackLink: (value) => dispatch(showReportBackLinkAction(value)),
    setSelectedPoolWeek: (PoolWeek) => dispatch(setSelectedPoolWeek(PoolWeek))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(Stepper));
