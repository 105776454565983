import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DrcDatePicker, DrcTranslate, DrcSelect, DrcCheckbox, DrcDialog, DrcButton } from '@driscollsinc/driscolls-react-components';
import APIEndPoints from '../../services/api';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../actions/actions';
import { designColors } from '../../data/constants';
import { setSelectedPoolWeek, setPoolweekFreezeData } from '../../actions/WeeklySettlementStatusAction';
import { connect } from 'react-redux';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { displayErrorMessage, userBusinessUnit } from '../../utils/helper';
import { withOktaAuth } from '@okta/okta-react';
import { Typography } from '@material-ui/core';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTableCell-root': {
            padding: '0px 6px'
        }
    },
    headers: {
        margin: '10px 0 0px 24px',
        color: `${designColors.blue}`,
        fontWeight: 'bold',
        fontFamily: 'Rubik',
        display: 'flex',
        flexWrap: 'wrap'
    },
    cardContainer: {
        backgroundColor: '#f9f9f9',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.common.black} !important`
        },
        // borderTop: '2px solid #E0E0E0',
        marginLeft: '24px',
        marginBottom: '20px',
        padding: '5px 10px'
    },
    filterBox: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    datePickerClass: {
        width: '200px'
    },
    optionClass: {
        color: `${designColors.blue}`,
        cursor: 'pointer',
        margin: '0 30px',
        fontWeight: 'bold',
        fontFamily: 'Rubik',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    iconsClass: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 'auto',
        alignItems: 'center'
    },
    select: {
        margin: 0,
        marginTop: 25
    },
    tableHead: {
        backgroundColor: designColors.grey,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['600']} !important`,
            color: theme.palette.common.black
        }
    }
}));

function PricingPoolCard(props) {
    const classes = useStyles();
    const [poolWeekList, setPoolWeekList] = useState();
    const [summaryTransaction, setSummaryTransaction] = useState({ ReceiptLine: [], SalesInvoice: [] });
    const [showFreezeDialog, setShowFreezeDialog] = useState(false);

    // const [filterPoolWeek, setFilterPoolWeek] = useState(0);

    useEffect(() => {
        getPoolWeekOptions();
        props.setSelectedPoolWeek(props.yearPoolWeeks[1] || {});
        if (props.selectedPoolWeek && !isNaN(props.selectedPoolWeek)) {
            props.setSelectedPoolWeek(props.yearPoolWeeks.filter((pw) => pw.PoolWeek === props.selectedPoolWeek)[0] || {});
        }
    }, [props.yearPoolWeeks]);

    useEffect(() => {
        if (props.selectedPoolWeek?.PoolWeek) getReceiptQuantity();
    }, [props.selectedPoolWeek]);

    const handleFreezePoolWeek = async () => {
        setShowFreezeDialog(false);
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            let response = await Call(
                {
                    name: 'PoolWeekShift',
                    url: APIEndPoints.POST_POOLWEEK_SHIFT(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        BusinessUnit: userBusinessUnit(),
                        Status: 'Freeze',
                        CreatedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!response) {
                showError();
            } else if (response.display.Status === 409) {
                showError(response.display.Message, 'Something Went Wrong');
            } else {
                props.onFilter();
                props.hideLoadingScreenAction();
                props.showToast(<DrcTranslate>{'PoolWeek Freezed Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            props.hideLoadingScreenAction();
            showError(error);
        }
    };

    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const handleChange = (event) => {
        props.setSelectedPoolWeek(event?.target?.value);
    };
    function createData(name, Totals, onPricingPool, remainingOnPricingPool, onSettlementPool, remainingOnSettlementPool) {
        return { name, Totals, onPricingPool, remainingOnPricingPool, onSettlementPool, remainingOnSettlementPool };
    }

    const getPoolWeekOptions = () => {
        let options = (props.yearPoolWeeks || []).map((itm) => ({ label: itm.PoolWeek, value: itm.PoolWeek, ...itm }));
        // props.setSelectedPoolWeek(props.currentPoolWeek[0] || {});
        // setToDate(new Date((props.currentPoolWeek[0] || {}).PoolWeekEndDate || new Date()));
        setPoolWeekList(options);
    };

    const getReceiptQuantity = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        //let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
        try {
            let response = await Call(
                {
                    name: 'ReceiptLines',
                    url: APIEndPoints.GET_SUMMARY_TRANSACTION(userBusinessUnit(), props.selectedPoolWeek.PoolWeek),
                    method: 'GET',
                    options: {}
                },
                token
            );
            let ReceiptLine = response?.display?.ReceiptLine || [];
            let SalesInvoice = response?.display?.SalesInvoice || [];
            setSummaryTransaction({ SalesInvoice, ReceiptLine });
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const rows = [
        createData(
            <DrcTranslate>{"Receipt's Quantity"}</DrcTranslate>,
            (summaryTransaction.ReceiptLine[0] || {}).ExpectedQuantity || 0.0,
            (summaryTransaction.ReceiptLine[0] || {}).ExpectedQuantity || 0.0,
            0.0,
            (summaryTransaction.ReceiptLine[0] || {}).ExpectedQuantity || 0.0,
            0.0
        ),
        createData(
            <DrcTranslate>{'Sales Amount'}</DrcTranslate>,
            (summaryTransaction.SalesInvoice[0] || {}).SalesAmount || 0.0,
            (summaryTransaction.SalesInvoice[0] || {}).SalesAmount || 0.0,
            0.0,
            (summaryTransaction.SalesInvoice[0] || {}).SalesAmount || 0.0,
            0.0
        )
    ];
    return (
        <React.Fragment>
            {/* <div className={classes.headers}>
                    <div className={classes.iconsClass}>
                        <BorderColorIcon style={{ marginRight: '20px' }} />
                        <FileCopyIcon style={{ marginRight: '20px' }} />
                        <SystemUpdateAltOutlinedIcon style={{ marginRight: '20px' }} />
                    </div>
                </div> */}
            <div className={classes.cardContainer}>
                <div className={classes.filterBox}>
                    <div style={{ margin: '2px 10px', minWidth: 130 }}>
                        <Typography>
                            <DrcTranslate>{'Pool Week'}</DrcTranslate>
                        </Typography>
                        {/* <FormControl style={{ minWidth: 200 }}>
                                <Select defaultValue={'Modified'} value={poolWeek} input={<OutlinedInput />} label="poolWeek" onChange={handleChange}>
                                    {PoolWeekOptions?.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl> */}
                        <DrcSelect
                            options={poolWeekList}
                            className={classes.select}
                            label={'Pool Week'}
                            hideLabel
                            onChange={(option) => {
                                // setFilterPoolWeek(option.value);
                                props.setSelectedPoolWeek(option);
                            }}
                            value={{ label: props.selectedPoolWeek.PoolWeek, value: props.selectedPoolWeek.PoolWeek }}
                        />
                    </div>
                    <div style={{ margin: '2px' }}>
                        <Typography>
                            <DrcTranslate>{'To Date'}</DrcTranslate>
                        </Typography>
                        <DrcDatePicker
                            InputLabelProps={{ shrink: true }}
                            required={false}
                            onChange={() => null}
                            selectedDate={((props.selectedPoolWeek || {}).PoolWeekEndDate || '').replace(/-/g, '/')}
                            emptyLabel=""
                            InputProps={{ readOnly: true }}
                            disabled={true}
                            style={{ marginTop: 11 }}
                        />
                    </div>
                    <div style={{ marginLeft: '2px' }}>
                        <Typography>
                            <DrcTranslate>{'Freeze GIT/On-hand Reports'}</DrcTranslate>
                        </Typography>
                        <DrcCheckbox
                            style={{ margin: '10px' }}
                            label={'Checkbox'}
                            name="Checkbox"
                            value={props.poolweekFreezeData ? props.poolweekFreezeData.Status !== 'Open' : false}
                            checked={props.poolweekFreezeData ? props.poolweekFreezeData.Status !== 'Open' : false}
                            onChange={() => setShowFreezeDialog(true)}
                            disabled={props.poolweekFreezeData?.Status !== 'Open' || !props.selectedRole.includes('User')}
                        />
                    </div>

                    <DrcDialog
                        title={<DrcTranslate>{'Do you want to freeze Pool Week?'}</DrcTranslate>}
                        open={showFreezeDialog}
                        buttons={
                            <>
                                <DrcButton
                                    onClick={() => {
                                        setShowFreezeDialog(false);
                                    }}
                                >
                                    <DrcTranslate>{'Cancel'}</DrcTranslate>
                                </DrcButton>
                                <DrcButton isPrimary onClick={handleFreezePoolWeek}>
                                    <DrcTranslate>{'Confirm'}</DrcTranslate>
                                </DrcButton>
                            </>
                        }
                    ></DrcDialog>

                    {/* <div className={classes?.optionClass}>
                        <DrcButton
                            onClick={() => {
                                // getReceiptQuantity()
                                props.onFilter();
                            }}
                        >
                            FILTER
                        </DrcButton>
                    </div> */}
                    {/* <div className={classes?.optionClass}>
                        <p>CLEAR ALL</p>
                    </div> */}
                </div>
                <TableContainer style={{ width: '98%' }} component={Paper}>
                    <Table className={classes.root} padding="none">
                        <TableHead className={classes.tableHead}>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell
                                    className={classes.tableHead}
                                    align="center"
                                    style={{
                                        width: '130px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Rubik',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)'
                                    }}
                                >
                                    <DrcTranslate>{'Totals'}</DrcTranslate>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHead}
                                    style={{
                                        padding: '5px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Rubik',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)'
                                    }}
                                    align="center"
                                >
                                    <DrcTranslate>{'On Pricing Pool'}</DrcTranslate>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHead}
                                    style={{
                                        padding: '5px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Rubik',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)'
                                    }}
                                    align="center"
                                >
                                    <DrcTranslate>{'Remaining'}</DrcTranslate>
                                </TableCell>
                                <TableCell
                                    className={classes.tableHead}
                                    style={{
                                        padding: '5px',
                                        fontWeight: 'bold',
                                        fontFamily: 'Rubik',
                                        borderRight: '1px solid rgba(224, 224, 224, 1)'
                                    }}
                                    align="center"
                                >
                                    <DrcTranslate>{'On Settlement Pool'}</DrcTranslate>
                                </TableCell>
                                <TableCell className={classes.tableHead} style={{ padding: '5px 10px', fontWeight: 'bold' }} align="center">
                                    <DrcTranslate>{'Remaining'}</DrcTranslate>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, indx) => (
                                <TableRow key={indx}>
                                    <TableCell
                                        component="th"
                                        scope="row"
                                        style={{ height: '30px', fontWeight: 'bold', fontFamily: 'Rubik', width: '150px' }}
                                        align="center"
                                        className={classes.tableHead}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="center" style={{ height: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row?.Totals}
                                    </TableCell>
                                    <TableCell align="center" style={{ height: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row?.onPricingPool}
                                    </TableCell>
                                    <TableCell align="center" style={{ height: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row?.remainingOnPricingPool}
                                    </TableCell>
                                    <TableCell align="center" style={{ height: '30px', borderRight: '1px solid rgba(224, 224, 224, 1)' }}>
                                        {row?.onSettlementPool}
                                    </TableCell>
                                    <TableCell align="center">{row?.remainingOnSettlementPool}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </React.Fragment>
    );
}

const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer, rootReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    yearPoolWeeks: masterReducer.yearPoolWeeks,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    selectedSettlementRecord: weeklySettlementStatusReducer.records,
    selectedRole: rootReducer.selectedRole,
    poolweekFreezeData: weeklySettlementStatusReducer.poolweekFreezeData
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    setSelectedPoolWeek: (PoolWeek) => dispatch(setSelectedPoolWeek(PoolWeek)),
    setPoolweekFreezeData: (data) => dispatch(setPoolweekFreezeData(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(PricingPoolCard));
