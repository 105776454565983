import React from 'react';
import { connect } from 'react-redux';
import AccordionPoolDefinition from '../../components/AccordionPoolDefinition';
import SettlementPoolDetail from './SettlementPoolDetail';
import { makeStyles } from '@material-ui/core/styles';
import { designColors } from '../../data/constants';
const useStyles = makeStyles((theme) => ({
    childAccordion: {
        backgroundColor: theme.light.primary,
        [theme.darkTheme]: {
            backgroundColor: `${theme.dark.primary} !important`
        },
        margin: '0px',
        '& .MuiAccordionSummary-root': {
            height: 40
        }
    }
}));
function PricingPoolDetail(props) {
    const classes = useStyles();
    return (props.pricingPoolsMap[props.id] || []).map((pricingPoolKey, indx) => (
        <AccordionPoolDefinition
            title={`${pricingPoolKey.Identification.PoolID}, ${pricingPoolKey.Identification.Description}`}
            poolStatus="Recommended"
            className={classes.childAccordion}
            key={indx}
            titleStyle={{ color: designColors.blue }}
            selectedKey={pricingPoolKey}
            data={pricingPoolKey}
        >
            <SettlementPoolDetail id={pricingPoolKey.Identification.PoolTableID} />
        </AccordionPoolDefinition>
    ));
}

const mapStateToProps = ({ masterReducer, pricingPoolReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pricingPoolsMap: pricingPoolReducer.pricingPoolsMap
});

export default connect(mapStateToProps, null)(PricingPoolDetail);
