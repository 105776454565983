export const zhCN = {
    Blackberry: '黑莓',
    Blueberry: '蓝莓',
    Raspberry: '树莓',
    Strawberry: '草莓',
    'Grower Settlement': '种植户结算',
    'User Details': '用户明细',
    Admin: '系统管理',
    'Grower Accountant': '种植户账户',
    'Settlement Workbench': '结算工作台',
    'Pricing Pool': '定价池',
    'Settlement Pool': '结算池',
    'Data Setup': '数据配置',
    Home: '主页',
    'Pool Week': '财务周',
    'Item UOM Conversion': '品类UOM转化',
    'Item Shipping Rates': '运输费率',
    'Repackaging SKU Mapping': '重新包装的SKU匹配',
    'Grower Category': '种植户目录',
    'Settlement Rate': '结算率',
    'Settlement Record': '结算记录',
    'Customer Code Category': '客户编号目录',
    'Receipt lines': '收据队列',
    'VAT Rate Maintenance': '增值税率维护',
    'Forex Rate': '外币兑换率',
    'Sales Invoice lines': '销售发票队列',
    'Packaging Deduction': '包装费抵扣',
    'Adjustments (Pooled)': '合并调整项',
    HOMI: 'HOMI',
    PFQ: 'PFQ',
    'Adjustments (Non-Pooled)': '非合并调整项',
    'Grower Financing': '种植户财务',
    Advances: '预付',
    'Packaging Issuance': '包材发行',
    'Packaging Credit': '包材额度',
    'Plant Recovery': '植被回收',
    'Sign Out': '退出登录',
    'Updated Successfully': '更新成功',
    'Added Successfully': '加载成功',
    'Deleted Successfully': '删除成功',
    'An Error Occurred': '发生错误',
    'Duplicate Record': '重复数据',
    Process: '流程',
    'Submit to Approval': '提交审批',
    Reports: '报表',
    'No Data To Show': '无数据可显示',
    Export: '导出',
    'Average Price': '平均价格',
    'Turn Over Rate': '周转率',
    'Average Price & Turn Over Rate': '平均价格&周转率',
    Refresh: '刷新',
    'SETTLEMENT SHARE': '结算额分配',
    'Share %': '分配率',
    Sales: '销售 ',
    Receipts: '收据 ',
    WEIGHT: '重量',
    'Expected vs Actual': '预期 vs 实际',
    'Approved By : ': '审批人 : ',
    'Submitted By : ': '提交人 : ',
    Reject: '拒收',
    Approve: '审批同意',
    'Add Commodity from below': '增加商品',
    'Selected Commodities': '已选商品',
    'To Date': '至（日期）',
    //'Pool Week': '结算周',
    'Goods In Transit': '在途物资',
    'Inventory On-hand': '库存',
    'RECEIPT LINES': '收据队列',
    'From Date': '起始日期',
    'SUMMARY REVIEW': '总结审阅',
    'Sales Invoice Lines': '销售发票队列',
    'Service Deductions': '服务费扣除',
    'Current Release Version:': '当前发布版本：',
    'Config Settings': '配置',
    'Choose Filter': '过滤器',
    'Business Unit': '业务部门',
    Year: '年份',
    'Start Date': '起始日期',
    'End Date': '结束日期',
    'Search Key Date': '搜索关键日期',
    Remaining: '剩余',
    'On Pricing Pool': '在定价中',
    'On Settlement Pool': '在结算中',
    'Preparing download': '准备下载',
    'Your download should start in a few seconds.': '您的下载项将在数秒后开始执行',
    Journals: '日志',
    'View Report': '审阅报表',
    Submit: '提交',
    Cancel: '取消',
    "Receipt's Quantity": '收据数量',
    'Sales Amount': '销售额',
    Reset: '重置',
    Filter: '过滤器',
    Add: '增加',
    Delete: '删除',
    Lookup: '查询',
    'Pool Week Start Date': '财务周起始日',
    'Pool Week End Date': '财务周结束日',
    'Created By': '创建人',
    'Created Date Time': '创建日',
    'Modified By': '修改人',
    'Modified Date Time': '修改日',
    Status: '状态',
    Totals: '总数',
    'Pool Id': '财务周ID',
    'Expected Qty': '预期数量',
    Description: '描述',
    QUANTITY: '数量',
    Variance: '差额',
    "Driscoll's Farm Share%": 's农场分享百分比',
    'Grower Share%': '种植户分享百分比',
    'Not Run': '未运行',
    Processing: '运行中',
    Processed: '已运行',
    'Pending Approval': '等待批准',
    Approved: '已批准',
    Rejected: '已拒绝',
    Closed: '已结束',
    Open: '开放中',
    Pricing: '定价',
    Settlement: '结算',
    'Select Report': '选择报表',
    'Select Pool Week': '选择财务周',
    'Select Commodity': '选择商品',
    'Report Type': '报表类型',
    'Enter Item ID': '输入项目ID',
    'Settlement Currency': '结算货币',
    Version: '版本',
    'Search Grower ID': '搜索种植户ID',
    'Select Estimate Group': '选择分组',
    'Package UOM': '包装UOM',
    'Freight UOM': '运费UOM',
    Commodity: '商品',
    'Repackage Item Id': '包装项目ID',
    'Original Item Id': '原始项目ID',
    'Customer Code': '客户编号',
    'Vendor Code': '供应商编号',
    'Grower Name': '种植户名称',
    'Location Code': '地点编号',
    'Family Entity': '家族实体',
    Geography: '地理地势',
    Application: '应用',
    'Cooler Name': '冷库名称',
    Country: '国家',
    State: '州',
    'Lookup Type': '查找类型',
    'Lookup Code': '查找代码',
    'Repackage Movement': '分装运转',
    'Shipment Details': '运输明细',
    'User Preference': '用户偏好',
    Accounts: '账号',
    'Re-Process': '再运行',
    'Rejected By:': '被（另用户）拒绝:',
    'Settlement Pool ID': '结算池编号',
    'Prepared By': '由（用户）准备',
    'POOL DEFINITIONS': '池定义',
    Select: '选择',
    'Grower Fixed Price': '种植户固定价格',
    'Enter Item ID to Search': '输入项目编号用以查询',
    WeeklySales: '周销售',
    'Inventory Movement': '库存变动',
    AverageSalesPrice: '平均价格',
    TurnOverRateDomestic: '国内库存周转率',
    TurnOverRateImport: '进口库存周转率',
    ItemInventoryOutbound: '库存出库',
    Back: '回退',
    New: '新增',
    'Estimate Group': '评估分组',
    'Tray To Kilogram': '盘数转换为公斤',
    'Item ID': '项目编号',
    Actions: '行动',
    Currency: '货币',
    'Package Rate': '包装费率',
    'Freight Rate': '运输费',
    'Repackage Item Description': '分包项目描述',
    'Original Item Description': '原始项目描述',
    'Contact Email Id': '联系人邮箱',
    Category: '类别',
    Price: '价格',
    'Effective Start Date': '起期',
    'Effective End Date': '止期',
    'Application Full Name': '申请全称',
    'Commission Rate': '佣金率',
    'Share Grower Percent': '种植户分享比列',
    'Share Driscolls Percent': 'Driscolls分享比例',
    'Mid Size Grower Percent': '中型种植户比例',
    'Mid Size Driscolls Percent': '中型Driscolls种植户比例',
    'Start Date Active': '起期生效',
    'End Date Active': '止期生效',
    'Exclude Average Pricing': '去除均价',
    'VAT Percent': '增值税率',
    Meaning: '含义',
    'Opening Balance KG': '开口余留公斤数',
    'Closing Balance KG': '已处理余留公斤数',
    'Sales Quantity': '销售数量',
    'Receipt Quantity': '收货数量',
    'Sales Order Sold': '已售订单',
    'Include Quality': '包含质量',
    'Damage Loss': '报损',
    Remark: '备注',
    'Loading data': '加载数据',
    'Import Cost': '进口成本',
    'Shipment Number': '运输编号',
    'DOC User': 'DOC用户',
    'DOC Approver': 'DOC审批人',
    Approver: '审批人',
    'Account No': '账户编号',
    'Inactive Flag': '未激活标志',
    'Select Item ID': '选择项目编号',
    ADJUSTMENTS: '调整',
    AMOUNT: '金额',
    'Adjustment Amount': '调整金额',
    'Receipt Id': '收据编号',
    Line: '行',
    Date: '日期',
    'Vendor Name Chinese': '供应方中文名',
    'Vendor Name': '供应方名称',
    'Item Number': '项目编号',
    Quantity: '数量',
    'Cost Center': '成本中心',
    Variety: '品种',
    'Qty Ordered': '已定数量',
    Memo: '备注',
    Location: '地址',
    'QC Score': '质量打分',
    'Invoice ID': '发票编号',
    'Line Num': '行数',
    'Invoice Date': '发票日期',
    'Item Description': '项目描述',
    'Pickup Location': '取货地址',
    'Invent Trans ID': '库存运输编号',
    'Invoice Amount': '发票金额',
    'Customer Name': '客户名称',
    'Customer Geography': '客户领域',
    'Item Rate': '项目费率',
    'Sales ID': '销售编号',
    Item: '项目',
    'Preferred Vendor': '偏好供应方',
    'On Hand': '持有',
    'Transaction Key': '交易密钥',
    'Document Number': '文件编号',
    'Ship To': '运输至',
    'Quantity Memo': '数量备注',
    'Date Shipped': '运输日期',
    'Date Received': '收货日期',
    'Ship From Location': '运输自',
    'Last Modified Date': '最新编辑日期',
    'Item Id': '项目编号',
    Account: '账目',
    Amount: '金额',
    'Berry Type': '莓果类型',
    'Transaction Number': '交易编号',
    Type: '类型',
    Vendor: '供应方',
    'Grower ID': '种植户编号',
    'Payment Date': '付款日期',
    'Total Kilos Received': '总收货公斤',
    'Package Deduction': '包装费扣除',
    'Gross Pool Amount': '毛金额数量',
    'Commission Net Pool Return': '净池回报佣金',
    'Freight Deduction': '运费扣除',
    'Commission Net Fruit Return Price Per Kg': '每公斤水果净价格佣金',
    'Average Price Per Kg': '每公斤平均价格',
    'VAT Net Fruit Return Price Per Kg': '每公斤水果净增值税',
    'UOM Conversion Rate': 'UOM转化率',
    'VAT Net Pool Return': '增值税净池回报',
    'Average Price Per Tray': '平均价/每盘',
    'NetFruit Return Price Per Kg': '净回报/每公斤',
    'Total Trays Received': '总收货盘数',
    'VAT Share Grower': '分成种植户增值税',
    'Net Fruit Before DAP share': 'DAP分成前净价',
    'DAP Share': 'DAP分成',
    'Submitted By': '提交人',
    'Approved By': '审批人',
    'Settlement Unit Price With Repack': '含分包结算单价',
    'Prelim WODamage Average Settlement Price': '除报损的初步结算均价',
    'Prelim Average Settlement Price': '初步结算均价',
    'Abnormal Sales Amount': '异常销售额',
    'Settlement Unit Price': '结算单价',
    'Repackaging Sales Qty': '分包销售数量',
    'Final True Up Average Settlement Price': '最终调整结算均价',
    'Sold Qty': '已售数量',
    'Receipt Qty': '已收数量',
    'Final Average Settlement Price': '最终结算均价',
    'Sample Damage Sales Qty': '报损样品销售数量',
    'Closing Qty': '收盘数量',
    'Normal Sales Unit Price': '标准销售单价',
    'Turn Over Rate WK2': 'WK2周转率',
    'Opening Qty': '开口数量',
    'Turn Over Rate Wk1': 'WK1周转率',
    'Turn Over Rate WK3': 'WK3周转率',
    'Normal Sales Qty': '正常销售数量',
    'Abnormal Sales Qty': '异常销售数量',
    'In Transit': '运输中',
    'Normal Sales Amount': '正常销售金额',
    'Quality Issue Sales Qty': '质量问题销售数量',
    'Abnormal Sales Unit Price': '异常销售单价',
    'D Shared Sales Qty': 'D分享销售数量',
    'D Shared Sales Amount': 'D分享销售金额',
    'D Shared Sales Unit Unit Price': 'D分享销售单价',
    'Service Deduction Amount': '服务扣除金额',
    'Variance Normal Settlement Unit Price': '正常结算单价差异',
    'Variance Normal Settlement Unit PriceWith Repack': '正常结算单价差异（含分包）',
    'Disposal Total': '废弃总数',
    'Last Week On Hand': '上周持有',
    'Last Week In Transit': '上周运输中',
    DisposalQty: '废弃数量',
    'Prelim WO Damage Average Settlement Price': '初步结算均价（不含报损）',
    Spec: '特殊',
    SPEC: '特殊',
    'Turn Over Rate WK1': 'WK1周转率',
    ReceiptQty: '收货数量',
    'Disposal Qty': '废弃数量',
    'Sold Qty WK1': 'WK1已售数量',
    'Prelim Average Settlement Price WK1': '初步结算均价WK1',
    'Sold Qty WK2': 'WK2已售数量',
    'Prelim Average Settlement Price WK2': '初步结算均价WK2',
    'Sold Qty WK3': 'WK3已售数量',
    'Prelim Average Settlement Price WK3': '初步结算均价WK3',
    Balance: '差异',
    Abbreviation: '缩写',
    'Pkg Net Pool Return Price Per KG': '净池回报价格每公斤',
    'Pkg Net Fruit Return Price Per Kg': '结算净价/公斤',
    'Submitted Date': '提交日期',
    'Approved Date': '审批日期',
    'Repackage Item ID': '分包项目编号',
    'Original Item ID': '原始项目编号',
    'Short Name': '简短名称',
    'Repackage Movement ID': '分包变动编号',
    'Enabled Flag': '标识',
    'Custom Clearance Fee': '海关清关费',
    Trays: '盘',
    'Container Number': '集装箱号码',
    'Freight Custom To FDC': '海关至FDC运输',
    'Repackaging Charge': '分包费',
    'Pool Week Average Price/Tray': '池均价/盘',
    'Pool Week Average Price/Kilo': '池均价/公斤',
    'Total PKG Ded': '总扣除公斤',
    'Total Freight Deduction': '总运费扣除',
    'Net Fruit Return Price/Kilo (Avg)': '结算净价/公斤',
    'Net Fruit before DAP Share': '包括DAP佣金的水果净额',
    'Net Pool Return': '净池回报',
    'Pool Return': '池回报',
    'VAT Part Share-Grower': '增值税部分-种植户',
    'Enter Receipt Id': '数据收据编号',
    Apply: '申请',
    'Add Pricing Pool': '新增价格池',
    'Edit Pricing Pool': '编辑价格池',
    'Grower Settlement Report': '种植户结算报表',
    'Receipt By Cost Center': '成本中心收据',
    'Duplicate entry': '重复提交',
    'Driscolls Xishuangbanna AP': 'Driscolls西双版纳应付',
    'Driscolls Qujing AP': 'Driscolls曲靖应付',
    'Share Percentage': '佣金比例',
    'Share Yunnan': '云南份额',
    'Share Seven knot': '柒瑞结农业份额',
    'Driscolls Seven knot AP': '柒瑞结农业应付',
    'Share Seven knot KY': '柒瑞结农业份额',
    'Seven knot KY AP': '柒瑞结农业应付',
    'Pkg Net Return To Grower': '种植户收入（每公斤）',
    'Share Xishuangbanna': '西双版纳份额',
    'Driscolls Yunnan AP': 'Driscolls云南应付',
    'Share Grower Allowance': '种植户补助费用',
    'Share Qujing': '曲靖份额',
    'Net Return To Grower': '种植户收入',
    'Packaging Issurance': '领用包材',
    'Share Grower Deduction': '种植户扣除份额',
    'All Deduct Net Return To Grower': '种植户回报（去除扣除部分）',
    'Summary Domestic': '国内总计',
    'Weekly Sales Report': '周销售报表',
    'Average Sales Price': '平均销售价',
    'Turn Over Rate Domestic': '国内周转率',
    'Turn Over Rate Import': '进口周转率',
    'Item Inventory Outbound': '库存出库',
    'Aged Inventory': '过期库存',
    'Amount Adjustment': '金额调整',
    'End Date should be greater than start date': '结束日期必须晚于开始日期',
    'Start date and End date overlap': '开始日期与结束日期不可重叠',
    'From Currency': '转化自（币种）',
    'To Currency': '转化成（币种）',
    'Exchange Rate': '汇率',
    'Rate Date': '汇率日期',
    Language: '语言',
    'User Role': '用户角色',
    'User Preferences': '用户偏好',
    Save: '保存',
    'Grower Percentage': '种植户比例',
    'Preliminary SH Booking': '上海公司初步结算记账',
    'Final SH Booking': '上海公司最终结算记账',
    'Preliminary Grower Booking': '种植户初步结算记账',
    'Final Grower Booking': '种植户最终结算记账',
    'Preliminary Family Entity Booking': '家族实体初步结算记账',
    'Final Family Entity Booking': '家族实体最终结算记账',
    'Receipt By Grower': '种植户收据',
    'Grower Own Percentage': '种植户比例',
    'Family Entity Percentage': '家族实体比例',
    'DMSH Share': 'DMSH佣金',
    'Family Entity Entry Booking': '家族公司记账',
    'Shanghai Entry Booking - Preliminary': '上海公司记账-初始结算',
    'Shanghai Entry Booking - Final': '上海公司记账-最终结算',
    Code: '代号',
    'Quantity Kg': '数量（公斤）',
    'CC Allocation': '成本中心分配',
    'CC Allocation Entry Booking - Preliminary': '成本中心分配记账-初步结算',
    'JE Code': '日志代码',
    'JE Cost Center': '日志成本中心',
    'JE - Shanghai': 'JE - 上海',
    'Grower Amount': '种植户金额',
    'Driscolls Amount': "Driscoll's金额",
    Total: '总计',
    'Grower Own': '种植户持有',
    'Account Number': '账目编号',
    Debit: '贷',
    Credit: '借',
    'Line Memo': '备注',
    'Account Name': '账目名称',
    Subsidiary: '子公司',
    'JE - Sevenknot': 'JE - 柒瑞结',
    'JE - Qujing': 'JE - 曲靖',
    'JE - Xishuangbanna': 'JE - 西双版纳',
    'JE - Yunnan': 'JE - 云南',
    'JE Shanghai': 'JE上海',
    Publish: '发行',
    Published: '已发行',
    Qty: '数量',
    Grower: '种植户',
    'Last Week Repack SKU Transfer Back': '上周运回的转包SKU',
    'Repack SKU Transfer Back': '运回的转包SKU',
    Module: '单元',
    Breeder: '繁殖',
    'CC Percent': 'CC比例',
    'DSA Service Fee': 'DSA 服务费',
    'Parent Vendor Code': '母供应商公司编码',
    'Import Settlement Rate': '进口结算率',
    'Import Grower Settlement': '进口种植户结算',
    'Add Grower': '新增种植户',
    'Update Items': '更新项目',
    'Pool ID': '净池编号',
    'Pool Status': '净池状态',
    Region: '区域',
    'Destination Countries': '目的国',
    'Producing Area': '生产区域',
    'Import Receipt By Cost Center': '进口成本中心收据',
    'Import Settlement Report': '进口结算报表',
    'Transfer Rate': '周转率',
    'Pool Week Average Price Per Kg': '本周单价/公斤',
    'Domestic Freight': '运输物流',
    'Settled Average Price Per Tray': '平均价格/每盘',
    'Unit Return Per Kg': '每公斤回报',
    'Trans Net Fruit Return Price Per Kg': '每公斤回报转化',
    'DSA Service Fee Cost': 'DSA 服务费',
    'Pooling Amount': '佣金计算基础',
    'Advance Rate': '预付费率',
    'Total Costs Deducted': '扣减费用合计',
    'Direct Sales Return': '池净回报',
    'Advance Paid': '已付预付费',
    'Summary CNY': '总人民币金额',
    'Summary Peru': '总外币金额（秘鲁）',
    'Summary Chile': '总外币金额（智利）',
    'Settled Average Price/Tray': '本周结算销售单价/盘',
    'Net Fruit Return Price Per/Kilo (Avg)': '结算净价/公斤',
    'Import Custom Clearance Fee': '进口关税',
    'Freight From Custom To FDC': '运费（客户至分拨中心）',
    Others: '其他',
    'Unit Return/Kg': '结算净价/公斤',
    'Grower Location': '种植户地址',
    'Sub Grower': '子种植户',
    'Remaining Payment': '剩余付款',
    'Journal Account': '日志账号',
    'PDF Domestic Pre': 'PDF国内预结算',
    'PDF Domestic Final': 'PDF国内总结算',
    'PDF Import Pre': 'PDF进口预结算',
    'PDF Import Final': 'PDF进口总结算',
    'Do you want to freeze Pool Week?': '确认后，GIT/On-hand数据在本周将不再变动',
    Confirm: '确认',
    'Freeze GIT/On-hand Reports': '锁定GIT/On-hand',
    'PoolWeek Freezed Successfully': '成功锁定GIT/On-hand',
    'Publish Domestic': '发布国产结算',
    'Publish Import': '发布进口结算',
    'Partially Published': '部分发布',
    Failed: '失败',
    'Exclude Normal Sales Amt': '正常销售金额除外',
    'Exclude Normal Sales Qty': '正常销售数量除外',
    'Exclude Settlement Price Amt': '结算金额除外',
    'Exclude Settlement Price Qty': '结算金额数量除外',
    'No Files Found': '未找到文件',
    'Downloaded Successfully': '成功下载',
    'Download Reports': '下载报表',
    'Import Data': '导入数据',
    'Only *.csv file should be uploaded for acceptance.': '仅支持CSV格式的文件导入',
    'Choose a file ': '选择文件',
    'or drag it here.': '或者拖入文件',
    'Please Enter a Valid File.': '请选择有效文件',
    'Search Reports': '查询报表',
    'Preparing Files...': '文件准备中',
    'Files are preparing to download.': '文件正在准备下载中'
};
