import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { makeStyles, useTheme, Box } from '@material-ui/core';
import Form from 'react-validation/build/form';
import { DrcDialog, DrcButton, DrcTranslate, DrcChipsMultiSelect } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { userBusinessUnit } from '../../../utils/helper';
import DataTable from '../../../components/DataTable';
import APIEndPoints from '../../../services/api';
import { ReactComponent as NoteAdd } from '../../../Images/SVG/Add_Enabled.svg';
import { showToast, showLoadingScreenAction, hideLoadingScreenAction } from '../../../actions/actions';
const useStyles = makeStyles((theme) => ({}));
const GrowersTab = (props) => {
    const [editPoolTableGrower, setEditPoolTableGrower] = useState({});
    const [growersData, setGrowersData] = useState([]);
    const [showAddDialog, setShowAddDialog] = useState(false);
    const initialAddPoolTableGrower = {
        PoolID: props.selectedKey.Identification.PoolID,
        DataareaID: props.selectedKey.Identification.DataareaID
    };
    useEffect(() => {
        setupData();
        loadData();
    }, []);
    const setupData = () => {
        setEditPoolTableGrower({ ...initialAddPoolTableGrower });
    };
    const showError = (err, msg) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{msg || 'An Error Occurred'}</DrcTranslate>, false);
        console.error('error : ', err);
    };
    const updateGrowers = (growersArray) => {
        var updatedGrowersData = [];
        let selectedGrowers = (props?.selectedKey?.Grower?.GrowerSelected || '').split(',') || [];
        growersArray.forEach((grower) => {
            updatedGrowersData.push({ ...grower, selected: selectedGrowers.includes('' + grower.VendorCode) });
        });
        return updatedGrowersData;
    };
    const loadData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                { name: 'GrowerList', url: APIEndPoints.GET_GROWER_LIST('', '', userBusinessUnit(), props.userLang, ''), method: 'GET', options: {} },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            var growersData = updateGrowers(data.converted.Data);
            setGrowersData(growersData);
            props.hideLoadingScreenAction();
        } catch (error) {
            showError(error);
        }
    };
    const PostPoolTableGrower = async () => {
        props.showLoadingScreenAction('Posting data');
        try {
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            var data = await Call(
                {
                    name: 'POST_POOLTABLEGROWER',
                    url: APIEndPoints.PUTPOST_POOLTABLEGROWER(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolTableGrower: growersData
                            .filter((grower) => {
                                return grower.selected;
                            })
                            .map((grower) => {
                                return { VendorAccountNum: grower.VendorCode, Description: grower.GrowerName };
                            }),
                        PoolID: editPoolTableGrower['PoolID'],
                        DataareaID: editPoolTableGrower['DataareaID'],
                        DateTime: new Date().toISOString(),
                        RequestedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                setShowAddDialog(false);
                setEditPoolTableGrower({ ...initialAddPoolTableGrower });
                return showError(data.errors[0]);
            } else if (data.display.Status === 409) {
                setShowAddDialog(false);
                setEditPoolTableGrower({ ...initialAddPoolTableGrower });
                return showError((data.display.Message, 'Duplicate Record'));
            } else {
                props.hideLoadingScreenAction();
                setShowAddDialog(false);
                setEditPoolTableGrower({ ...initialAddPoolTableGrower });
                props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            showError(error);
        }
        props.hideLoadingScreenAction();
    };
    const handleSelectChange = (grower, isSelected) => {
        let newData = growersData.reduce((arr, data) => {
            if (grower.key === data.VendorCode) {
                data.selected = isSelected;
            }
            arr.push(data);
            return arr;
        }, []);
        setGrowersData(newData);
    };
    return (
        <>
            <Box sx={{ margin: 'auto', marginRight: 'inherit' }}>
                <NoteAdd onClick={() => setShowAddDialog(true)} style={{ width: '50px', height: '20px', marginRight: '-10px', cursor: 'pointer' }} />
            </Box>
            <DataTable
                total={
                    growersData.filter((grower) => {
                        return grower.selected;
                    }).length
                }
                data={
                    growersData.filter((grower) => {
                        return grower.selected;
                    }) || []
                }
                columns={[
                    { key: 'VendorCode', name: 'Vendor Code' },
                    { key: 'GrowerName', name: 'Grower Name' }
                ]}
            />
            <DrcDialog
                title={<DrcTranslate>{'Add Grower'}</DrcTranslate>}
                open={showAddDialog}
                buttons={
                    <>
                        <DrcButton
                            isPrimary
                            onClick={() => {
                                PostPoolTableGrower();
                            }}
                        >
                            <DrcTranslate>{'Save'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            onClick={() => {
                                setEditPoolTableGrower({ ...initialAddPoolTableGrower });
                                setShowAddDialog(!showAddDialog);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <Form>
                    <DrcChipsMultiSelect
                        chips={growersData
                            .filter((grower) => grower.selected)
                            .map((grower) => {
                                return { key: grower.VendorCode, value: grower.VendorCode, label: grower.GrowerName };
                            })}
                        options={growersData.map((grower) => {
                            return { key: grower.VendorCode, value: grower.VendorCode, label: grower.GrowerName };
                        })}
                        onSelectChange={(grower) => handleSelectChange(grower, true)}
                        onChipDelete={(grower) => handleSelectChange(grower, false)}
                    />
                </Form>
            </DrcDialog>
        </>
    );
};

const mapStateToProps = ({ masterReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang
});

export default connect(mapStateToProps, { showToast, showLoadingScreenAction, hideLoadingScreenAction })(withOktaAuth(GrowersTab));
