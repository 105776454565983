import React from 'react';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { DrcButton, DrcDialog, DrcTranslate, DrcDatePicker, DrcSelect, DrcTooltip } from '@driscollsinc/driscolls-react-components';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { Divider } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import Form from 'react-validation/build/form';
import APIEndPoints from '../../../services/api';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import DrcWrapper from '../../../components/DrcWrapper';
import Stepper from '../../../components/Stepper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import GGSFormInput from '../../../components/GGSFormInput';
import { displayErrorMessage, getAppliedFilters, makeColumns, makeOptions, userBusinessUnit } from '../../../utils/helper';
import { alphaNumeric, decimal, maxValue } from '../../../utils/validations';
import { growerPercentageColumns, convertDateToIso, LOOKUP_FIELDS, commodityOptions } from '../../../data/constants';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';
import { format, isValid } from 'date-fns';
import MasterDataUtilities from '../../../data/MasterDataUtilities';

const pageTitle = 'Grower Percentage';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    errorClass: {
        color: 'red',
        fontSize: '10px',
        marginTop: '-2px'
    }
});

class GrowerPercentage extends React.Component {
    state = {
        data: [],
        columns: ['LookupCode', 'Meaning', 'Description', 'EnabledFlag'],
        showAddNew: false,
        pageSize: 10,
        pageNum: 0,
        editingRow: {},
        newRecord: {},
        invalidFields: [],
        savebuttonDisabled: true
    };
    uniqueKeyField = 'GrowerSettlementRateID';
    excludingFields = ['Actions', 'GrowerSettlementRateID'];
    validationFields = {
        EffectiveStartDate: {
            key: 'EffectiveStartDate',
            validations: [alphaNumeric],
            customProps: {}
        },
        EffectiveEndDate: {
            key: 'EffectiveEndDate',
            validations: [alphaNumeric],
            customProps: {}
        },
        GrowerOwnPercentage: {
            key: 'GrowerOwnPercentage',
            validations: [decimal, maxValue],
            customProps: { maxvalue: 100 }
        },
        FamilyEntityPercentage: {
            key: 'FamilyEntityPercentage',
            validations: [decimal, maxValue],
            customProps: { maxvalue: 100 }
        }
    };

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    componentDidUpdate(_prevProps, prevState) {
        if (
            this.state.newRecord.EffectiveStartDate !== prevState.newRecord.EffectiveStartDate ||
            this.state.newRecord.EffectiveEndDate !== prevState.newRecord.EffectiveEndDate ||
            this.state.editingRow.EffectiveStartDate !== prevState.editingRow.EffectiveStartDate ||
            this.state.editingRow.EffectiveEndDate !== prevState.editingRow.EffectiveEndDate
        )
            this.checkDateValidation();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    checkDateValidation() {
        if (
            (new Date(this.state.newRecord.EffectiveStartDate) > new Date(this.state.newRecord.EffectiveEndDate) ||
                new Date(this.state.editingRow.EffectiveStartDate) > new Date(this.state.editingRow.EffectiveEndDate)) &&
            (this.state.editingRow.EffectiveEndDate || this.state.newRecord.EffectiveEndDate)
        ) {
            if (!this.state.dateError)
                return this.setState({
                    dateError: <DrcTranslate>{'End Date should be greater than start date'}</DrcTranslate>,
                    invalidFields: ['EffectiveEndDate']
                });
        } else {
            if (this.state.dateError) return this.setState({ dateError: '', invalidFields: [] });
        }
    }

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'GrowerPercentage',
                    url: APIEndPoints.GET_GROWER_PERCENTAGE(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        getAppliedFilters(this.props.filters),
                        userBusinessUnit()
                    ),
                    method: 'GET',
                    options: {}
                    // fakeData: GrowerPercentageJson
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }

            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['EffectiveStartDate', 'EffectiveEndDate', 'Category', 'Commodity', 'GrowerOwnPercentage'],
                  [],
                  growerPercentageColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };

    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    deleteData = (_rowData) => null;

    editData = (_event, data, _index) => {
        this.setState({
            editingRow: {
                ...data,
                [data[this.uniqueKeyField]]: true,
                EffectiveStartDate: this.convertDate(data.getPreviousData().EffectiveStartDate),
                EffectiveEndDate: data['EffectiveEndDate'] ? this.convertDate(data.getPreviousData().EffectiveEndDate) : null
            },
            invalidFields: []
        });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };

    cannotEdit = (_row) =>  !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    cannotDelete = (_row) =>  !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'GrowerPercentage',
                    url: APIEndPoints.PUT_GROWER_PERCENTAGE(),
                    method: 'PUT',
                    options: {},
                    data: {
                        GrowerSettlementRateID: this.state.editingRow['GrowerSettlementRateID'],
                        Category: this.state.editingRow['Category'],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        EffectiveStartDate: this.state.editingRow['EffectiveStartDate'].replace(/\//g, '-'),
                        EffectiveEndDate: this.state.editingRow['EffectiveEndDate']
                            ? this.state.editingRow['EffectiveEndDate'].replace(/\//g, '-')
                            : '',
                        Commodity: this.state.editingRow['Commodity'],
                        GrowerOwnPercentage: this.state.editingRow['GrowerOwnPercentage'],
                        FamilyEntityPercentage: '' + (100 - parseFloat(this.state.editingRow['GrowerOwnPercentage'])),
                        CreatedBy: this.state.editingRow['CreatedBy'],
                        CreatedDateTime: this.state.editingRow['CreatedDateTime'],
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ editingRow: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else if ((data.display.Message || '').includes('Duplicate entry')) {
                this.setState({ editingRow: {} });
                return this.showError(data.display.Message, 'Duplicate entry');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'GrowerPercentage',
                    url: APIEndPoints.PUT_GROWER_PERCENTAGE(),
                    method: 'POST',
                    options: {},
                    data: {
                        BusinessUnit: userBusinessUnit(),
                        Category: this.state.newRecord['Category'].value,
                        Commodity: this.state.newRecord['Commodity'].value,
                        GrowerOwnPercentage: this.state.newRecord['GrowerOwnPercentage'],
                        FamilyEntityPercentage: '' + (100 - parseFloat(this.state.newRecord['GrowerOwnPercentage'])),
                        EffectiveStartDate: convertDateToIso(this.state.newRecord['EffectiveStartDate']),
                        EffectiveEndDate: this.state.newRecord['EffectiveEndDate'] ? convertDateToIso(this.state.newRecord['EffectiveEndDate']) : '',
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else if ((data.display.Message || '').includes('start date and end date overlap')) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Start date and End date overlap');
            } else if ((data.display.Message || '').includes('Duplicate entry')) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.display.Message, 'Duplicate entry');
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     * @memberof CustomerCodeCategory
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };
    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key] ?? {};
        switch (key) {
            case 'EffectiveStartDate':
                return this.dateCellEditor(key, false, false);
            case 'EffectiveEndDate':
                return this.dateCellEditor(key, true, true);
            case 'GrowerOwnPercentage':
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
            case 'Commodity':
                return this.selectCellEditor(key, col, others, commodityOptions);
            case 'Category':
                return this.selectCellEditor(key, col, others, makeOptions(this.props.categoryLookup, 'LookupCode', 'LookupCode'));
        }
    };
    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };
    convertDate = (date) => {
        return isValid(date) ? format(date, 'yyyy/MM/dd') : date;
    };
    dateCellEditor = (key, clearable, errorMsg) => {
        return (
            <>
                <DrcDatePicker
                    clearable={clearable}
                    emptyLabel=" "
                    InputProps={{ readOnly: true }}
                    style={{ marginTop: `${errorMsg && this.state.dateError ? '19px' : '0'}` }}
                    selectedDate={this.state.editingRow[key] ? new Date(this.state.editingRow[key]) : null}
                    onChange={(e) => {
                        this.setState((prevState) => ({ editingRow: { ...prevState.editingRow, [key]: this.convertDate(e) } }));
                    }}
                />
                {errorMsg && this.state.dateError && (
                    <DrcTooltip tipText={this.state.dateError}>
                        <p style={{ color: 'red', fontSize: '10px', marginTop: '-5px', textOverflow: 'ellipsis' }}>{this.state.dateError || ''}</p>
                    </DrcTooltip>
                )}
            </>
        );
    };

    selectCellEditor = (key, _col, _others, options) => {
        return (
            <DrcSelect
                options={options}
                label="Select"
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={options.find((itm) => itm.label === this.state.editingRow[key])}
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        // position: 'fixed !important',
                        // width: '15rem',
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['GrowerCategory', 'Commodity']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                        disabled={!(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'))}
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton>
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'100px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => {
                            return this.getRowEditor(
                                key,
                                col,
                                others,
                                this.validationFields[key] ? this.validationFields[key].validations : [],
                                this.validationFields[key] ? this.validationFields[key].customProps : {}
                            );
                        }}
                    />
                </div>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord['GrowerOwnPercentage'] &&
                                        this.state.newRecord['Category'] &&
                                        this.state.newRecord['EffectiveStartDate']
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <DrcSelect
                            label="Category"
                            value={this.state.newRecord['Category']}
                            required
                            options={makeOptions(this.props.categoryLookup, 'LookupCode', 'LookupCode')}
                            validations={this.validationFields['Category'] ? this.validationFields['Category'].validations : []}
                            {...(this.validationFields['Category'] ? this.validationFields['Category'].customProps : {})}
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Category: option } }))}
                        />
                        <DrcSelect
                            label="Commodity"
                            value={this.state.newRecord['Commodity']}
                            required
                            options={commodityOptions}
                            validations={this.validationFields['Commodity'] ? this.validationFields['Commodity'].validations : []}
                            {...(this.validationFields['Commodity'] ? this.validationFields['Commodity'].customProps : {})}
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Commodity: option } }))}
                        />
                        <GGSFormInput
                            label={'Grower Own Percentage'}
                            required
                            validations={this.validationFields['GrowerOwnPercentage'] ? this.validationFields['GrowerOwnPercentage'].validations : []}
                            {...(this.validationFields['GrowerOwnPercentage'] ? this.validationFields['GrowerOwnPercentage'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, GrowerOwnPercentage: e.target.value } }))
                            }
                            value={this.state.newRecord['GrowerOwnPercentage']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'GrowerOwnPercentage')}
                        />

                        <DrcDatePicker
                            label={'Effective Start Date'}
                            selectedDate={this.state.newRecord['EffectiveStartDate'] || null}
                            required
                            InputProps={{ readOnly: true }}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, EffectiveStartDate: e } }))}
                        />
                        <DrcDatePicker
                            label={'Effective End Date'}
                            selectedDate={this.state.newRecord['EffectiveEndDate'] || null}
                            InputProps={{ readOnly: true }}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, EffectiveEndDate: e } }))}
                        />
                        <p className={classes.errorClass}>{this.state.dateError || ''}</p>
                    </Form>
                </DrcDialog>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    categoryLookup: masterReducer[LOOKUP_FIELDS.GrowerCategoryLookUp],
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(GrowerPercentage))));
