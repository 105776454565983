import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { connect } from 'react-redux';

const useStyles = makeStyles((theme) => ({
    main: {
        borderRadius: '8px',
        marginTop: 8,
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['600']} !important`
        }
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    content: {
        margin: 8,
        fontWeight: 500,
        display: 'flex',
        textAlign: 'left'
    },
    cellContent: {
        width: '100px'
    }
}));

function PoolDefinitions(props) {
    const classes = useStyles();
    return (
        // <DrcMain className={classes.main}>
        <>
            <Typography style={{ color: '#B9B9B9', fontWeight: 'bold' }}>
                <DrcTranslate>{'POOL DEFINITIONS'}</DrcTranslate>
            </Typography>
            {/* <Divider /> */}
            <div className={classes.container}>
                <div>
                    <div className={classes.content}>
                        <Typography variant="body2" className={classes.cellContent}>
                            <DrcTranslate>{'Pricing'}</DrcTranslate> :
                        </Typography>
                        <Typography variant="body2">{`${props.selectedSettlementRecord.PricingPoolID} , ${props.selectedSettlementRecord.PricingPoolDescription}`}</Typography>
                    </div>
                    <div className={classes.content}>
                        <Typography variant="body2" className={classes.cellContent}>
                            <DrcTranslate>{'From Date'}</DrcTranslate>
                        </Typography>
                        <Typography variant="body2">{props.selectedPoolWeek.PoolWeekStartDate}</Typography>
                    </div>
                </div>
                <div>
                    <div className={classes.content}>
                        <Typography variant="body2" className={classes.cellContent}>
                            <DrcTranslate>{'Settlement'}</DrcTranslate>
                        </Typography>
                        <Typography variant="body2">
                            {`${props.selectedSettlementRecord.PoolID} , ${props.selectedSettlementRecord.Description}`}{' '}
                        </Typography>
                    </div>
                    <div className={classes.content}>
                        <Typography variant="body2" className={classes.cellContent}>
                            <DrcTranslate>{'To Date'}</DrcTranslate>
                        </Typography>
                        <Typography variant="body2">{props.selectedPoolWeek.PoolWeekEndDate}</Typography>
                    </div>
                </div>
            </div>
        </>
        // </DrcMain>
    );
}
const mapStateToProps = ({ masterReducer, weeklySettlementStatusReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek,
    selectedSettlementRecord: weeklySettlementStatusReducer.selectedSettlementRecord
});
export default connect(mapStateToProps, null)(PoolDefinitions);
