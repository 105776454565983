import React, { Component } from 'react';
import { Divider } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles } from '@material-ui/core/styles';
import { DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, makeColumns } from '../../../utils/helper';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { conversionRateDsColumns } from '../../../data/constants';
import NoData from '../../../components/NoData';
import { format } from 'date-fns';

const pageTitle = 'Forex Rate';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    }
});

class ConversionRate extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null
    };

    excludingFields = ['Id', 'CreatedBy', 'ModifiedDateTime', 'ModifiedBy', 'CreatedDateTime'];
    readOnly = true;
    uniqueKeyField = 'Id';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    getRateDate() {
        const currentPoolWeekDate = this.props.currentPoolWeek[0].PoolWeekStartDate; // 2022-02-27
        const day = currentPoolWeekDate.slice(8, 10);
        const month = currentPoolWeekDate.slice(5, 7) - 1;
        const year = currentPoolWeekDate.slice(0, 4);
        const rateDate = new Date(year, month, day - 2);
        // using diretly new Date(currentPoolweekDate) will change the date in US time zone.
        return format(rateDate, 'yyyy/MM/dd').replace(/\//g, '-');
    }

    updateTableData = (response, hasFrozenColumns = false) => {
        let data = response?.display?.Data || [];
        let columns = data.length ? makeColumns(Object.keys(data[0]), this.excludingFields, null, false, [], [], conversionRateDsColumns) : [];
        this.setState({
            data,
            //totalCount: response.raw.totalCount,
            totalCount: response?.display.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            var data = await Call(
                {
                    name: 'ConversionRate',
                    url: APIEndPoints.GET_CONVERSION_RATE('CNYUSD', this.getRateDate()),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            this.setState({ saveButtonDisabled: true });
        } else {
            this.setState({ saveButtonDisabled: false });
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.tableContainer}>
                    {!!this.state.data.length ? (
                        <PrimeReactTable
                            value={this.state.data}
                            columns={this.state.columns}
                            uniqueKeyField={this.uniqueKeyField}
                            editingRows={this.state.editingRow}
                            tableSelectionMode="checkbox"
                            paginator={this.state.data.length > 0}
                            currentPage={this.state.pageNum}
                            pageSize={this.state.pageSize}
                            onPage={this.onPage}
                            totalRecords={this.state.totalCount}
                        />
                    ) : (
                        <NoData />
                    )}
                </div>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, rootReducer }) => ({
    currentPoolWeek: masterReducer.currentPoolWeek,
    isMasterDataInitialized: masterReducer.isInitialized,
    pageTitle: rootReducer.pageTitle
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withStyles(styles)(ConversionRate)));
