import React from 'react';
import { DrcInput, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

const useStyles = makeStyles(() => ({
    root: {
        '& .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense ': { height: 'auto' }
    }
}));

const IdentificationTab = (props) => {
    const rootClass = useStyles();
    const data = (props.data || {}).Identification || {};
    return (
        <>
            <Box
                sx={{
                    width: '49%',
                    margin: 'auto'
                }}
            >
                <Typography>
                    <DrcTranslate>{'Pool ID'}</DrcTranslate>
                </Typography>
                <DrcInput
                    label=""
                    name="pool_id"
                    disabled
                    value={data.PoolID}
                    onChange={() => null}
                    inputProps={{ maxLength: 50, readOnly: true }}
                    required={false}
                />
            </Box>
            <Box
                sx={{
                    width: '49%',
                    margin: 'auto'
                }}
            >
                <Typography>
                    <DrcTranslate>{'Pool Status'}</DrcTranslate>
                </Typography>
                <DrcInput
                    label=""
                    name="pool_status"
                    value={data.PoolStatus}
                    disabled
                    onChange={() => null}
                    inputProps={{ maxLength: 50, readOnly: true }}
                    required={false}
                />
            </Box>
            <Box
                sx={{
                    width: '99%',
                    margin: 'auto',
                    marginTop: '25px'
                }}
            >
                <Typography>
                    <DrcTranslate>{'Description'}</DrcTranslate>
                </Typography>
                <DrcInput
                    label=""
                    className={rootClass.root}
                    sx={{ inputMultiline: { height: '50px' } }}
                    name="description"
                    value={data.Description}
                    disabled
                    onChange={() => null}
                    inputProps={{ maxLength: 100, readOnly: true }}
                    required={false}
                    multiline={true}
                    minRows={6}
                />
            </Box>
        </>
    );
};

const mapStateToProps = ({ masterReducer, pricingPoolReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    parentPools: pricingPoolReducer.parentPools,
    pricingPoolsMap: pricingPoolReducer.pricingPoolsMap,
    settlementPoolsMap: pricingPoolReducer.settlementPoolsMap
});

export default connect(mapStateToProps, null)(IdentificationTab);
