import React, { Component } from 'react';
import PrimeReactTable from '../../../components/PrimeReactTable';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcButton, DrcDialog, DrcTranslate, DrcSelect, DrcMain } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { getAppliedFilters, makeColumns, userBusinessUnit, makeOptions, displayErrorMessage } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import { ReactComponent as Add } from '../../../Images/SVG/Add_Enabled.svg';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { alphaNumeric, decimal, numeric } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { designColors, ShipmentDsColumns, versionOptions } from '../../../data/constants';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';
import NoData from '../../../components/NoData';

const styles = (theme) => ({
    cardContainer: {
        backgroundColor: designColors.lightGrey,
        borderTop: '2px solid #E0E0E0',
        width: '82%',
        padding: '20px',
        marginBottom: '30px',
        borderRadius: '10px',
        margin: '5px',
        [theme.darkTheme]: {
            backgroundColor: `${theme.palette.grey['A700']} !important`,
            borderTop: '2px solid #000000'
        }
    },
    container: {
        width: '100%',
        maxWidth: 'none !important',
        margin: '8px 0',
        borderRadius: '8px'
    },
    gridStyles: GridStyles.styles(theme, 'calc(100vh - 340px)'),
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'left'
        }
    },
    root: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0 0px'
    },
    selectedClass: {
        width: '45px !important',
        padding: '0 10px !important'
    },
    actionContainer: {
        display: 'flex',
        justifyContent: 'end'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.common.white,
        [theme.darkTheme]: {
            backgroundColor: theme.palette.common.black
        }
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class ImportCost extends Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {},
        showAddNew: false,
        newRecord: {},
        itemIdOptions: [],
        invalidFields: []
    };
    validationFields = {
        ContainerNumber: {
            key: 'ContainerNumber',
            validations: [alphaNumeric],
            customProps: {}
        },
        RepackagingCharge: {
            key: 'RepackagingCharge',
            validations: [decimal],
            customProps: {}
        },
        FreightCustomToFDC: {
            key: 'FreightCustomToFDC',
            validations: [decimal],
            customProps: {}
        },
        Trays: {
            key: 'Trays',
            validations: [numeric],
            customProps: {}
        },
        ShipmentNumber: {
            key: 'ShipmentNumber',
            validations: [],
            customProps: {}
        },
        CustomClearanceFee: {
            key: 'CustomClearanceFee',
            validations: [decimal],
            customProps: {}
        },
        Others1: {
            key: 'Others1',
            validations: [decimal],
            customProps: {}
        },
        GrowerID: {
            key: 'GrowerID',
            validations: [],
            customProps: {}
        },
        ItemID: {
            key: 'ItemID',
            validations: [numeric],
            customProps: {}
        }
    };
    excludingFields = ['Actions'];
    readOnly = false;
    uniqueKeyField = 'ImportCostID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    componentDidMount() {
        this.loadData();
        this.getItemIdData();
        this.getFdcLookup();
        window.addEventListener('resize', this.updateDataTable);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = (_event, data, _index) => {
        this.setState({
            editingRow: {
                ...data,
                Trays: data.getPreviousData().Trays,
                RepackagingCharge: data.getPreviousData().RepackagingCharge,
                FreightCustomToFDC: data.getPreviousData().FreightCustomToFDC,
                CustomClearanceFee: data.getPreviousData().CustomClearanceFee,
                [data[this.uniqueKeyField]]: true
            },
            invalidFields: []
        });
    };

    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {}, invalidFields: [] });
    };
    cannotEdit = (_row) => null;

    deleteData = (_rowData) => null;

    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction('Updating data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'ShipmentRate',
                    url: APIEndPoints.PUT_SHIPMENT_DETAILS(),
                    method: 'PUT',
                    options: {},
                    data: {
                        ImportCostID: this.state.editingRow[this.uniqueKeyField],
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        ShipmentNumber: this.state.editingRow['ShipmentNumber'],
                        CustomClearanceFee: '' + this.state.editingRow['CustomClearanceFee'],
                        ReportType: '' + this.state.editingRow['ReportType'],
                        ContainerNumber: '' + this.state.editingRow['ContainerNumber'],
                        FreightCustomToFDC: '' + this.state.editingRow['FreightCustomToFDC'],
                        RepackagingCharge: '' + this.state.editingRow['RepackagingCharge'],
                        ...(this.state.editingRow.FDC ? { FDC: this.state.editingRow.FDC } : {}),
                        Trays: parseInt(this.state.editingRow['Trays']),
                        GrowerID: this.state.editingRow['GrowerID'],
                        ItemID: '' + this.state.editingRow['ItemID'],
                        PoolWeek: this.state.editingRow['PoolWeek'],
                        Others1: '' + (this.state.editingRow['Others1'] ?? ''),
                        Others2: this.state.editingRow['Others2'] || '',
                        Others3: this.state.editingRow['Others3'] || ''
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ editingRow: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.setState({ editingRow: {} });
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    addNewRecord = async () => {
        try {
            this.props.showLoadingScreenAction('Adding data');
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'ShipmentRate',
                    url: APIEndPoints.PUT_SHIPMENT_DETAILS(),
                    method: 'POST',
                    options: {},
                    data: {
                        PoolWeek: this.props.selectedPoolWeek.PoolWeek,
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString(),
                        ModifiedBy: loggedInUser,
                        CreatedDateTime: new Date().toISOString(),
                        ...(this.state.newRecord['RepackagingCharge'] !== undefined
                            ? { RepackagingCharge: '' + (this.state.newRecord['RepackagingCharge'] || '0') }
                            : {}),
                        ...(this.state.newRecord['FreightCustomToFDC'] !== undefined
                            ? { FreightCustomToFDC: '' + (this.state.newRecord['FreightCustomToFDC'] || '0') }
                            : {}),
                        ...(this.state.newRecord['ReportType'] !== undefined
                            ? { ReportType: '' + (this.state.newRecord['ReportType'].value || '') }
                            : {}),
                        Trays: this.state.newRecord['Trays'] !== undefined ? +this.state.newRecord['Trays'] : 0,
                        ...(this.state.newRecord.FDC?.value ? { FDC: this.state.newRecord.FDC.value } : {}),
                        ItemID: '' + this.state.newRecord['ItemID'].value,
                        ShipmentNumber: this.state.newRecord['ShipmentNumber'],
                        GrowerID: '' + this.state.newRecord['GrowerID'].value,
                        ContainerNumber: '' + this.state.newRecord['ContainerNumber'],
                        CustomClearanceFee: this.state.newRecord['CustomClearanceFee'] || '0',
                        Others1: this.state.newRecord['Others1'] || '0',
                        Others2: this.state.newRecord['Others2'],
                        Others3: this.state.newRecord['Others3']
                    }
                },
                token
            );
            if (data.errors.length && !data.raw) {
                this.setState({ showAddNew: false, newRecord: {} });
                return this.showError(data.errors[0]);
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ showAddNew: false, newRecord: {} });
                this.props.showToast(<DrcTranslate>{'Added Successfully'}</DrcTranslate>, true);
                this.loadData();
            }
        } catch (error) {
            this.showError(error);
        }
    };

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                checkRowValidation={() => !!this.state.invalidFields.length}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  [
                      'ShipmentNumber',
                      'CustomClearanceFee',
                      'GrowerID',
                      'Trays',
                      'ItemID',
                      'ContainerNumber',
                      'FreightCustomToFDC',
                      'RepackagingCharge',
                      'Others1',
                      'Others2',
                      'Others3',
                      'FDC'
                  ],
                  [],
                  ShipmentDsColumns
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'ShipmentRate',
                    url: APIEndPoints.GET_SHIPMENT_DETAILS(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        getAppliedFilters(this.props.filters),
                        this.props.selectedPoolWeek.PoolWeek || this.props.match.params.poolWeek
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getItemIdData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_ITEM_UOM_CONVERSION_LIST(null, null, userBusinessUnit(), this.props.userLang),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.setState({ itemIdOptions: makeOptions(data?.raw?.data?.Data || [], 'ItemID', 'ItemID') });
        } catch (error) {
            this.showError(error);
        }
    };

    getFdcLookup = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'FDC_LOOKUP',
                    url: APIEndPoints.GET_LOOK_UP_VALUES(userBusinessUnit(), 'FDC'),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.setState({ fdcLookupOptions: makeOptions(data?.raw?.data?.Data || [], 'LookupCode', 'LookupCode') });
        } catch (error) {
            this.showError(error);
        }
    };

    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key] ?? {};
        switch (key) {
            case 'CustomClearanceFee':
            case 'ShipmentNumber':
            case 'Trays':
            case 'ContainerNumber':
            case 'FreightCustomToFDC':
            case 'RepackagingCharge':
            case 'Others1':
            case 'Others2':
            case 'Others3':
                return this.inputTextEditor(key, col, others, field.validations, field.customProps);
            case 'GrowerID':
                return this.selectCellEditor(
                    key,
                    col,
                    others,
                    this.props.vendorCodeOptions.filter((grower) => grower.Geography === 'International')
                );
            case 'ItemID':
                return this.selectCellEditor(key, col, others, this.state.itemIdOptions);
            case 'FDC':
                return this.selectCellEditor(key, col, others, this.state.fdcLookupOptions);
        }
    };

    selectCellEditor = (key, _col, _others, options) => {
        return (
            <DrcSelect
                options={options}
                data-id={key}
                label={key}
                hideLabel={true}
                className={this.props.classes.root}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={(options || '').find((itm) => '' + itm.value === '' + this.state.editingRow[key])}
                menuPortalTarget={document.body}
                menuShouldBlockScroll={true}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid light gray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };

    inputTextEditor = (key, _col, _others, validations = [], customProps = {}) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) =>
                        this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }))
                    }
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                    maxvalue={100}
                    validationDetected={(validate) => this.handleValidationDetected(validate, key)}
                />
            </Form>
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    /**
     * Method that is triggered when validation is detected. This will enable/disable the save button for the add-dialog
     * @function handleValidationDetected
     * @param {*} validate
     */
    handleValidationDetected = (validate, fieldName) => {
        if (validate === undefined) {
            return;
        }
        if (validate) {
            if (!this.state.invalidFields.includes(fieldName)) {
                let newFields = [...this.state.invalidFields];
                newFields.push(fieldName);
                this.setState({ invalidFields: newFields });
            }
        } else {
            if (this.state.invalidFields.includes(fieldName))
                this.setState((prevState) => ({ ...prevState, invalidFields: prevState.invalidFields.filter((field) => field !== fieldName) }));
        }
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }
        return (
            <div className={classes.cardContainer}>
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['ShipmentNumber', 'GrowerID', 'ItemID', 'ContainerNumber', 'ReportTypeOptions']}
                        growerType="International"
                    />
                </div>

                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                    {/* <DrcButton
                        onClick={(_event) => {
                            this.setState({ showAddNew: true });
                        }}
                        noStyle
                        title="Add"
                    >
                        <Add style={{ width: '50px', height: '20px', marginRight: '-10px' }} />
                        <DrcTranslate>{'Add'}</DrcTranslate>
                    </DrcButton> */}
                </div>
                <DrcMain className={classes.container}>
                    {!!this.state.data.length ? (
                        <PrimeReactTable
                            value={this.state.data}
                            columns={this.state.columns}
                            editMode="row"
                            frozenWidth={'80px'}
                            rowSelect={false}
                            selectionMode="multiple"
                            tableSelectionMode="checkbox"
                            selected={this.state.selectedRow}
                            selectedFrozen={true}
                            selectedClass={classes.selectedClass}
                            selectionChange={(e) => {
                                this.setState({ selectedRow: e.value });
                            }}
                            uniqueKeyField={this.uniqueKeyField}
                            editingRows={this.state.editingRow}
                            paginator={this.state.data.length > 0}
                            currentPage={this.state.pageNum}
                            pageSize={this.state.pageSize}
                            onPage={this.onPage}
                            totalRecords={this.state.totalCount}
                            editor={(key, col, others) => {
                                return this.getRowEditor(
                                    key,
                                    col,
                                    others,
                                    this.validationFields[key] ? this.validationFields[key].validations : [],
                                    this.validationFields[key] ? this.validationFields[key].customProps : {}
                                );
                            }}
                        />
                    ) : (
                        <NoData />
                    )}
                </DrcMain>
                <DrcDialog
                    title={'Add New Record'}
                    open={this.state.showAddNew}
                    buttons={
                        <>
                            <DrcButton
                                onClick={() => {
                                    this.setState({ showAddNew: false, newRecord: {} });
                                }}
                            >
                                <DrcTranslate>{'Cancel'}</DrcTranslate>
                            </DrcButton>
                            <DrcButton
                                isPrimary
                                onClick={this.addNewRecord}
                                disabled={
                                    !!this.state.invalidFields.length ||
                                    !(
                                        this.state.newRecord['ContainerNumber'] &&
                                        this.state.newRecord['ItemID'] &&
                                        this.state.newRecord['GrowerID'] &&
                                        this.state.newRecord['ShipmentNumber'] &&
                                        this.state.newRecord['ReportType']
                                    )
                                }
                            >
                                <DrcTranslate>{'Submit'}</DrcTranslate>
                            </DrcButton>
                        </>
                    }
                >
                    <Form>
                        <GGSFormInput
                            required
                            label={'Shipment Number'}
                            validations={this.validationFields['ShipmentNumber'] ? this.validationFields['ShipmentNumber'].validations : []}
                            {...(this.validationFields['ShipmentNumber'] ? this.validationFields['ShipmentNumber'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, ShipmentNumber: e.target.value } }))
                            }
                            value={this.state.newRecord['ShipmentNumber']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'ShipmentNumber')}
                        />
                        <DrcSelect
                            label="Grower ID"
                            required
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, GrowerID: option } }))}
                            value={this.state.newRecord['GrowerID']}
                            className={this.props.classes.input}
                            options={this.props.vendorCodeOptions.filter((grower) => grower.Geography === 'International')}
                        />
                        <DrcSelect
                            label="Item ID"
                            required
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, ItemID: option } }))}
                            value={this.state.newRecord['ItemID']}
                            className={this.props.classes.input}
                            options={this.state.itemIdOptions}
                        />
                        <DrcSelect
                            label="FDC"
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, FDC: option } }))}
                            value={this.state.newRecord['FDC']}
                            className={this.props.classes.input}
                            options={this.state.fdcLookupOptions}
                        />
                        <GGSFormInput
                            label={'Trays'}
                            validations={this.validationFields['Trays'] ? this.validationFields['Trays'].validations : []}
                            {...(this.validationFields['Trays'] ? this.validationFields['Trays'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Trays: e.target.value } }))}
                            value={this.state.newRecord['Trays']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'Trays')}
                        />
                        <GGSFormInput
                            required
                            label={'Container Number'}
                            validations={this.validationFields['ContainerNumber'] ? this.validationFields['ContainerNumber'].validations : []}
                            {...(this.validationFields['ContainerNumber'] ? this.validationFields['ContainerNumber'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, ContainerNumber: e.target.value } }))
                            }
                            value={this.state.newRecord['ContainerNumber']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'ContainerNumber')}
                        />
                        <DrcSelect
                            required
                            label="Report Type"
                            onChange={(option) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, ReportType: option } }))}
                            value={this.state.newRecord['ReportType']}
                            className={this.props.classes.input}
                            options={versionOptions}
                        />
                        <GGSFormInput
                            label={'Custom Clearance Fee'}
                            validations={this.validationFields['CustomClearanceFee'] ? this.validationFields['CustomClearanceFee'].validations : []}
                            {...(this.validationFields['CustomClearanceFee'] ? this.validationFields['CustomClearanceFee'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, CustomClearanceFee: e.target.value } }))
                            }
                            value={this.state.newRecord['CustomClearanceFee']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'CustomClearanceFee')}
                        />

                        <GGSFormInput
                            label={'Freight Custom To FDC'}
                            validations={this.validationFields['FreightCustomToFDC'] ? this.validationFields['FreightCustomToFDC'].validations : []}
                            {...(this.validationFields['FreightCustomToFDC'] ? this.validationFields['FreightCustomToFDC'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, FreightCustomToFDC: e.target.value } }))
                            }
                            value={this.state.newRecord['FreightCustomToFDC']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'FreightCustomToFDC')}
                        />
                        <GGSFormInput
                            label={'Repackaging Charge'}
                            validations={this.validationFields['RepackagingCharge'] ? this.validationFields['RepackagingCharge'].validations : []}
                            {...(this.validationFields['RepackagingCharge'] ? this.validationFields['RepackagingCharge'].customProps : {})}
                            className={this.props.classes.input}
                            onChange={(e) =>
                                this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, RepackagingCharge: e.target.value } }))
                            }
                            value={this.state.newRecord['RepackagingCharge']}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'RepackagingCharge')}
                        />
                        <GGSFormInput
                            label={'Other 1'}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Others1: e.target.value } }))}
                            value={this.state.newRecord['Others1']}
                            validations={this.validationFields['Others1'] ? this.validationFields['Others1'].validations : []}
                            validationDetected={(validate) => this.handleValidationDetected(validate, 'Others1')}
                        />
                        <GGSFormInput
                            label={'Other 2'}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Others2: e.target.value } }))}
                            value={this.state.newRecord['Others2']}
                        />
                        <GGSFormInput
                            label={'Other 3'}
                            className={this.props.classes.input}
                            onChange={(e) => this.setState((prevState) => ({ newRecord: { ...prevState.newRecord, Others3: e.target.value } }))}
                            value={this.state.newRecord['Others3']}
                        />
                    </Form>
                </DrcDialog>
            </div>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, weeklySettlementStatusReducer, vendorCodeReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    filters: filterReducer.filters,
    currentPoolWeek: masterReducer.currentPoolWeek,
    vendorCodeOptions: vendorCodeReducer.vendorCodeOptions,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek
});
const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(ImportCost))));
