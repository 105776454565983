import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AccordionTable from '../components/AccordionTable';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { setWeeklySettlementStatusData, setPoolweekFreezeData } from '../actions/WeeklySettlementStatusAction';
import PricingPoolCard from '../components/PricingPoolCard/PricingPoolCard';
import { APPROVER_GROUP_ID, designColors, settlementWorkBenchApproverColumns, settlementWorkBenchSubmitterColumns } from '../data/constants';
import DrcWrapper from '../components/DrcWrapper';
import Stepper from '../components/Stepper';
import { DrcButton, DrcTranslate, DrcSelect, DrcDialog, DrcChips } from '@driscollsinc/driscolls-react-components';
import MasterDataUtilities from '../data/MasterDataUtilities';
import APIEndPoints from '../services/api';
import { setCurrentPoolWeek } from '../actions/MasterActions';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../actions/actions';
import { Call } from '@driscollsinc/driscolls-display-rules';
import { displayErrorMessage, userBusinessUnit } from '../utils/helper';
import { ReactComponent as Submit } from '../Images/SVG/approve.svg';
import { ReactComponent as Process } from '../Images/SVG/process.svg';
import SyncIcon from '@material-ui/icons/Sync';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';

const pageTitle = 'Settlement Workbench';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: 200,
        '& .select': {
            top: 0
        }
    },
    link: {
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            color: `${designColors.white} !important`
        },
        borderRadius: '5px',
        border: 'solid 1px #707070',
        padding: '3px 21px'
    },
    buttons: {
        color: `${designColors.blue} !important`,
        [theme.darkTheme]: {
            color: `${designColors.white} !important`
        }
    }
}));

const INITIAL_REPORT_PUBLISH_DATA = { domestic: false, import: false, count: 0 };

function WeeklySettlementStatus(props) {
    const classes = useStyles();

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [selectedRows, setSelected] = React.useState([]);
    const [approvedRows, setApprovedRows] = React.useState([]);
    const [rejectedRows, setRejectedRows] = React.useState([]);
    const [onHoldRows, setOnHoldRows] = React.useState([]);
    const [openPublisher, setOpenPublisher] = React.useState(false);
    const [openSubmitToApproval, setOpenSubmitToApproval] = React.useState(false);
    const [approverOptions, setApproverOptions] = React.useState([]);
    const [rowSelected, setRowSelect] = React.useState({});
    const [selectedApprover, setSelectedApprover] = React.useState({});
    const [isReportPublished, setIsReportPublished] = React.useState(INITIAL_REPORT_PUBLISH_DATA);

    useEffect(() => {
        if (props.pageTitle !== pageTitle) {
            props.setPageTitleAction(pageTitle);
        }
        getApprovers();
    }, []);

    useEffect(() => {
        if (props.selectedPoolWeek.PoolWeek) {
            getSettlementData();
            getGGSReportPublishData();
            setIsReportPublished(INITIAL_REPORT_PUBLISH_DATA);
        }
    }, [props.selectedPoolWeek.PoolWeek, props.userIsAdmin, props.selectedRole]);

    const adminOptions = [
        // { label: ' ', value: 'ActionRequired' },
        { label: 'Approved', value: 'Approved' },
        { label: 'Rejected', value: 'Rejected' }
        // { label: 'On Hold', value: 'OnHold' }
    ];

    const callBackMethod = (option, entry) => {
        var ap = [...approvedRows].filter((e) => e !== entry.PoolId);
        var rp = [...rejectedRows].filter((e) => e !== entry.PoolId);
        var op = [...onHoldRows].filter((e) => e !== entry.PoolId);

        switch (option.value) {
            case 'Approved':
                ap.push(entry.PoolId);
                break;
            case 'Rejected':
                rp.push(entry.PoolId);
                break;
            case 'OnHold':
                op.push(entry.PoolId);
                break;
            default:
                break;
        }

        setApprovedRows(ap);
        setRejectedRows(rp);
        setOnHoldRows(op);
    };

    const showError = (msg, err) => {
        props.hideLoadingScreenAction();
        props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    const getSettlementData = () => {
        setRowSelect({});
        setSelected([]);
        setOpenPublisher(false);
        setOpenSubmitToApproval(false);
        loadPoolWeekFreezeData();
        if (props.userIsAdmin) {
            getAdminData();
        } else {
            getData();
        }
    };
    const getApprovers = async () => {
        try {
            let token = await props.oktaAuth.getAccessToken();
            let approverGroup = Object.keys(APPROVER_GROUP_ID).find((group) => window.config.OKTA_GGS_ADMIN_GROUPS.includes(group));
            let response = await Call(
                {
                    name: 'ApproverUserList',
                    url: APIEndPoints.GET_APPROVER_LIST(APPROVER_GROUP_ID[approverGroup]),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            setApproverOptions((response.raw.data || []).map((usr) => ({ label: usr.profile.email, value: usr.profile.email })));
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', err);
        }
    };

    const loadPoolWeekFreezeData = async () => {
        props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await props.oktaAuth.getAccessToken();
        try {
            let response = await Call(
                {
                    name: 'PoolWeekShift',
                    url: APIEndPoints.GET_POOLWEEK_SHIFT(props.selectedPoolWeek.PoolWeek, userBusinessUnit()),
                    method: 'GET',
                    options: {}
                },
                token
            );

            props.setPoolweekFreezeData(response.raw.data.Data[0]);
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const getData = async () => {
        try {
            props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'WeeklySettlementStatusList',
                    url: APIEndPoints.GET_SETTLEMENT_RECORDS(userBusinessUnit(), props.selectedPoolWeek.PoolWeek),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            props.setWeeklySettlementStatusData({ Data: response.display, TotalCount: response.raw.data.length });
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const getAdminData = async () => {
        try {
            props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'PendingApprovalRecords',
                    // url: APIEndPoints.GET_SETTLEMENT_STATUS(props.selectedPoolWeek.PoolWeek, userBusinessUnit()),
                    url: APIEndPoints.GET_SETTLEMENT_RECORDS(userBusinessUnit(), props.selectedPoolWeek.PoolWeek, 'Pending Approval'),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            // props.setWeeklySettlementStatusData(response.raw.data);
            props.setWeeklySettlementStatusData({ Data: response.display, TotalCount: response.raw.data.length });
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const getColumns = (data = {}) => {
        let excludedCol = ['Id'];
        let columnMap = props.userIsAdmin ? settlementWorkBenchApproverColumns : settlementWorkBenchSubmitterColumns;
        let columns = Object.keys(columnMap)
            .filter((key) => !excludedCol.includes(key))
            .map((key) => ({ key, name: columnMap[key].name, width: columnMap[key].width }));
        return columns;
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleCheckboxSelect = (val, row) => {
        //Changing Checkbox to only select one at a time
        /*  if (val) {
            setSelected([...selectedRows, row.Id]);
        } else {
            let filteredRow = [...selectedRows].filter((itm) => itm !== row.Id);
            setSelected(filteredRow);
        } */
        if (val) {
            setSelected([row.PricingPoolID]);
            setRowSelect(row);
        } else {
            setSelected([]);
            setRowSelect({});
        }
    };

    const handleSelectAll = (val) => {
        if (val) {
            let ids = props.records.map((itm) => itm.Id);
            setSelected(ids);
        } else setSelected([]);
    };
    const submitToGlueJob = async (token, loggedInUser, runType) => {
        try {
            let response = await Call(
                {
                    url: APIEndPoints.POST_SETTLEMENT_RECORD(),
                    method: 'POST',
                    options: {},
                    data: {
                        jobname: 'Job_ggs_engine_settlement_raw_to_transform',
                        // jobname: 'Test_QMP',
                        arguments: {
                            '--env': DuAuthenticationUtilities.GetGroupScope(),
                            '--poolweek': props.selectedPoolWeek.PoolWeek,
                            '--poolId': rowSelected.PoolID,
                            '--businessunit': userBusinessUnit(),
                            '--user_details': loggedInUser,
                            '--configYaml': 'growersettlement_config.yaml',
                            '--tblProps': 'GGS_tables_properties.yaml',
                            '--enable-glue-datacatalog': '',
                            '--execution': 'INCREMENTAL',
                            '--ReprocessFlag': runType
                        }
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            return response;
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const postStatusToDb = async (loggedInUser, token, status, jobId) => {
        try {
            let startTime = new Date().toISOString();
            let response = await Call(
                {
                    name: 'GrowerSettlementStatus',
                    url: APIEndPoints.PUT_GROWER_SETTLEMENT_STATUS(),
                    method: 'POST',
                    options: {},
                    data: {
                        SubmittedBy: '', //loggedInUser,
                        ApprovedDate: '',
                        ApprovedBy: '',
                        BusinessUnit: userBusinessUnit(),
                        CreatedBy: loggedInUser,
                        ModifiedDateTime: startTime,
                        PoolID: rowSelected.PoolID,
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        ModifiedBy: loggedInUser,
                        SubmittedDate: '', //startTime,
                        CreatedDateTime: startTime,
                        Status: status,
                        ReprocessFlag: 'N',
                        EngineJobID: jobId,
                        EngineStartTime: startTime,
                        EngineEndTime: ''
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const sendEmail = async (loggedInUser, token) => {
        try {
            let url = window.location.origin + '/SettlementWorkbench/';
            let response = await Call(
                {
                    name: 'GrowerSettlementStatusEmail',
                    url: APIEndPoints.POST_EMAIL(),
                    method: 'POST',
                    options: {},
                    data: {
                        SendTo: selectedApprover.value,
                        Subject: `GGS Grower Settlement Request`,
                        MessageText: `Hi,<br/>You are assigned to review a new Grower Settlement Request Submitted for<br/>PoolWeek: ${
                            props.selectedPoolWeek.PoolWeek
                        }<br/>BusinessUnit: ${userBusinessUnit()}<br/>PoolID: ${
                            rowSelected.PoolID
                        }<br/>by ${loggedInUser}.<br/>Please <a href=${url}>login to GGS</a> and review`,
                        Attachment: null,
                        AttachmentFileName: '',
                        Isxl: false,
                        IsTemplate: false
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleProcess = async () => {
        try {
            props.showLoadingScreenAction('Submitting');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let resp = await submitToGlueJob(token, loggedInUser, 'N');
            await postStatusToDb(loggedInUser, token, 'Processing', resp?.raw?.data?.JobRunId);
            getSettlementData();
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };
    const handleReProcess = async () => {
        try {
            props.showLoadingScreenAction('Submitting');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let resp = await submitToGlueJob(token, loggedInUser, 'Y');
            let response = await Call(
                {
                    name: 'GrowerSettlementStatus',
                    url: APIEndPoints.PUT_GROWER_SETTLEMENT_STATUS(),
                    method: 'PUT',
                    options: {},
                    data: {
                        SubmittedBy: '', //loggedInUser || '',
                        // ApprovedDate: '',
                        ApprovedBy: '',
                        BusinessUnit: userBusinessUnit(),
                        GrowerSettlementStatusID: rowSelected.GrowerSettlementStatusID,
                        CreatedBy: rowSelected.CreatedBy,
                        ModifiedDateTime: new Date().toISOString(),
                        PoolID: rowSelected.PoolID,
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        ModifiedBy: loggedInUser,
                        SubmittedDate: '', //rowSelected.SubmittedDate || '',
                        CreatedDateTime: rowSelected.CreatedDateTime,
                        Status: 'Processing',
                        ReprocessFlag: 'Y',
                        EngineJobID: resp?.raw?.data?.JobRunId,
                        EngineStartTime: new Date().toISOString()
                        // EngineEndTime: ''
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            getSettlementData();
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const getGGSReportPublishData = async () => {
        try {
            props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await props.oktaAuth.getAccessToken();
            let response = await Call(
                {
                    name: 'GGSReportPublish',
                    url: APIEndPoints.GET_REPORT_PUBLISH_DATA(props.selectedPoolWeek.PoolWeek, userBusinessUnit()),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            getReportPublishedInformation(response?.raw?.data?.Data);
            props.hideLoadingScreenAction();
        } catch (error) {
            props.hideLoadingScreenAction();
            console.log('error : ', error);
        }
    };

    const getReportPublishedInformation = (data) => {
        data.find((itm) => {
            if (itm.Geography === 'Domestic') setIsReportPublished((prevState) => ({ ...prevState, count: prevState.count + 1, domestic: true }));
            if (itm.Geography === 'International') setIsReportPublished((prevState) => ({ ...prevState, count: prevState.count + 1, import: true }));
        });
    };

    const handleSubmitGGSReportsPublish = async (status, reportCategory, geography) => {
        try {
            props.showLoadingScreenAction('Processing');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let response = await Call(
                {
                    name: 'GGSReportPublish',
                    url: APIEndPoints.POST_GGS_REPORT_PUBLISH(),
                    method: 'POST',
                    options: {},
                    data: [
                        {
                            BusinessUnit: userBusinessUnit(),
                            ReportCategory: reportCategory,
                            Geography: geography,
                            CreatedBy: rowSelected.CreatedBy,
                            ModifiedDateTime: new Date().toISOString(),
                            PoolWeek: props.selectedPoolWeek.PoolWeek,
                            ModifiedBy: loggedInUser,
                            ReportStatus: status
                        }
                    ]
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            props.hideLoadingScreenAction();
            getSettlementData();
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', err);
        }
    };

    const handleSubmitApproval = async (status) => {
        try {
            props.showLoadingScreenAction('Processing');
            let token = await props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let response = await Call(
                {
                    name: 'GrowerSettlementStatus',
                    url: APIEndPoints.PUT_GROWER_SETTLEMENT_STATUS(),
                    method: 'PUT',
                    options: {},
                    data: {
                        SubmittedBy: loggedInUser || '',
                        BusinessUnit: userBusinessUnit(),
                        GrowerSettlementStatusID: rowSelected.GrowerSettlementStatusID,
                        CreatedBy: rowSelected.CreatedBy,
                        ModifiedDateTime: new Date().toISOString(),
                        PoolID: rowSelected.PoolID,
                        PoolWeek: props.selectedPoolWeek.PoolWeek,
                        ModifiedBy: loggedInUser,
                        SubmittedDate: new Date().toISOString(),
                        CreatedDateTime: rowSelected.CreatedDateTime,
                        Status: status,
                        ReprocessFlag: 'N',
                        ...(rowSelected.EngineStartTime ? { EngineStartTime: rowSelected.EngineStartTime } : {}),
                        ...(rowSelected.EngineEndTime ? { EngineEndTime: rowSelected.EngineEndTime } : {})
                    }
                },
                token
            );
            if ((response.errors.length && !response.raw) || response.raw.data.Status === 409) {
                return showError(response?.raw?.data?.Message || response.errors[0]);
            }
            if (status === 'Pending Approval') await sendEmail(loggedInUser, token);
            props.hideLoadingScreenAction();
            getGGSReportPublishData();
            getSettlementData();
        } catch (err) {
            props.hideLoadingScreenAction();
            console.log('error : ', err);
        }
    };

    const handlePublish = async () => {
        let status = isReportPublished.count === 1 ? 'Published' : 'Partially Published';
        handleSubmitApproval(status);
    };

    if (!MasterDataUtilities.Check(props.isMasterDataInitialized)) {
        return MasterDataUtilities.Redirect();
    }
    const newRecords = props.userIsAdmin
        ? props.records.map((entry) => {
              entry['Actions Required'] = (
                  <DrcSelect
                      onChange={(option) => {
                          callBackMethod(option, entry);
                      }}
                      label="Action Required"
                      hideLabel={true}
                      placeholder="Action Required"
                      className={classes.root}
                      options={adminOptions}
                      menuShouldBlockScroll={true}
                      menuPortalTarget={document.body}
                      styles={{
                          menuList: (base) => ({
                              ...base,
                              backgroundColor: 'white',
                              border: '1px solid lightgray',
                              width: 'auto',
                              zIndex: 9999
                          })
                      }}
                      menuPlacement={'auto'}
                  />
              );
              return entry;
          })
        : props.records;

    return (
        <DrcWrapper>
            <Stepper page="Settlement Workbench"></Stepper>
            <PricingPoolCard onFilter={getSettlementData} />
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ paddingLeft: 8 }}>
                    <DrcButton
                        className={classes.link}
                        onClick={() => {
                            window.open(APIEndPoints.GET_BI_DOMESTIC_PRE_REPORT);
                        }}
                    >
                        <DrcTranslate>{'PDF Domestic Pre'}</DrcTranslate>
                    </DrcButton>
                    <DrcButton
                        className={classes.link}
                        onClick={() => {
                            window.open(APIEndPoints.GET_BI_DOMESTIC_FINAL_REPORT);
                        }}
                    >
                        <DrcTranslate>{'PDF Domestic Final'}</DrcTranslate>
                    </DrcButton>
                    <DrcButton
                        className={classes.link}
                        onClick={() => {
                            window.open(APIEndPoints.GET_BI_IMPORT_PRE_REPORT);
                        }}
                    >
                        <DrcTranslate>{'PDF Import Pre'}</DrcTranslate>
                    </DrcButton>
                    <DrcButton
                        className={classes.link}
                        onClick={() => {
                            window.open(APIEndPoints.GET_BI_IMPORT_FINAL_REPORT);
                        }}
                    >
                        <DrcTranslate>{'PDF Import Final'}</DrcTranslate>
                    </DrcButton>
                </div>
                <div style={{ display: 'flex', justifyContent: 'end', padding: '0 12px' }}>
                    <DrcButton className={classes.buttons} onClick={getSettlementData}>
                        <SyncIcon style={{ width: '50px', height: '20px', marginRight: '-10px' }} /> <DrcTranslate>{'Refresh'}</DrcTranslate>
                    </DrcButton>

                    {!props.userIsAdmin && (
                        <>
                            {rowSelected.Status === 'Not Run' && (
                                <DrcButton className={classes.buttons} disabled={!selectedRows.length} onClick={handleProcess}>
                                    <Process style={{ width: '50px', height: '20px', marginRight: '-10px' }} />{' '}
                                    <DrcTranslate>{'Process'}</DrcTranslate>
                                </DrcButton>
                            )}
                            {(rowSelected.Status === 'Processed' || rowSelected.Status === 'Rejected' || rowSelected.Status === 'Failed') && (
                                <DrcButton className={classes.buttons} disabled={!selectedRows.length} onClick={handleReProcess}>
                                    <Process style={{ width: '50px', height: '20px', marginRight: '-10px' }} />{' '}
                                    <DrcTranslate>{'Re-Process'}</DrcTranslate>
                                </DrcButton>
                            )}
                            {rowSelected.Status === 'Processed' && (
                                <DrcButton className={classes.buttons} disabled={!selectedRows.length} onClick={() => setOpenSubmitToApproval(true)}>
                                    <Submit style={{ width: '50px', height: '20px', marginRight: '-15px' }} />
                                    <DrcTranslate>{'Submit to Approval'}</DrcTranslate>
                                </DrcButton>
                            )}
                            {(rowSelected.Status === 'Approved' || rowSelected.Status === 'Partially Published') && (
                                <>
                                    <DrcButton
                                        className={classes.buttons}
                                        disabled={!selectedRows.length || isReportPublished.domestic}
                                        onClick={() => {
                                            handlePublish();
                                            handleSubmitGGSReportsPublish('Publish', 'GrowerSettlementReport', 'Domestic');
                                        }}
                                    >
                                        <Submit style={{ width: '50px', height: '20px', marginRight: '-15px' }} />
                                        <DrcTranslate>{'Publish Domestic'}</DrcTranslate>
                                    </DrcButton>
                                    <DrcButton
                                        className={classes.buttons}
                                        disabled={!selectedRows.length || isReportPublished.import}
                                        onClick={() => {
                                            handlePublish();
                                            handleSubmitGGSReportsPublish('Publish', 'ImportGrowerSettlementReport', 'International');
                                        }}
                                    >
                                        <Submit style={{ width: '50px', height: '20px', marginRight: '-15px' }} />
                                        <DrcTranslate>{'Publish Import'}</DrcTranslate>
                                    </DrcButton>
                                </>
                            )}
                        </>
                    )}
                </div>
            </div>
            <AccordionTable
                total={props.total}
                data={props.records}
                columns={getColumns((props.records || [])[0])}
                rowsPerPageOptions={[10, 25, 50]}
                rowsPerPage={rowsPerPage}
                page={page}
                onLoad={getSettlementData}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleCheckboxSelect={handleCheckboxSelect}
                handleSelectAll={handleSelectAll}
                selectedRows={selectedRows}
                isCheckboxDisabled={(row) => !!(row.Status === 'Processing' || row.Status === 'Pending Approval' || row.Status === 'Published')}
                rowSelect={!props.userIsAdmin}
            />
            <DrcDialog
                title=""
                open={openPublisher}
                buttons={
                    <>
                        <DrcButton
                            isPrimary
                            onClick={() => {
                                //to do, integrate API
                            }}
                        >
                            <DrcTranslate>{'Publish'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            onClick={() => {
                                setOpenPublisher(false);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <DrcTranslate>{'Are you sure you want to publish ?'}</DrcTranslate>
            </DrcDialog>
            <DrcDialog
                title=""
                open={openSubmitToApproval}
                buttons={
                    <>
                        <DrcButton isPrimary disabled={!(selectedApprover || {}).value} onClick={() => handleSubmitApproval('Pending Approval')}>
                            <DrcTranslate>{'Submit'}</DrcTranslate>
                        </DrcButton>
                        <DrcButton
                            onClick={() => {
                                setSelectedApprover({});
                                setOpenSubmitToApproval(false);
                            }}
                        >
                            <DrcTranslate>{'Cancel'}</DrcTranslate>
                        </DrcButton>
                    </>
                }
            >
                <DrcSelect label="Select Approver" placeholder="Select Approver" onChange={setSelectedApprover} options={approverOptions} />
            </DrcDialog>
        </DrcWrapper>
    );
}

const mapStateToProps = ({ weeklySettlementStatusReducer, masterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userIsAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole,
    total: weeklySettlementStatusReducer.totalRecords,
    records: weeklySettlementStatusReducer.records,
    currentPoolWeek: masterReducer.currentPoolWeek,
    pageTitle: rootReducer.pageTitle,
    selectedPoolWeek: weeklySettlementStatusReducer.selectedPoolWeek
});
export default connect(mapStateToProps, {
    setWeeklySettlementStatusData,
    setCurrentPoolWeek,
    hideLoadingScreenAction,
    showLoadingScreenAction,
    showToast,
    setPageTitleAction,
    setPoolweekFreezeData
})(withOktaAuth(withRouter(WeeklySettlementStatus)));
