import React from 'react';
import { Divider } from '@material-ui/core';
import DrcWrapper from '../../../components/DrcWrapper';
import PrimeReactTable from '../../../components/PrimeReactTable';
import Stepper from '../../../components/Stepper';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { DrcButton, DrcSelect, DrcTranslate } from '@driscollsinc/driscolls-react-components';
import GridStyles from '../../../styles/gridStyles';
import { showLoadingScreenAction, hideLoadingScreenAction, showToast, setPageTitleAction } from '../../../actions/actions';
import { connect } from 'react-redux';
import { withOktaAuth } from '@okta/okta-react';
import { displayErrorMessage, getAppliedFilters, makeColumns, makeOptions, userBusinessUnit } from '../../../utils/helper';
import ActionsFormatter from '../../../components/cellFormatters/ActionFormatter';
import MasterDataUtilities from '../../../data/MasterDataUtilities';
import { Call } from '@driscollsinc/driscolls-display-rules';
import APIEndPoints from '../../../services/api';
import { DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { decimal } from '../../../utils/validations';
import GGSFormInput from '../../../components/GGSFormInput';
import Form from 'react-validation/build/form';
import { itemUomConversionDsColumns, LOOKUP_FIELDS } from '../../../data/constants';
import FilterListSharp from '@material-ui/icons/FilterListSharp';
import Filter from '../../../components/Filter/Filter';

const pageTitle = 'Item UOM Conversion';
const styles = (theme) => ({
    input: {
        '& .MuiOutlinedInput-input': {
            textAlign: 'center'
        }
    },
    gridStyles: GridStyles.styles(theme, '300px', '300px'),
    tableContainer: {
        margin: theme.spacing(0, 3)
    },
    select: {
        '& .select': {
            top: 2
        },
        margin: '-5px 0px 0px'
    },
    filterContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    pageActions: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
    }
});

class ItemUomConversion extends React.Component {
    state = {
        data: [],
        pageNum: 0,
        selectedRow: [],
        pageSize: 50,
        totalCount: 0,
        columns: [],
        rowData: null,
        editingRow: {}
    };
    excludingFields = ['Actions', 'Lang', 'ItemListID'];
    readOnly = false;
    uniqueKeyField = 'ItemID';
    widthIsSufficient = window.innerWidth > 955;
    widthIsInSufficient = false;

    validationFields = {
        TrayToKilogram: {
            key: 'TrayToKilogram',
            validations: [decimal],
            customProps: {}
        }
    };

    componentDidMount() {
        this.loadData();
        window.addEventListener('resize', this.updateDataTable);
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDataTable);
    }

    editData = async (_event, data, _index) => {
        this.setState({
            editingRow: { ...data, TrayToKilogram: data.getPreviousData().TrayToKilogram + '', [data[this.uniqueKeyField]]: true }
        });
    };
    cancelEdit = (_event, _rowData) => {
        this.setState({ editingRow: {} });
    };
    cannotEdit = (_row) => !(this.props.isUserAdmin && this.props.selectedRole.includes('-Admin'));
    deleteData = async (_rowData) => null;
    confirmEdit = async (_event, _rowData) => {
        try {
            this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
            let token = await this.props.oktaAuth.getAccessToken();
            let loggedInUser = DuAuthenticationUtilities.GetEmail(token);
            let data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.PUT_ITEM_UOM_CONVERSION_LIST(),
                    method: 'PUT',
                    options: {},
                    data: {
                        ItemID: this.state.editingRow[this.uniqueKeyField],
                        EstimateGroup: this.state.editingRow['EstimateGroup'],
                        Commodity: this.state.editingRow['Commodity'],
                        TrayToKilogram: '' + (this.state.editingRow['TrayToKilogram'] || 0),
                        BusinessUnit: this.state.editingRow['BusinessUnit'],
                        ItemListID: this.state.editingRow['ItemListID'],
                        CreatedBy: this.state.editingRow['CreatedBy'],
                        Description: this.state.editingRow['Description'] || '',
                        Abbreviation: this.state.editingRow['Abbreviation'] || '',
                        Lang: this.state.editingRow['Lang'] || this.props.userLang,
                        CreatedDateTime: this.state.editingRow['CreatedDateTime'],
                        ModifiedBy: loggedInUser,
                        ModifiedDateTime: new Date().toISOString()
                    }
                },
                token
            );
            if (!data) {
                this.showError();
            } else {
                this.props.hideLoadingScreenAction();
                this.setState({ editingRow: {} });
                this.loadData();
                this.props.showToast(<DrcTranslate>{'Updated Successfully'}</DrcTranslate>, true);
            }
        } catch (error) {
            this.showError(error);
        }
    };
    cannotDelete = (_row) => null;

    getActionsCell = (row) => {
        return (
            <ActionsFormatter
                rowEdit={true}
                uniqueKeyField={this.uniqueKeyField}
                objState={this}
                records={this.state.data}
                initRowEdit={this.editData}
                confirmEdit={this.confirmEdit}
                cancelEdit={this.cancelEdit}
                cannotDelete={this.cannotDelete}
                cannotEdit={this.cannotEdit}
                deleteAttribute={this.deleteData}
                row={row}
                rowUnderEditUniqueKeyValue={row[this.uniqueKeyField]}
            />
        );
    };

    updateTableData = (response, hasFrozenColumns = true) => {
        let data = response?.display?.Data || [];
        let columns = data.length
            ? makeColumns(
                  Object.keys(data[0]),
                  this.excludingFields,
                  this.getActionsCell,
                  true,
                  ['Description', 'EstimateGroup', 'TrayToKilogram', 'Abbreviation'],
                  ['ItemID'],
                  itemUomConversionDsColumns,
                  true
              )
            : [];
        this.setState({
            data,
            totalCount: response?.display?.TotalCount || 0,
            columns,
            convertedData: response.converted || [],
            displayData: response.display || [],
            unfreeze: !hasFrozenColumns
        });
    };
    updateDataTable = (_event, force = false) => {
        if (window.innerWidth > 955 && !this.widthIsSufficient) {
            this.widthIsSufficient = true;
            this.widthIsInSufficient = false;
            this.updateTableData(this.response || [], true);
        } else if (window.innerWidth <= 955 && !this.widthIsInSufficient) {
            this.widthIsInSufficient = true;
            this.widthIsSufficient = false;
            this.updateTableData(this.response || [], false);
        } else if (force) {
            this.updateTableData(this.response || [], true);
        }
    };

    loadData = async () => {
        this.props.showLoadingScreenAction(<DrcTranslate>{'Loading data'}</DrcTranslate>);
        let token = await this.props.oktaAuth.getAccessToken();
        try {
            let data = await Call(
                {
                    name: 'ItemUomConversion',
                    url: APIEndPoints.GET_ITEM_UOM_CONVERSION_LIST(
                        this.state.pageNum / this.state.pageSize + 1,
                        this.state.pageSize,
                        userBusinessUnit(),
                        this.props.userLang,
                        getAppliedFilters(this.props.filters)
                    ),
                    method: 'GET',
                    options: {}
                },
                token
            );
            if (data.errors.length && !data.raw) {
                return this.showError(data.errors[0]);
            }
            this.response = data;
            this.updateDataTable(null, true);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.showError(error);
        }
    };
    getOptions = (key) => {
        if (key === 'EstimateGroup') {
            return makeOptions(this.props.estimateGroupLookUp, 'LookupCode', 'LookupCode');
        } else if (key === 'Commodity') {
            return makeOptions(this.props.commodityLookUp, 'Description', 'Description');
        }
    };
    getRowEditor = (key, col, others) => {
        let field = this.validationFields[key] ?? {};
        switch (key) {
            case 'EstimateGroup':
            case 'Commodity':
                return this.selectCellEditor(key, col, others);
            case 'TrayToKilogram':
                return this.inputTextEditor(key, col, others, field.customProps, field.validations);
            default:
                return this.inputTextEditor(key, col, others);
        }
    };

    inputTextEditor = (key, _col, _others, customProps, validations = []) => {
        return (
            <Form>
                <GGSFormInput
                    className={this.props.classes.input}
                    onChange={(e) => {
                        return this.setState(() => ({
                            editingRow: { ...this.state.editingRow, [key]: e.target.value }
                        }));
                    }}
                    value={this.state.editingRow[key]}
                    validations={validations}
                    {...customProps}
                />
            </Form>
        );
    };

    selectCellEditor = (key, _col, _others) => {
        return (
            <DrcSelect
                options={this.getOptions(key)}
                label="CATEGORY"
                hideLabel={true}
                className={this.props.classes.select}
                onChange={(option) => {
                    this.setState(() => ({
                        editingRow: { ...this.state.editingRow, [key]: option.value }
                    }));
                }}
                value={this.getOptions(key).find((itm) => itm.label === this.state.editingRow[key])}
                menuShouldBlockScroll={true}
                menuPortalTarget={document.body}
                styles={{
                    menuList: (base) => ({
                        ...base,
                        backgroundColor: this.props.theme.palette.common.white,
                        [this.props.theme.darkTheme]: {
                            backgroundColor: this.props.theme.palette.common.black
                        },
                        border: '1px solid lightgray',
                        width: 'auto',
                        zIndex: 9999
                    })
                }}
                menuPlacement={'auto'}
            />
        );
    };

    onPage = (event) => {
        this.setState({ pageNum: event.first });
        this.loadData();
    };

    showError = (err, msg) => {
        this.props.hideLoadingScreenAction();
        this.props.showToast(<DrcTranslate>{displayErrorMessage(msg)}</DrcTranslate>, false);
        console.error('error : ', err);
    };

    applyFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    toggleShowFilter = () => this.setState({ showFilter: !this.state.showFilter });

    resetFilter = () => {
        this.setState({ pageNum: 0 }, () => this.loadData());
    };

    render() {
        const { classes } = this.props;
        if (!MasterDataUtilities.Check(this.props.isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcWrapper>
                <Stepper page={pageTitle} isDataSetup />
                <Divider />
                <div className={classes.filterContainer}>
                    <Filter
                        showFilter={this.state.showFilter}
                        applyFilter={this.applyFilter}
                        resetFilter={this.resetFilter}
                        filterNames={['Commodity', 'EstimateGroup', 'ItemID']}
                    />
                </div>
                <div className={classes.pageActions}>
                    <div>
                        <DrcButton onClick={this.toggleShowFilter}>
                            <FilterListSharp className={classes.icon} />
                            <DrcTranslate>{'Filter'}</DrcTranslate>
                        </DrcButton>
                    </div>
                </div>
                <div className={classes.tableContainer}>
                    <PrimeReactTable
                        value={this.state.data}
                        columns={this.state.columns}
                        editMode="row"
                        frozenWidth={'170px'}
                        tableSelectionMode="checkbox"
                        uniqueKeyField={this.uniqueKeyField}
                        editingRows={this.state.editingRow}
                        paginator={this.state.data.length > 0}
                        currentPage={this.state.pageNum}
                        pageSize={this.state.pageSize}
                        onPage={this.onPage}
                        totalRecords={this.state.totalCount}
                        editor={(key, col, others) => this.getRowEditor(key, col, others)}
                    />
                </div>
            </DrcWrapper>
        );
    }
}
const mapStateToProps = ({ masterReducer, filterReducer, rootReducer }) => ({
    isMasterDataInitialized: masterReducer.isInitialized,
    userLang: masterReducer.userLang,
    estimateGroupLookUp: masterReducer[LOOKUP_FIELDS.EstimateGroupLookUp],
    commodityLookUp: masterReducer[LOOKUP_FIELDS.CommodityLookup],
    pageTitle: rootReducer.pageTitle,
    filters: filterReducer.filters,
    isUserAdmin: rootReducer.userIsAdmin,
    selectedRole: rootReducer.selectedRole
});

const mapDispatchToProps = (dispatch) => ({
    showToast: (message, type) => dispatch(showToast(message, type)),
    showLoadingScreenAction: (message) => dispatch(showLoadingScreenAction(message)),
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction())
});

export default connect(mapStateToProps, mapDispatchToProps)(withOktaAuth(withTheme(withStyles(styles)(ItemUomConversion))));
